import React, { Component } from "react";
import {
  Form,
  Radio,
  Tooltip,
  message,
  Modal,
  Button,
  Col,
  Input,
  Alert,
  Typography,
} from "antd";
import List from "../grid.component";
import {  Sumsub, reRunScore, getCustomerBankDetails } from "./api";
import { setBreadcrumb, clearBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import apiCalls from "../../api/apiCalls";

import {
  getPermissions,
  updateCurrentScreen, updateTabAccessDenied
} from "../../reducers/permissionsReducer";
import { setCurrentAction } from "../../reducers/actionsReducer";
import AccessDenied from "../accessDined.component/accessDenied";
import CreateCase from "../../utils/createCase";
import { CaseRediractions } from '../../utils/caseRediractions';
import LockedComponent from "../../utils/lockunlock";
import WithdrawStateChange from './stateChange'

const { Paragraph } = Typography;
const { TextArea } = Input;

class Withdraw extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      statuserrorMsg: null,
      warningMsg: null,
      check: false,
      withdraw: true,
      selection: [],
      selectedObj: {},
      caseModal: false,
      gridUrl: process.env.REACT_APP_GRID_API + "Withdraw/Fiat",
      gridUrlC: process.env.REACT_APP_GRID_API + "Withdraw/Crypto",
      errorMessage: "",
      type: "fiat",
      stateLoading: true,
      error: null,
      obj: {
        id: "",
        screenName: "Withdrawal",
        state: "",
        statusRemarks: "",
        pCommisionValue: null,
        sweepSourceCommisionValue: null,
        sweepDestinationCommissionValue: null,
        bankTxnId:"",
				providerTxnId:"",
      },
      stateChange: {},
      isClickedStateChange: {},
      alert: false,
      btnDisabled: false,
      selectedTab: 1,
      istabAcessdenied: false,
      selectedObjs: [],
      statesLst: [],
      isModal: false,
      getLockandUnLockDetails: [],
      isCheckLock: false,
      modal: false,
      selectedKytTxnId: [],
      reRunModal: false,
      fiatBanks: [],
      selectedBankName: null,
      isRemarks:null,
      showStateChange:false
    };
    this.gridFiatRef = React.createRef();
    this.gridCryptoRef = React.createRef();
    this.formref = React.createRef();
  }
  handleCheck = (props) => {
    return <label className="text-center custom-checkbox">
      <input
        id={props.dataItem.id}
        name="check"
        type="checkbox"
        checked={this.state.selection.indexOf(props.dataItem.id) > -1}
        onChange={(e) => this.handleCheckbox(props, e)}
      />
      <span></span>{" "}
    </label>
  }
  handleLockUnLock = (lockdata) => {
    return <td>
      <div className="d-flex align-center justify-content">
        {lockdata.dataItem?.lockUnlock}
        {lockdata.dataItem?.lockUnlockHistory && <Tooltip title="More Info">
          <span
            className="icon md info c-pointer ml-8"
            onClick={() => this.showMoreDetails(lockdata)}
          />
        </Tooltip>}
      </div>
    </td>
  }
  handleTransactionID = (prosp) => {
    return <div className="gridLink" onClick={() => this.gridView(prosp)}>
      {prosp.dataItem?.transactionId}
    </div>
  }
  handleCaseViewdata = (caseData) => {
    return <td>
      <div className="gridLink" >
        {caseData.dataItem.caseIds?.map(item => <div onClick={() => this.createCaseView(item)}>{item.caseNumber}</div>)}
      </div>
    </td>
  }
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: this.handleCheck
    },
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      locked: true,
      width: 210,
      customCell: this.handleTransactionID,
    },

    { field: "createdDate", title: "Submission Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
    { field: "approvedDate", title: "Approval Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
    {
      field: "status", title: "Status", filter: true, width: 180,
      customCell: (props) => {
        const statusColorConfig = this.props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === props.dataItem.status);
        const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
        const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';

        return (
          <td className="whitelist-name">
            <div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
              {props.dataItem.status}
            </div>
          </td>
        );
      }
    },

    {
      field: "customerName",
      title: "Business Name/Personal Name",
      filter: true,
      width: 250,
      customCell: (props) => (
        <td className="whitelist-name">
          <div className="d-flex">
            <div className="white-address address-width">	{props.dataItem.customerName}</div>
            {props?.dataItem?.bankAccountType !== null && (
              <div className="file-labels ml-8 fs-12 address-label address-label-width">
                {props?.dataItem?.bankAccountType}
              </div>
            )}</div>
        </td>
      ),
    },
    { field: "walletCode", title: "Wallet Code", filter: true, width: 130 },
    { field: "requestedAmount", title: "Requested Amount", filter: true, width: 200, dataType: "number", filterType: "numeric" },
    { field: "netAmount", title: "Net Amount", filter: true, width: 160, dataType: "number", filterType: "numeric" },
    { field: "externalAccountName", title: "Sender's Bank Name", filter: true, width: 200 },
    { field: "addressType", title: "Address Type", filter: true, width: 150 },
    { field: "recepientName", title: "Recipient Full Name", filter: true, width: 200 },
    {
      field: "accountNumber",
      title: "Bank Account Number/IBAN",
      width: 250,
      filter: true,
      customCell: (props) => (
        <td className="whitelist-name">
          <div className={`white-address ibancolor ${props.dataItem?.ibanColour?.toLowerCase()}`}>{props.dataItem.accountNumber}</div>
        </td>
      ),
    },
    { field: "bankName", title: "Bank Name", filter: true, width: 160 },
    {
      field: "routingNumber",
      title: "BIC/SWIFT/ABA Routing/Uk Sort Code",
      filter: true,
      width: 370,
    },
    {
      field: "effectiveFee",
      title: "Fees",
      filter: true,
      width: 220,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "tierDiscount",
      title: "Tier Discount",
      filter: true,
      width: 220,
      dataType: "number",
      filterType: "numeric",
    },
    { field: "comissionvalue", title: "Commission", filter: true, width: 180, dataType: "number", filterType: "numeric" },

    { field: "comissionvalueInBase", title: "Commission in Base", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    {
      field: "profit",
      title: "Profit",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "profitInBase",
      title: "Profit in Base",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "pcomissionvalue", title: "Provider Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "pcomissionvalueInBase", title: "Provider Commission in Base", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComissionValue", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceValueInBase", title: "Sweep Source in Base", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationcomissionValue", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationvalueInBase", title: "Sweep Destination in Base", filter: true, width: 270, dataType: "number", filterType: "numeric" },
    {
      field: "caseids",
      title: "Case Number",
      width: 160,
      customCell: this.handleCaseViewdata,
    },
    { field: "addressBookStatus", title: "Recipient Status", filter: true, width: 200 },
    { field: "username", title: "User Name", filter: true, width: 160 },
    { field: "email", title: "Email", filter: true, width: 200 },
    { field: "refrenceId", title: "Customer/Contract ID", filter: true, width: 200 },
    { field: "rejectReason", title: "Reason For Rejection", filter: true, width: 240 },
    {
      field: "lockUnlock", title: "Lock/Unlock", filter: true, width: 160, customCell: this.handleLockUnLock
    },
    { field: "transactionrefId", title: "Reference ID", filter: true, width: 240 },
    {
      field: "riskScore",
      title: "Monitoring ",
      filter: true,
      width: 120,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "kytTxnId",
      title: "Txn ID Monitoring ",
      filter: true,
      width: 250,
      dataType: "number",
      filterType: "numeric",
    },
    { field: "lp", title: "LP", filter: true, width: 180 },
		{ field: "bankPartner", title: "Bank Partner", filter: true, width: 180 },

  ];
  withdrawColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: this.handleCheck
    },
    {
      field: "transactionId",
      title: "Transaction ID",
      filter: true,
      locked: true,
      width: 210,
      customCell: this.handleTransactionID,
    },
    { field: "createdDate", title: "Submission Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
    { field: "approvedDate", title: "Approval Date", filterType: "date", isShowTime: true, filter: true, width: 210 },
    {
      field: "status",
      title: "Status",
      width: 180,
      filter: true,
      customCell: (props) => {
        const statusColorConfig = this.props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === props.dataItem.status);
        const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
        const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';

        return (
          <td className="whitelist-name">
            <div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
              {props.dataItem.status}
            </div>
          </td>
        );
      }
    },
    {
      field: "customerName",
      title: "Business Name/Personal Name",
      filter: true,
      width: 280,
    },
    { field: "walletName", title: "Coin", filter: true, width: 130 },
    { field: "network", title: "Network", filter: true, width: 160 },
    {
      field: "walletAddress", title: " Wallet Address", filter: true, width: 170, customCell: (props) => (
        <td>
          {props.dataItem.walletAddress != null &&
            props.dataItem.walletAddress !== "" ? (
            <Paragraph
              className="kpi-val mb-0"
              copyable={{ text: props.dataItem.walletAddress }}>
              {props.dataItem.walletAddress.slice(0, 4) +
                "..." +
                props.dataItem.walletAddress.slice(-4)}
            </Paragraph>
          ) : (
            ""
          )}
        </td>
      )
    },
    {
      field: "addressBookStatus",
      title: "Address Book State",
      width: 200,
      filter: true,
    },
    { field: "requestedAmount", title: "Requested Crypto", filter: true, width: 180, dataType: "number", filterType: "numeric" },
    { field: "receivedAmount", title: "Net Amount", filter: true, width: 180, dataType: "number", filterType: "numeric" },
    { field: "comission", title: "Commission", filter: true, width: 180, dataType: "number", filterType: "numeric" },
    {
      field: "profit",
      title: "Profit",
      filter: true,
      width: 150,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "comissionValueInBase", title: "Commission in Base", filter: true, width: 220, dataType: "number", filterType: "numeric" },

    {
      field: "profitInBase",
      title: "Profit in Base",
      filter: true,
      width: 180,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "effectiveFee",
      title: "Fees",
      filter: true,
      width: 220,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "tierDiscount",
      title: "Tier Discount",
      filter: true,
      width: 220,
      dataType: "number",
      filterType: "numeric",
    },
    { field: "pcomissionvalue", title: "Provider Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "pcomissionvalueInBase", title: "Provider Commission in Base", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComissionValue", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceValueInBase", title: "Sweep Source in Base", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestination", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationInBase", title: "Sweep Destination in Base", filter: true, width: 270, dataType: "number", filterType: "numeric" },
    { field: "username", title: "User Name", filter: true, width: 160 },
    { field: "email", title: "Email", filter: true, width: 200 },
    { field: "refrenceId", title: "Customer/Contract ID", filter: true, width: 200 },
    {
      field: "caseids",
      title: "Case Number",
      width: 160,
      customCell: this.handleCaseViewdata,
    },
    {
      field: "rejectReason",
      title: "Reason For Rejection",
      width: 260,
      filter: true,
    },
    {
      field: "lockUnlock", title: "Lock/Unlock", filter: true, width: 160, customCell: this.handleLockUnLock
    },
    {
      field: "riskScore",
      title: "Monitoring ",
      filter: true,
      width: 120,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "kytTxnId",
      title: "Txn ID Monitoring ",
      filter: true,
      width: 250,
      dataType: "number",
      filterType: "numeric",
    },
    {
      field: "crytpoWalletScore",
      title: "Crypto KYT ",
      filter: true,
      width: 120,
      dataType: "number",
      filterType: "numeric",
      customCell: (props) => {
				const kytColor = props.dataItem?.kytColour|| 'black'; 
				const textColor = (kytColor === 'red' || kytColor === 'green') ? 'white' : 'black';
				return (
					<td className="whitelist-name">
						<div className={`white-address ibancolor ${kytColor}`} style={{ color: textColor }}>
							{props.dataItem.crytpoWalletScore}
						</div>
					</td>
				);
			  }
    },
  ];
  componentDidMount() {
    this.props.dispatch(
      updateTabAccessDenied(false)
    );


    setTimeout(() => {
      this.props.dispatch(updateCurrentScreen("withdrawfiat"));
    }, 250);
    if (this.props.permissions?.currentScreenTabId) {
      if (!this.props.permissions[this.props.permissions?.currentScreenTabId]) {
        this.props.dispatch(
          getPermissions({
            customerId: this.props.userConfig.id,
            perKey: this.props.permissions?.currentScreenTabId,
          })
        );
      }
      const _menutabItem = this.props.permissions.menuItems.filter((item) => item.id === this.props.permissions?.currentScreenTabId)[0];
      this.setState({ ...this.state, selectedTab: _menutabItem?.label === 'Withdraw Fiat' ? 1 : 2, withdraw: _menutabItem?.label === 'Withdraw Fiat' })
    }

  }
  createCaseView = (CaseData) => {
    let propsData = this.props
    this.props.dispatch(updateCurrentScreen("withdrawfiat"));
    CaseRediractions(CaseData, propsData)
  };

  gridRefresh = () => {
    if (this.state.selectedObj.type === "Fiat") {
      this.gridFiatRef.current.refreshGrid()
    } else {
      this.gridCryptoRef.current.refreshGrid()
    }
  }
  redirectTransactionView = (e) => {
    const items = e.dataItem;
    const val = items?.walletCode;
    const batchID = items?.batchId;
    this.props.history.push(`/batchPayments/${items?.batchTransactionId}/${val}`);
    this.props.dispatch(
      setBreadcrumb({
        key: `/batchPayments/${items?.batchTransactionId}`,
        val: `${batchID + " / " + val}`
      })
    );
  };
  hideCaseModal = () => {
    this.setState({ ...this.state, caseModal: false, selection: [], selectedObjs: [] });
    this.gridRefresh()
  }
  createCase = () => {
    const { selection } = this.state;
    if (selection.length == 0) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select the one record", error: null, });
    }
    else if (selection.length > 1) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select only one record", error: null });
    }
    else {
      this.setState({ ...this.state, caseModal: true, check: false });
    }
  }
  handleWithdraw = (e) => {
    this.setState({
      ...this.state,
      withdraw: e.target.value === 1 ? true : false,
      selection: [],
      selectedObj: {},selectedKytTxnId:[],
      check: false,
      warningMsg: null,
      selectedTab: e.target.value,
      istabAcessdenied: false, type: e.target.value === 1 ? 'Fiat' : 'Crypto'
    });
    this.props.dispatch(
      updateCurrentScreen(
        e.target.value === 1 ? "withdrawfiat" : "withdrawcrypto"
      )
    );
    this.props.dispatch(
      updateTabAccessDenied(false)

    );
    const _menutabItem = this.props.permissions.menuItems.filter((item) => item.label === (e.target.value === 1 ? "Withdraw Fiat" : "Withdraw Crypto"))[0];
    this.props.dispatch(
      getPermissions({
        customerId: this.props.userConfig.id,
        perKey: _menutabItem.id,
      })
    );

  };
  validateNumber = (_, validNumberValue) => {
    if (validNumberValue === ".") {
      return Promise.reject("Please enter valid content");
    }
    return Promise.resolve();
  }
  gridView = (e) => {
    this.props.dispatch(clearBreadcrumb());
    const items = e.dataItem;
    const val = items.id;
    if (items.type === "Fiat") {
      this.props.dispatch(
        setBreadcrumb({
          key: "/withdraw/" + val + `/fiat`,
          val: ((items.transactionId) + ((items.transactionId || items.transactionId === null) ? ("  " + "/") : " ") + " " + items.status)
        })
      );
     // this.props.history.push(`/withdraw/${val}/fiat`);
      this.props.history.push({
        pathname: `/withdraw/${val}/fiat`,
        state: { details: e.dataItem }
        });
    }
    else {
      this.props.dispatch(
        setBreadcrumb({
          key: "/withdraw/" + val + `/crypto`,
          val: ((items.transactionId) + ((items.transactionId || items.transactionId === null) ? ("  " + "/") : " ") + " " + items.status)
        })
      );
      //this.props.history.push(`/withdraw/${val}/crypto`);
      this.props.history.push({
        pathname: `/withdraw/${val}/crypto`,
        state: { details: e.dataItem }
        });
    }
  };
  handleCheckbox = (prop, e) => {
    this.setState({ ...this.state, isClickedStateChange: {} ,selectedKytTxnId:[]})
    this.formref.current?.resetFields();
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection, selectedObjs,selectedKytTxnId } = this.state;
    let idx = selection.indexOf(rowObj.id);

    if (idx > -1) {
      selection.splice(idx, 1);
      selectedObjs.splice(idx, 1);
      selectedKytTxnId.splice(idx,1)

    } else {
      selection.push(rowObj.id);
      selectedObjs.push(rowObj);
      selectedKytTxnId.push(rowObj?.kytTxnId)

    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: selectedObjs[0] || rowObj,
      stateChange: rowObj,
      selectedObjs,
      selection,
      warningMsg: null,selectedKytTxnId
    });
    this.formref.current?.resetFields();
  };
  checkMultipleStateCondition = () => {
    return this.state.selectedTab === 2 && this.state.selection.length > 1;
  }
  getTabName = () => {
    return this.state.selectedTab === 1 ? 'Send Fiat' : 'Send Crypto';
  }
  
   statusUpdate=()=>{
    if (this.state.selection?.length == 0) {
      this.setState({ ...this.state, warningMsg: 'Please select the one record' });
  } else if (this.state?.selection?.length > 1) {
      this.setState({ ...this.state, warningMsg: "Please select only one record", selection: [], check: false, selectedObjs: [], selectedObj: {} });
  } else if (this.state.selectedObj?.status == "Draft") {
      this.setState({ ...this.state, warningMsg: 'The record is in draft state, so it cannot be changed.', selectedObj: {}, selection: [], check: false, selectedObjs: [] })

  }
  else if (this.state.selectedObj?.lockUnlock == "Locked") {
      this.setState({ ...this.state, warningMsg: 'This transaction was locked, Please unlock before state change', selectedObj: {}, selection: [], check: false, selectedObjs: [] })
  } 
  else{
    this.setState({...this.setState,showStateChange:true})

  }
   }
  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Approve":
        status = "Approved";
        break;
      case "Rejected":
        status = "Rejected";
        break;
      case "Reject":
        status = "Rejected";
        break;
      case "Pending":
        status = "Pending";
        break;
      case "Failed":
        status = "Failed";
        break;
      case "Queued":
        status = "Queued";
        break;
      case "Completed":
        status = "Completed";
        break;
      default:
        return null;
    }
    message.success({
      content: "Record " + status + " successfully",
      className: "custom-msg",
      duration: 1
    });
  };
  error = () => {
    message.error("Please select the one record");
  };
  

  

  handleImpNote = (e) => {
    if (e.currentTarget.checked) {
      this.setState({ ...this.state, selectedObj: { ...this.state.selectedObj, isCheckBox: true } });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    } else {
      this.setState({ ...this.state, selectedObj: { ...this.state.selectedObj, isCheckBox: false } });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    }
  }
  stateChange = (val, prop) => {

    if (val === "Reject" || val === "Approve") {
      this.formref.current.setFieldsValue({ statusRemarks: "", rejectReason: "", BankValueDate: "" })
    }
    this.setState({ ...this.state, btnDisabled: true, stateChange: { status: val }, isClickedStateChange: { status: val } });
    this.formref.current.setFieldsValue({ statusRemarks: "", rejectReason: "", BankValueDate: "" })
  };
  handleCancel = (e) => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      modal: false,
      selection: [],
      selectedObjs: [],
      check: false,
      error: null,
      statuserrorMsg: null,
      isClickedStateChange: {}
    });
    this.formref.current?.resetFields();

    if (this.state.withdraw) {
      this.gridFiatRef.current?.refreshGrid();
    } else {
      this.gridCryptoRef.current?.refreshGrid();
    }
  };
  onActionClick = (key) => {
    const action = {
      "Create Case": "createCase",
      "State Change": "statusUpdate",
      "Lock/Unlock": "CheckLockUnLock",
      "Sumsub": "SumSub",
      "Edit Commissions": "editComissions",
      "Re-Run Score": "reRun"

    };
    this[action[key]]();
  };

  editComissions = () => {
    this.props.dispatch(clearBreadcrumb());
    const { selection, selectedObjs } = this.state;
    if (selection.length == 0) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select the one record", error: null, });
    }
    else if (selection.length > 1) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select only one record", error: null });
    } else {
      const items = selectedObjs[0];
      const val = items.id;
      this.props.dispatch(
        setBreadcrumb({
          key: "/withdraw/" + val + "/fiat/isCommissionCheck",
          val: items.transactionId + " / " + items.status,

        })
      );

      this.props.history.push(`/withdraw/${val}/fiat/isCommissionCheck`);

    }
  }

  showMoreDetails = (props) => {
    this.setState({ ...this.state, isModal: true, getLockandUnLockDetails: props.dataItem.lockUnlockHistory });
  }
  CheckLockUnLock = () => {
    const { selection } = this.state;
    if (selection.length == 0) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select the one record", error: null, selectedObj: {} });
    }
    else if (selection.length > 1) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select only one record", error: null, selectedObj: {} });
    } else {
      this.setState({ ...this.state, isCheckLock: true })
    }

  }
  LockedPopClose = () => {
    this.setState({ ...this.state, isModal: false, isCheckLock: false, selection: [], selectedObj: {}, check: false, selectedObjs: [] })
  }
  SumSub = async () => {
    const { selection } = this.state;
    let obj = {
      "Ids": this.state?.selection,
      "ScreenName": this.state.selectedTab === 1 ? 'Send Fiat' : 'Send Crypto',//"withdraw",
    }
    if (selection.length == 0) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select the one record", error: null, });
    } else {
      let res = await Sumsub(obj, this.state.selectedTab === 1 ? 'Fiat' : 'Crypto');
      if (res.ok) {
        window.open(res.data, '_self')
        this.setState({ ...this.state, isCheckLock: false, selection: [], selectedObj: {}, check: false })
      } else {
        this.setState({ ...this.state, warningMsg: apiCalls.isErrorDispaly(res), selection: [], selectedObj: {}, check: false, selectedObjs: [] })
      }
    }
  }

  renderWarningMsg = () => (
    this.state.warningMsg !== undefined && this.state.warningMsg !== null && (
      <Alert
        className="mb-16 w-100"
        type="warning"
        description={this.state.warningMsg}
        showIcon
      />
    )
  )
  renderStatusError = () => {
    const { statuserrorMsg } = this.state;
    return statuserrorMsg !== undefined && statuserrorMsg !== null && (
      <Alert
        className="mb-12 send-poperror"
        type="error"
        description={statuserrorMsg}
        showIcon
      />
    )
  }
  renderCheckBox = () => {
    const {
      selectedObj,
      withdraw,
      stateChange
    } = this.state;
    return !withdraw && stateChange.status == "Approved" && stateChange.status == "Rejected" &&
      <div className="mb-8">
        <Alert message={<Form.Item
          name="isCheckBox"
          valuePropName="checked"
          className="mb-0"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please select checkbox')),
            },
          ]}
        ><div className="d-flex">
            <label className="text-center custom-checkbox mr-8">
              <input
                name="check"
                type="checkbox"
                checked={selectedObj.isCheckBox}
                onChange={(e) => this.handleImpNote(e)}
                disabled={selectedObj.status === "Rejected" || selectedObj.status === "Approved" || selectedObj.status?.indexOf("Approved") > -1}
              />
              <span></span>
            </label>Important Note:</div></Form.Item>}
          description={<p className="fw-400 fs-14 mb-5 l-height-normal">Please ensure that transaction has been successfully completed prior to approval. Please click the checkbox above to confirm. </p>}
          type="info"
          className="imp-alert"
        /></div>
  }
  isField1Disabled(disabledStatuses) {
    const { selectedObj } = this.state;
    return disabledStatuses.includes(selectedObj.status);
  }


  renderNetWorkField = () => {
    const { stateChange, selection, selectedObjs } = this.state;
    selectedObjs[0]?.type != "Fiat" && stateChange.status == "Approved" && stateChange.status == "Rejected" && selection.length === 1 &&
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          name="network"
          label="Network"
          className="input-label"
        >
          <Input placeholder="Net Work"
            disabled
            maxLength={150}
            className='cust-input' />
        </Form.Item>
      </Col>

  }

  reRun = async () => {
    const { selection,selectedKytTxnId } = this.state;
    if (selection.length == 0) {
      this.setState({ ...this.state, errorMessage: null, selection: [], selectedObjs: [], warningMsg: "Please select the one record", error: null, });
    }
    else if(selectedKytTxnId.some(item =>item == null)){
      if (selection.length == 1) {
            this.setState({...this.state,warningMsg:"For this transaction, you don't have a kyt monitoring Txn ID. Re-Run score not possible."})
      }
      else{
        this.setState({...this.state,warningMsg:"For this transactions, you don't have a kyt monitoring Txn ID. Re-Run score not possible."})

      }
    }
    else {
      let obj = {
        "ids": this.state.selection,
        "kytTxnId": this.state.selectedKytTxnId,
        "ScreenName": "withdraw",
        ModifiedBy: this.props.userConfig.userName,
      }
      let res = await reRunScore(obj);

      if (res.ok) {
        this.setState({ ...this.state, check: false, selection: [], selectedObjs: [],selectedKytTxnId:[], errorMessage: null, warningMsg: null, error: null });
        if (selection.length == 1) {
          if (this.state.selectedTab === 1) {
            message.success({
							content: "Re-Run Score successfully updated.",
							className: "custom-msg",
							duration: 4,
						});
            this.gridFiatRef.current?.refreshGrid();
          }
          else {
            message.success({
							content: "Re-Run Score successfully updated.",
							className: "custom-msg",
							duration: 4,
						});
            this.gridCryptoRef.current?.refreshGrid();

          }
        } else {
          this.setState({ ...this.state, reRunModal: true })
        }

      }else{
        this.setState({...this.state,warningMsg:apiCalls.isErrorDispaly(res)})
      }


    }
  }
  handleRerunCancel = () => {
    this.setState({ ...this.state, reRunModal: false })
    if (this.state.selectedTab === 1) {
      this.gridFiatRef.current?.refreshGrid();
    }
    else {
      this.gridCryptoRef.current?.refreshGrid();

    }
  }
 

  handleRemarks = (e) => {
    const value = e.target.value;
    this.setState({
      ...this.state,
      isRemarks: value,
      btnDisabled:true
    });
  };
  
  handleSuccess=()=>{
		this.setState({
			...this.state,
			modal: false,
			selection: [],
			selectedObjs: [],
			selectedObj: {},
			stateChange: {},
			check: !this.state.withdraw,
			error: null,
			stateLoading: true,
			btnDisabled: false,
			appwerningMsg: null,
			showStateChange:false
		}, () => {
      if (this.state.selectedTab === 1) {
        this.gridFiatRef?.current.refreshGrid()
      } else {
        this.gridCryptoRef?.current.refreshGrid()
      }
		});
	}

  render() {
    const { 
      gridUrl,
      gridUrlC,
      withdraw,
      caseModal,
      selectedTab,
      selectedObj
    } = this.state;
    let isAcessdenied;
    let istabAcessdenied;
    if (this.props.permissions[this.props.permissions.currentScreenTabId]) {
      const isview = this.props.permissions[this.props.permissions.currentScreenTabId]?.filter((item) => item.permissionName === "view" && item.values);
      if (isview?.length === 0) {
        isAcessdenied = true;
      } else {
        isAcessdenied = false;
      }
    }
    if (this.props.permissions['tabAccessDenied'] && istabAcessdenied !== true) {
      istabAcessdenied = true;
      if (!this.state.istabAcessdenied) {
        this.setState({ ...this.state, istabAcessdenied: true, statuserrorMsg: null, cryptoModal: false, fillGasModal: false, sweepModal: false, modal: false, caseModal: false, })
      }
    }
    return (
      <>
        {this.renderWarningMsg()}
        <div className="d-flex justify-content align-center">
          <Radio.Group
            value={selectedTab}
            onChange={this.handleWithdraw}
            className="buysell-toggle"
          >
            <Radio.Button value={1}> Fiat </Radio.Button>
            <Radio.Button value={2}> Crypto </Radio.Button>
          </Radio.Group>
        </div>
        {(isAcessdenied || istabAcessdenied) && <AccessDenied />}
        {!(isAcessdenied || istabAcessdenied) && <>{(selectedTab === 1) ? (
          <List
            showActionBar={true}
            pKey={"Send Fiat"}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrl}
            ref={this.gridFiatRef}
            key={gridUrl}
            columns={this.gridColumns}
            showExcelExport={true}
          />
        ) : (
          <List
            showActionBar={true}
            pKey={"Send Crypto"}
            onActionClick={(key) => this.onActionClick(key)}
            url={gridUrlC}
            ref={this.gridCryptoRef}
            key={gridUrlC}
            columns={this.withdrawColumns}
            showExcelExport={true}
          />
        )}</>}
        <LockedComponent getLockandUnLockDetails={this.state.getLockandUnLockDetails} isModal={this.state.isModal}
          LockedPopClose={() => this.LockedPopClose()} isCheckLock={this.state.isCheckLock} selectedObj={selectedObj}
          isGridRefresh={() => this.gridRefresh()} screen={selectedTab == 1 ? "WithdrawFiat" : "Withdrawcrypto"} ModifiedBy={this.props.userConfig.userName} />
       

        <Modal
          title="Create Case"
          visible={caseModal}
          width={1000}
          style={{ top: 16 }}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
            </Tooltip>
          }
          destroyOnClose
          footer={null}
        >
          <CreateCase
            FromScreen={this.state.selectedObjs[0]?.type === "Fiat" ? 'SendFiat' : 'SendCrypto'}
            ScreenId={this.state.selectedObjs[0]?.id}
            CreateCaseId={this.state.selectedCaseId}
            ScreenName={this.state.selectedObjs[0]?.type === "Fiat" ? 'SendFiat' : 'SendCrypto'}
            closeModal={this.hideCaseModal} />
        </Modal>
        <Modal
          title="Re Run Score?"
          className="custom-width text-break deposit-pop"
          visible={this.state.reRunModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleRerunCancel} />
            </Tooltip>
          }
          footer={<>

            <Button
              type="primary"
              className="primary-btn"
              onClick={this.handleRerunCancel}
            >
              OK
            </Button>
          </>}>
          <p>Please wait and check again after 5 minutes. Your re-run score is updating. </p>
        </Modal>
        <WithdrawStateChange
        visible={this.state.showStateChange} 
				withdraw={withdraw} 
        selection={this.state.selection}
				selectedTab={this.state.selectedTab}
				selectedObj={this.state.selectedObj} 
				status={this.state.selectedObj.status}
					onClose={() => {
						this.setState({...this.state,showStateChange:false,selection: [], selectedObjs: []})

						if (this.state.withdraw) {
							this.gridFiatRef.current.refreshGrid();
						} else {
							this.gridCryptoRef.current.refreshGrid();
						}
					}}
					onSuccess={() => {
						this.handleSuccess()
					}}
					onNote={(v)=>this.handleImpNote(v)}
        />
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig, permissions }) => {
  return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData, permissions };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Withdraw);

