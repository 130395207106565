import { clientApi} from "../../api";
import { ApiControllers } from "../../api/config";
const getCustomer = (userId) => {
    return clientApi.get(ApiControllers.customers + `Customers/${userId}`);
}
const getAccountLu = () => {
    return clientApi.get(ApiControllers.customers + "Types");
}
const getNameLu = (email, name) => {
    return clientApi.get(ApiControllers.customers + `Customers/${email}/${name}`)
}
const saveCustomer = (obj) => {
    return clientApi.put(ApiControllers.customers + `UpdateAccount`, obj);
}

const getCryptoDetails = (id) => {
    return clientApi.get(ApiControllers.commissions + `Multiplier/Crypto/${id}`);
}
const getFiatDetails = (id) => {
    return clientApi.get(ApiControllers.commissions + `Multiplier/Fiat/${id}`);
}
const saveFiat = (obj) => {
    return clientApi.put(ApiControllers.commissions + `Multiplier/Fiat`, obj);
}
const saveCrypto = (obj) => {
    return clientApi.put(ApiControllers.commissions + `Multiplier/Crypto`, obj);
}
const getCaseLu = (name) => {
    return clientApi.get(ApiControllers.customers + `AdminUsers/${name}`)
}
const getVerification = (customerid) => {
    return clientApi.get(ApiControllers.customers + `Custumer/Verification/${customerid}`)
}
const saveVerification = (obj) => {
    return clientApi.put(ApiControllers.customers + `Verification`, obj)
}
const getRiskScreenDetails = (id) => {
    return clientApi.get(ApiControllers.reskscreen + `GetRiskScreenDetails/${id}`);
}
const ApproveCard = (obj)=>{
    return clientApi.put(ApiControllers.customers+`Cards`,obj);
}
const saveLockUnlock=(customerid,status,loginCustomerId)=>{
    return clientApi.put(ApiControllers.master + `Customer/Status/${customerid}/${status}/${loginCustomerId}`)  
}
const getRefferalCode = (id,customerid) =>{
    return clientApi.get(ApiControllers.customers + `GetReferralMemberDetails/${id}/${customerid}`); 
}
const saveRefferalCode = (customerId,obj) =>{
    return clientApi.put(ApiControllers.partner + `UpdatePartnerDetails/${customerId}`,obj); 
}
const activeInactive = (obj) => {
	return clientApi.put(ApiControllers.master + `Customer/Status`, obj);
};

const countryLu = (memberId, isDeepSearch) => {
    return clientApi.get(ApiControllers.kompany + `countrylu?customerId=${memberId}&isDeepSearch=${isDeepSearch}`);
}

const getCompanyDetailss = (customerId,kompanyId) => {
    return clientApi.get(ApiControllers.kompany + `companyDetailss?customerId=${customerId}&kompanyId=${kompanyId}`);
}

const getCustomerKompanyDetails=(customerId)=>{
    return clientApi.get(ApiControllers.kompany + `companyDetails?CustomerId=${customerId}`);
}
const getCustomerStates = (screen_name, from_state) => {
    return clientApi.get(ApiControllers.common + `statechange/${screen_name}/${from_state}`)
}
const confirmStateChange = (obj) => {
    return clientApi.put(ApiControllers.customers + `StateUpdate`, obj);
}
const getRiskScreenDetailsData = (customerId)=>{
    return clientApi.get(ApiControllers.reskscreen + `GetRiskScreenData/${customerId}`)
}

const saveRiskScreenDetailsData = (obj)=>{
    return clientApi.post(ApiControllers.reskscreen + `SaveRiskScreenData`,obj)
}
const saveDocReply = (obj) => {
    return clientApi.post(ApiControllers.documents + `Accounts`, obj);
  };
  const getFileURL = (customerId) => {
    return clientApi.get(ApiControllers.common + `FilePreview/${customerId}`);
  };
  const getCommissionsData=(customerId)=>{
    return clientApi.get(ApiControllers.commissions + `CustomerFee/${customerId}`);
  }
  const getCommissionsLU=(customerId)=>{
    return customerId ? clientApi.get(ApiControllers.commissions + `CustomerTemplateLu/${customerId}`) : clientApi.get(ApiControllers.commissions + `CommissionTemplateLu`);
  }
  const getCustomerLogs=(id)=>{
    return clientApi.get(ApiControllers.customers + `CustomerLogs/${id}`);
  }
  const saveCredits=(obj)=>{
    return clientApi.put(ApiControllers.customers + `Customer/Credit`,obj)
  }
  const getCommissionTemplateData=(commissionId)=>{
        return clientApi.get(ApiControllers.commissions + `Customer/Commission/${commissionId}`);
    }
   
  const saveCustomerFee = (obj) => {
    return clientApi.put(ApiControllers.commissions + `BatchSave`, obj);
}
const getAvailableBanks=(customerId,commTemplateId)=>{
    return clientApi.get(ApiControllers.customers + `Customer/AvailableBanks/${customerId}/${commTemplateId}`);
}
const saveBanks=(obj)=>{
    return clientApi.put(ApiControllers.customers + `Customer/Banks`, obj);
}
const changeEmail=(obj)=>{
    return clientApi.put(ApiControllers.customers + `ChangeEmail`,obj)
}
const changePhoneNo=(obj)=>{
    return clientApi.put(ApiControllers.customers + `ChangePhoneNo`,obj)
}
const getWalletDetails=(id)=>{
    return clientApi.get(ApiControllers.customers + `WalletAddress/${id}`);
}
const getAllCustomerBackupAddress=(id)=>{
    return clientApi.get(ApiControllers.addressbook + `BackupAddress/${id}`);
}
const getCredits=(customerId)=>{
    return clientApi.get(ApiControllers.customers + `Customer/Credit/${customerId}`)
  }

const getCustomerDetails=(url)=>{
    return clientApi.get(ApiControllers.sumSub+ url);
}
const getPOADetails=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `GetPoaCheckDetails/${customerId}`);
}
const getDOCDetails=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `documentdetails/${customerId}`);
}
const sumsubRefreshData=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `sumsubrefreshdata/${customerId}`);
}
const getCtrlOwnerShipDetails=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `SumsubreOwnershipStructure/${customerId}`);
}
const getCompanyStructureApi=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `SumsubCompanyStructure/${customerId}`);
}
const getCompanyOfficersApi=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `SumsubCompanyOfficers/${customerId}`);
}
const getCompanyCheckApi=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `SumsubCompanyCheck/${customerId}`);
}
const getCompanySignificantPersonsApi=(customerId)=>{
    return clientApi.get(ApiControllers.sumSub+ `SumsubCompanySignificantPersons/${customerId}`);
}
const getRiskScreenData = (customerId)=>{
    return clientApi.get(ApiControllers.reskscreen + `GetRiskScreenData/${customerId}`)
}
const customerDeductionData=(userId)=>{
    return clientApi.get(ApiControllers.withdraw + `Deductions/customerfee/${userId}`);
}
const customerDeductionsSaveData=(userId,obj)=>{
    return clientApi.put(ApiControllers.withdraw + `Deductions/customerfees/${userId}`,obj)
}
const bankNameLu=()=>{
    return clientApi.get(ApiControllers.customer + `BankNames`);
}
const updateBankName=(obj)=>{
    return clientApi.put(ApiControllers.customer + `UpdateLPBank`,obj)
}
const updateStateChange=(obj)=>{
    return clientApi.put(ApiControllers.customer + `LpStatusChange`,obj)
}
const lpActiveInactive=(obj)=>{
    return clientApi.post(ApiControllers.customer + `LPActiveInactive`,obj)
}
const getLpWallets = () => {
    return clientApi.get(ApiControllers.common + `ControlCodes?codeCategory=Wallets`)
}
const walletFreeze=(obj)=>{
    return clientApi.post(ApiControllers.customer + `Wallet/Freeze`,obj)
}
const walletUnFreeze=(obj)=>{
    return clientApi.post(ApiControllers.customer + `Wallet/UnFreeze`,obj)
}

export {getCustomerStates,confirmStateChange, getCustomer, getCaseLu, getNameLu,
     saveFiat, saveCrypto, getCryptoDetails, getFiatDetails, getAccountLu, 
      saveCustomer, getVerification, saveVerification, 
     getRiskScreenDetails,ApproveCard,saveLockUnlock,getRefferalCode,
     saveRefferalCode, activeInactive,countryLu,getCustomerKompanyDetails,
     getCompanyDetailss,getRiskScreenDetailsData,saveRiskScreenDetailsData,
     saveDocReply,getFileURL,getCommissionsData,getCommissionsLU,getCustomerLogs,
     saveCredits,getCommissionTemplateData,saveCustomerFee,getAvailableBanks,saveBanks,changeEmail,changePhoneNo,getWalletDetails,getAllCustomerBackupAddress,getCredits,getCustomerDetails,getPOADetails,getDOCDetails,sumsubRefreshData,
     getCtrlOwnerShipDetails,getCompanyStructureApi,getCompanyOfficersApi,getCompanyCheckApi,getCompanySignificantPersonsApi,customerDeductionData,customerDeductionsSaveData,getRiskScreenData,bankNameLu,updateBankName,updateStateChange,
     lpActiveInactive,getLpWallets,walletFreeze,walletUnFreeze}
