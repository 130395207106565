

import React, { Component } from 'react';
import { Row, Col, Typography, Alert } from 'antd';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import Loader from '../loader.component';
import { handleBuysell } from '../../reducers/buysellReducer';
import { setCurrentAction } from '../../reducers/actionsReducer';
import Moment from 'react-moment';
import apiCalls from '../../api/apiCalls';
import { publishShowActions } from '../grid.component/subscribir';
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { Link } from 'react-router-dom';
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import config from "../../config";
const { Title } = Typography
class BuySellSummary extends Component {
    state = {
        buysell: {},
        isLoading: false,
    }
    componentDidMount() {
        this.props.setAction(null)
        var selectedId = this.props.match.params.id
        this.props.fetchbuysellData(selectedId)
        publishShowActions(false);

    }
    redirectCustomerView = () => {

        const items = this.props.buysellStore.buysellData;
        const val = items.data.customerId;  
        this.props.dispatch(
            setBreadcrumb({
                key: "/customers/details/" + val,
                val: items.data.customerName
            })
          );  
        localStorage.setItem("MyURL","/customers/details/" + val)
        window.open("/customers/details/" + val);
    };
    redirectCaseView = (caseData) => {
        const val = caseData?.caseId;
        this.props.dispatch(updateCurrentScreen("cases"));
        this.props.history.push("/cases/1/" + val + "/view");
        this.props.dispatch(
          setBreadcrumb({ key: "/cases/1/" + val + "/view", val: caseData.caseNumber })
        );
      };
      renderBuysellDetails=(lable,value,bSClassname)=>(
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
        <span className={`icon md ${bSClassname}`} />
        <div>
            <label className="kpi-label">{lable}</label>
            <div className="kpi-val">{value || "-"} {lable == "Commission" &&  this.props.buysellStore.buysellData?.cTypeFlat}</div>
        </div>
    </Col>
      )
      renderBuysellCommissionDetails=(commissionlable,commissionValue,commissionClass)=>(
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
        <span className={`icon md ${commissionClass}`} />
        <div>
            <label className="kpi-label">{commissionlable}</label>
            <div className="kpi-val">
                {commissionValue ?
                     ( <NumberFormat decimalScale={8} value={commissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true}  />)
                    : ("0")}
                </div>
        </div>
    </Col>
      )

    render() {
        const { data: buysellData, isLoading, error } = this.props.buysellStore.buysellData;

        return (
            <>
                {isLoading && <Loader />}
                <div className="transaction-text">
                    <Title className="page-title">
                        Trade Detail View
                    </Title>
                </div>
                {error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon description={error} />}
                <Row gutter={8}>
                    <Col xl={24} xxl={24} className="bank-view">
                        <Row className="kpi-List">
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md date" />
                                <div>
                                    <label className="kpi-label">Submission Date</label>
                                    <div className="kpi-val"><Moment format={config?.dateFormates?.dateTimeFormate}>{buysellData.createdDate ? apiCalls.convertUTCToLocalTime(buysellData.createdDate) : buysellData.createdDate}</Moment>
                                    </div>

                                </div>
                            </Col>
                            {this.renderBuysellDetails("Customer/Contract ID",buysellData?.referenceId,"user")}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md user" />
                                <div>
                                    <label className="kpi-label">{buysellData?.isBusiness === true ? <><b>Business Name</b>/<>Personal Name</> </> : <><>Business Name</>/<b>Personal Name</b></>}</label>
                                    <br />
                                    <span className="kpi-val c-pointer">
                                        <Link onClick={() => this.redirectCustomerView()}>
                                            {buysellData?.customerName}
                                        </Link>
                                    </span>
                                </div>
                            </Col>
                            {this.renderBuysellDetails("User Name", buysellData?.customerUserName, "user")}
                            {this.renderBuysellDetails("Email", buysellData?.customerEmail, "email")}
                            {this.renderBuysellDetails("Transaction", buysellData?.type, "acnum")}
                            {this.renderBuysellDetails("From Wallet", buysellData?.fromWalletCode, "wallet")}
                            {this.renderBuysellCommissionDetails("From Value", buysellData?.fromValue, "file")}
                            {this.renderBuysellDetails("To Wallet", buysellData?.toWalletCode, "wallet")}
                            {this.renderBuysellCommissionDetails("To Value", buysellData?.tovalue, "file")}
                            {this.renderBuysellCommissionDetails("Before Value", buysellData?.beforeValue, "cash")}
                            {this.renderBuysellCommissionDetails("After Value", buysellData?.afterValue, "cash")}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md commission" />
                                <div>
                                    <label className="kpi-label">Commission</label>
                                    <div className=" kpi-val  fw-700">
                                        {buysellData?.comissionValue }
                                        {"   "}{ buysellData?.cTypeFlat}
                                            
                                            
                                    </div>
                                </div>
                            </Col>
                            {this.renderBuysellCommissionDetails("Commission in Base", buysellData?.comissionValueInBase, "cash")}
                            {this.renderBuysellCommissionDetails("Fees", buysellData?.effectiveFee, "cash")}
                            {this.renderBuysellCommissionDetails("Tier Discount", buysellData?.tierDiscount, "cash")}
                            {this.renderBuysellCommissionDetails("SuisseBase Credit Used", buysellData?.sbCredit, "cash")}
                            {this.renderBuysellCommissionDetails("Provider Commission", buysellData?.pComissionValue, "commission")}
                            {this.renderBuysellCommissionDetails("Provider Commission in Base", buysellData?.pComissionValueInBase, "cash")}
                            {this.renderBuysellCommissionDetails("Profit", buysellData?.profit, "difference")}
                            {this.renderBuysellCommissionDetails("Profit in Base", buysellData?.profitInBase, "cash")}
                            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                <span className="icon md date" />
                                <div>
                                    <label className="kpi-label">Approval Date</label>
                                    <div className="kpi-val"><Moment format={config?.dateFormates?.dateTimeFormate}>{buysellData.date ? apiCalls.convertUTCToLocalTime(buysellData.date) : buysellData.date}</Moment>
                                    </div>

                                </div>
                            </Col>
                            {this.renderBuysellDetails("Provider",buysellData?.provider,"file")}                           
                             {buysellData?.caseIds !== null &&<Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                                <span className='icon md case' />
                            <div>
                                <label className="kpi-label d-block">Case Number</label>
                                <div
                                    className="fw-600 fs-14">
                                    {buysellData?.caseIds?.map(item => <Link className="c-pointer" onClick={() => this.redirectCaseView(item)}>{item.caseNumber}<br /></Link>)}
                                    
                                </div>
                                </div>
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

const connectStateToProps = ({ buysellStore }) => {
    return { buysellStore }
}
const connectDispatchToProps = dispatch => {
    return {
        fetchbuysellData: (id) => {
            dispatch(handleBuysell(id))
        },
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(BuySellSummary);


