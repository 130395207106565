import React, { Component } from "react";
import { Form, Select, Tooltip, message, Modal, Button, Row, Col, Input, Alert } from "antd";
import List from "../grid.component";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { connect } from "react-redux";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";
import Moment from "react-moment";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { validateContentRule} from '../../utils/custom.validator';
import config from "../../config";
import { BankReceiveUserNameLuSearch, GridColorsType, getBankCurrencyLookup, getBankStatusLookup } from "./api";
const { Option } = Select;
const { TextArea } = Input;

class BankWithdraw extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isBtnApprove: false,
      statuserrorMsg: null,
      werningMsg: null,
      check: false,
      selection: [],
      selectedObj: {},
      gridUrl: null,
      stateLoading: true,
      obj: {
        Id: "",
        CustomerId: "",
        State: "",
        ReasonofCancellation: "",
        remarks:"",
      },
      stateChange: {},
      selectedTab: 1,
      istabAcessdenied: false,
      isModal: false,
      stateLu:[],
      selectedState:null,
      pkeyValue:null,
      customerData: [],
      isLoading: false,
      searchObj: {
        currencyType:"All",
        statusType:'All',
				memberName: "00000000-0000-0000-0000-000000000000",
        
			},
      bankCurrencyTypesData: [],
      bankStatusTypeData: [],
      colorsCode:[]

    };
    this.gridEvolveRef = React.createRef();
    this.gridPyrrosRef = React.createRef();
    this.formref = React.createRef();
  }

  componentDidMount() {
    let gridUrlData;
    let pkeyData;
    const { pathname } = this.props?.location || {};
    if (pathname === '/pyrroswithdrawal') {
      gridUrlData = process.env.REACT_APP_GRID_API + "Bank/GetPyrrosWithDraw";
      pkeyData = "Pyrros Withdrawal";
    } else if (pathname === '/openpaydwithdrawal') {
      gridUrlData = process.env.REACT_APP_GRID_API + "Bank/GetOpenpaydWithDraw";
      pkeyData = "OpenPayd Withdrawal";
    } else {
      gridUrlData = process.env.REACT_APP_GRID_API + "Bank/GetEvolveWithDraw";
      pkeyData = "Evolve Withdrawal";
    }
    this.setState(prevState => ({ ...prevState, gridUrl: gridUrlData,pkeyValue:pkeyData }));
    this.BankStatusSearch();
    this.BankCurrencySearch();
    this.gridColorTypes();
  }
  openpaydGridColumns = [
    {
      field: "date", title: "Date", filter: true, isShowTime: true, locked: true, filterType: "date", footerCell: true,
      width: 200,
      customCell: (props) => (
          <div
            className="gridLink" onClick={() => this.widthdrawDetailsView(props)}>
            {props.dataItem?.date ? (
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
              </Moment>
            ) : (
              props.dataItem?.date
            )}
          </div>
      )
    },
    { field: "transactionId", title: "Transaction ID ", filter: true, width: 250 },
    { field: "referenceId", title: "Customer/Contract ID", filter: true, width: 250 },
    {
      field: "name",
      title: "Business Name/Personal Name",
      filter: true,
      width: 320,   
      customCell: (props) => (
        <td className="whitelist-name">
        <div className="d-flex">
        <div className="white-address address-width ">	{props.dataItem.name}</div>
          {props?.dataItem?.accountType !== null && (
            <div className="file-labels ml-8 fs-12 address-label address-label-width text-nowrap">
              {!props?.dataItem?.accountType  && "Digital Transfer" || props?.dataItem?.accountType && "Personal"}
            </div>
          )}</div>
        </td>
      ), 
    },
    { field: "receiverName", title: "Receiver's Name", filter: true, width: 150 },
    { field: "receiverBankName", title: "Receiver's Bank Name", filter: true, width: 200 },
    {
      field: "receiverBankAccount",
      title: "Receiver's Bank Account Number/IBAN",
      width: 300,
      filter: true
    },
    {
      field: "currency",
      title: "Currency",
      filter: true,
      width: 120,
      customCell: (props) => {
        const currencyColorConfig = this.state?.colorsCode?.find(colorConfig => colorConfig.currency === props.dataItem.currency);
        const currencyColor = currencyColorConfig?.color.toLowerCase() || 'black';
        const textColor = (currencyColor === 'blue' || currencyColor === 'darkgreen'||currencyColor === 'green') ? 'white' : 'black';
    
        return (
          <td className="whitelist-name">
            <div className={`white-address ibancolor ${currencyColor}`} style={{ color: textColor }}>
              {props.dataItem.currency}
            </div>
          </td>
        );
      },
    },
    { field: "grossAmount", title: "Gross Amount", filter: true, width: 150, dataType: "number", filterType: "numeric" },
    { field: "commission", title: "Commission", filter: true, width: 120, dataType: "number", filterType: "numeric" },   
    { field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "netAmount", title: "Net Amount", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "profitInBase", title: "Profit In Base", filter: true, width: 140, dataType: "number", filterType: "numeric" },
    { field: "bankTransactionStatus", title: "Bank Status", filter: true, width: 120 },
    { field: "state", title: "Status", filter: true, width: 100 },

  ];
  gridColumns = [
    {
      field: "",
      title: "",
      width: 50,
      locked: true,
      customCell: (props) => (
        <>
          {" "}
          <label className="text-center custom-checkbox">
            <input
              id={props.dataItem.id}
              name="check"
              type="checkbox"
              checked={this.state.selection.indexOf(props.dataItem.id) > -1}
              onChange={(e) => this.handleCheckbox(props, e)}
            />
            <span></span>{" "}
          </label>
        </>
      )
    },
    {
      field: "date", title: "Date", filter: true, isShowTime: true, locked: true, filterType: "date", footerCell: true,
      width: 200,
      customCell: (props) => (
          <div
            className="gridLink" onClick={() => this.widthdrawDetailsView(props)}>
            {props.dataItem?.date ? (
              <Moment format={config?.dateFormates?.dateTimeFormate}>
                {apiCalls.convertUTCToLocalTime(props.dataItem?.date)}
              </Moment>
            ) : (
              props.dataItem?.date
            )}
          </div>
      )
    },
    { field: "transactionId", title: "Transaction ID ", filter: true, width: 250 },
    { field: "referenceId", title: "Customer/Contract ID", filter: true, width: 250 },
    {
      field: "name",
      title: "Business Name/Personal Name",
      filter: true,
      width: 320,   
      customCell: (props) => (
        <td className="whitelist-name">
        <div className="d-flex">
        <div className="white-address address-width ">	{props.dataItem.name}</div>
          {props?.dataItem?.accountType !== null && (
            <div className="file-labels ml-8 fs-12 address-label address-label-width text-nowrap">
              {!props?.dataItem?.accountType  && "Digital Transfer" || props?.dataItem?.accountType && "Personal"}
            </div>
          )}</div>
        </td>
      ), 
    },
    { field: "receiverName", title: "Receiver's Name", filter: true, width: 150 },
    { field: "receiverBankName", title: "Receiver's Bank Name", filter: true, width: 200 },
    {
      field: "receiverBankAccount",
      title: "Receiver's Bank Account Number/IBAN",
      width: 300,
      filter: true
    },
    { field: "grossAmount", title: "Gross Amount", filter: true, width: 150, dataType: "number", filterType: "numeric" },
    { field: "commission", title: "Commission", filter: true, width: 120, dataType: "number", filterType: "numeric" },   
    { field: "providerCommission", title: "Provider Commission", filter: true, width: 220, dataType: "number", filterType: "numeric" },
    { field: "sweepSourceComission", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
    { field: "sweepDestinationComission", title: "Sweep Destination Commission", filter: true, width: 260, dataType: "number", filterType: "numeric" },
    { field: "netAmount", title: "Net Amount", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "profit", title: "Profit", filter: true, width: 120, dataType: "number", filterType: "numeric" },
    { field: "bankTransactionStatus", title: "Bank Status", filter: true, width: 120 },
    { field: "state", title: "Status", filter: true, width: 100 },
  ];
  widthdrawDetailsView = (e) => {
    const items = e.dataItem;
    const val = items.id;
    if (this.props?.location?.pathname === "/pyrroswithdrawal") {
      this.props.history.push("/pyrroswithdrawal/" + val + "/view");
    } else if (this.props?.location?.pathname === "/openpaydwithdrawal") {
      this.props.history.push("/openpaydwithdrawal/" + val + "/view");
    }else {
      this.props.history.push("/evolvewithdrawal/" + val + "/view");
    }
    let keyPrefix;
    const { pathname } = this.props?.location || {};
    if (pathname === '/pyrroswithdrawal') {
      keyPrefix = "/pyrroswithdrawal/";
    } else if (pathname === '/openpaydwithdrawal') {
      keyPrefix = "/openpaydwithdrawal/";
    } else {
      keyPrefix = "/evolvewithdrawal/";
    }
    const keyData = keyPrefix + `${val}` + "/view";
    this.props.dispatch(
      setBreadcrumb({
        key:keyData,
        val: `${items.userName} / ${items.state}`
      })
    );
  };
  handleCheckbox = (prop, e) => {
    const rowObj = prop.dataItem;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    let { selection } = this.state;
    let idx = selection.indexOf(rowObj.id);
    if (selection) {
      selection = [];
    }
    if (idx > -1) {
      selection.splice(idx, 0);
    } else {
      selection.push(rowObj.id);
    }
    this.setState({
      ...this.state,
      [name]: value,
      selectedObj: rowObj,
      selection,
      werningMsg: null
    });
  };
  statusUpdate = () => {
    if (!this.state.check) {
      this.setState({
        ...this.state,
        werningMsg: "Please select the one record",
      });
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            alert: false,
          }),
        1000
      );
    } else {
      this.getStatesLu();
      this.setState(
        {
          ...this.state,
          isModal: true,
          statuserrorMsg: null,
          stateChange: {
            status: this.state.selectedObj.state,
            customerId: this.state.selectedObj.customerId,
            ReasonofCancellation: this.state.selectedObj?.state!=="Approved"?this.state.selectedObj?.reasonOfCancellation:" ",
            remarks:this.state.selectedObj?.state==="Approved"?this.state.selectedObj?.remarks:" "
          }
        },
        () => {
          this.setState({
            ...this.state,
            stateLoading: true,
          });
          setTimeout(
            () =>
              this.setState({
                ...this.state,
                stateLoading: false,
              }),
            1000
          );

          setTimeout(
            () =>
              this.formref.current.setFieldsValue({
                ...this.state,
                statuserrorMsg: null,
                status: this.state.selectedObj.state,
                customerId: this.state.selectedObj.customerId,
                ReasonofCancellation: this.state.selectedObj?.state!=="Approved"?this.state.selectedObj?.reasonOfCancellation:" ",
                remarks:this.state.selectedObj?.state==="Approved"?this.state.selectedObj?.remarks:" "

              }),
            1000
          );
        }
      );
    }

  };

  success = (status) => {
    switch (status) {
      case "Approved":
        status = "Approved";
        break;
      case "Approve":
        status = "Approved";
        break;
      case "Rejected":
        status = "Rejected";
        break;
      case "Reject":
        status = "Rejected";
        break;
        case "Pending":
          status = "Pending";
          break;
      case "Failed":
        status = "Failed";
        break;
      case "Queued":
        status = "Queued";
        break;
      case "Completed":
        status = "Completed";
        break;
        default:
        return null;

    }
    message.success({
      content: "Record " + status + " successfully",
      className: "custom-msg",
      duration: 1
    });
  };
  approveSave = async (values) => {
    this.setState({ ...this.state, selection: [], check: true, stateLoading: false, isBtnApprove: true, werningMsg: null });
    let statusObj = this.state.obj;
    statusObj.Id = this.state.selectedObj.id;
    statusObj.CustomerId = this.state.selectedObj.customerId;
    statusObj.State = values.status || this.state.selectedObj.state;
    statusObj.ModifiedBy = this.props?.userConfig?.userName;
    if (values?.status === "Rejected") {
      statusObj.ReasonofCancellation = values.ReasonofCancellation;
      statusObj.remarks = ""
    }
    else if (values?.status === "Approved") {
      statusObj.remarks = values.remarks;
      statusObj.ReasonofCancellation = ""
    }
    let response;
    if(this.props?.location?.pathname ==="evolvewithdrawal"){
         response = await apiCalls.evolveSaveAdminTransfer(statusObj);
    }else{  
         response = await apiCalls.pyrrosSaveAdminTransfer(statusObj);
    }
    if (response.ok) {
      this.props.dispatch(setCurrentAction(null));
      this.success(statusObj.State);
      this.setState({
        ...this.state,
        isModal: false,
        selection: [],
        check: false,
        stateLoading: false,
        isBtnApprove: false, werningMsg: null,statuserrorMsg:null,selectedState:null
      });
      this.gridPyrrosRef.current?.refreshGrid();
    } else {
      this.setState({
        ...this.state,
        stateLoading: false,
         isBtnApprove: false,
        statuserrorMsg: apiCalls.isErrorDispaly(response),
      });
    }   
  };

  handleImpNote = (e) => {
    if (e.currentTarget.checked) {
      this.setState({ ...this.state, selectedObj: { ...this.state.selectedObj, isCheckBox: true } });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    } else {
      this.setState({ ...this.state, selectedObj: { ...this.state.selectedObj, isCheckBox: false } });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    }
  }
  stateChange = (val) => {
    let { stateChange } = this.state;
    stateChange.state = val;
    this.setState({ ...this.state, stateChange,selectedState:val });
    this.formref.current.setFieldsValue({ReasonofCancellation:null})
  };
  handleCancel = () => {
    this.props.dispatch(setCurrentAction(null));
    this.setState({
      ...this.state,
      isModal: false,
      selection: [],
      check: false,
      statuserrorMsg: null,
      werningMsg:null,
      selectedState:null
    });
  };
  status = () => {
    this.setState({ ...this.state, isModal: true });
  };
  handleClose = () => {
    this.setState({ ...this.state, isModal: false });
  };
  onActionClick = (key) => {
    const action = {
      "State Change": "statusUpdate"
    };
    this[action[key]]();
  };
  getStatesLu=async()=>{
    let res = await apiCalls.getStates("withdrawal",this.state.selectedObj.state)
    if(res.ok){
      this.setState({...this.state,stateLu:res.data})
    }else{
      this.setState({ ...this.state,stateErrorMsg: apiCalls.isErrorDispaly(res) });
    }
  }
  renderAlertMsg=(alertMsg)=>{
  return alertMsg !== undefined && alertMsg !== null && (
      <Alert
        className="mb-16 w-100"
        type="warning"
        description={alertMsg}
        showIcon
      />
    )
  }

  BankReceiveUserSearch = async (email, memberName) => {
		this.setState({ isLoading: true, errorMsg: null });
		
		const { pathname } = this.props?.location || {};
		if (pathname === `/openpaydwithdrawal`) {
			const response = await BankReceiveUserNameLuSearch(email, memberName);
		if (response.ok) {
			const newState = { isLoading: false, errorMsg: null };
			if (memberName) {
				newState.customerData = response.data;
			} else {
				newState.searchObj = { type: "All", memberName: response.data[0].customerId };
				newState.customerData = response.data;
			}
			this.setState(newState);
		} else {
			this.setState({ errorMsg: apiCalls.isErrorDispaly(response) });
		}
	};
}

BankCurrencySearch = async () => {
  let response = await getBankCurrencyLookup();
  if (response.ok) {
    this.setState({
      bankCurrencyTypesData: response.data,
      errorMsg:null
    });
  }else{
    this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
  }
};

BankStatusSearch = async () => {
  let response = await getBankStatusLookup();
  if (response.ok) {
    this.setState({
      bankStatusTypeData: response.data,
      errorMsg:null
    });
  }else{
    this.setState({...this.state,errorMsg:apiCalls.isErrorDispaly(response)})
  }
};

  handleUserChange = (event, type) => {
		let userVal = event.target.value;
		if (userVal != null && userVal.length > 2) {
			if (type === "memberName") {
				this.BankReceiveUserSearch(null, userVal);
			}
		}
	}
  handleChange = (value, prop) => {
    let val=""
		let { customerData,searchObj,bankCurrencyTypesData,bankStatusTypeData } = this.state;
    if (prop === "currencyType") {
     
        let index = bankCurrencyTypesData.findIndex(function (o) {
          return o.currency === value;
        });
        val = bankCurrencyTypesData[index].currency;
        searchObj[prop] = prop === "currencyType" ? val : value;
        this.setState({ ...this.state, searchObj });
    }
    else if (prop === "statusType") {
     
      let index = bankStatusTypeData.findIndex(function (o) {
        return o.status === value;
      });
      val = bankStatusTypeData[index].status;
      searchObj[prop] = prop === "statusType" ? val : value;
      this.setState({ ...this.state, searchObj });
  }
		else {
			const obj = customerData?.find((item) => item.name === value);
			searchObj[prop] =  obj?.customerId  ? obj?.customerId : null;
			this.setState({ ...this.state, searchObj });
		}

	};

  handleSearch = (values) => {
		let { searchObj } = this.state;
		this.setState({ ...this.state, searchObj }, () => {
			this.gridPyrrosRef.current.refreshGrid();
		});

	};
  gridColorTypes=async()=>{
    const res= await GridColorsType();
    if(res.ok){
      this.setState({ ...this.state, colorsCode:res?.data?.result });
    }
  }
  render() {
    const {
      werningMsg,
      statuserrorMsg,
      isBtnApprove,
      stateChange,
      pkeyValue,
      customerData,bankCurrencyTypesData,bankStatusTypeData,searchObj
    } = this.state;
		const options = customerData.map((item) => (
			<Option key={item.id} value={item.name}>
				{item.name}
			</Option>
		));
    const options2 = bankCurrencyTypesData.map((item) => (
			<Option key={item.id} value={item.currency}>
				{item.currency}
			</Option>
		));
    const options3 = bankStatusTypeData.map((item) => (
			<Option key={item.id} value={item.status}>
				{item.status}
			</Option>
		));
    const pathName = window.location.pathname.split('/')[1]
    return (
      <>
       {this.renderAlertMsg(werningMsg)}
       {pathName === 'openpaydwithdrawal' && <Row style={{ alignItems: "flex-end" }} className="mb-16">
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="customerId"
									className="input-label mb-0"
									label="User Name">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onKeyUp={(event) =>
											this.handleUserChange(event, "memberName")
										}
										onChange={(e) => this.handleChange(e, "memberName")}
										placeholder="Select Members">
										{options}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="currency"
									className="input-label mb-0"
									label="Currency ">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "currencyType")}
										placeholder="Select Currency">
										{options2}
									</Select>
								</Form.Item>
							</Col>
              <Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="status"
									className="input-label mb-0"
									label="Status">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "statusType")}
										placeholder="Select Status">
										{options3}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={12} lg={3} className="px-8">
								<Button
									type="primary"
									className="primary-btn px-24 search-btn mt-20"
									htmlType="submit"
                  onClick={()=>this.handleSearch()}>
									Search
								</Button>
							</Col>
						</Row> }  
        <List
          showActionBar={true}
          pKey={pkeyValue}
          onActionClick={(key) => this.onActionClick(key)}
          url={this.state.gridUrl}
          ref={this.gridPyrrosRef}
          key={this.state.gridUrl}
          columns={pathName === 'openpaydwithdrawal' ? this.openpaydGridColumns : this.gridColumns}
          showExcelExport={true}
          additionalParams={pathName === 'openpaydwithdrawal' && searchObj}
        />
        <Modal className="text-break"
          title="Confirm Pending/Approve/Reject?"
          visible={this.state.isModal}
          closeIcon={
            <Tooltip title="Close">
              <span className="icon md x c-pointer" onClick={this.handleCancel} />
            </Tooltip>
          }
          footer={null}
        >
          <div>
            {this.state.stateLoading && <Loader />}
            {this.renderAlertMsg(statuserrorMsg)}
            <Form
              ref={this.formref}
              className="ant-advanced-search-form"
              autoComplete="off"
              onFinish={this.approveSave}
            >
              <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="status"
                    label="State"
                    className="input-label"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      }
                    ]}
                  >
                    <Select
                      className="cust-input mb-0"
                      onChange={(e) => this.stateChange(e)}
                      disabled={this.state.selectedObj.state === "Approved" || this.state.selectedObj.state === "Rejected"}
                      placeholder="Select State"
                    >
                       {this.state?.stateLu?.map(item => <Select.Option value={item?.code}>{item?.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                {(this.state.selectedObj.state === "Rejected" || stateChange.state === "Rejected") &&(
              <Col xs={24}>
                  <Form.Item
                    name="ReasonofCancellation"
                    className="input-label"
                    label= "Reason For Rejection"
                    rules={[
                      {
                        required: true,
                        message: "Is required"
                      },
                      {
                        validator:validateContentRule
                      }
                    ]}
                    >
                    <TextArea
                      placeholder= "Reason For Rejection"
                      maxLength={100}
                      rows={4}
                      disabled={
                        this.state.selectedObj.state === "Rejected" ||
                        this.state.selectedObj.state === "Approved"
                      }
                      showCount
                    />
                  </Form.Item>
              </Col>)}
              </Row>
              <Form.Item className="mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn mr-8"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                  {(this.state.selectedObj.state !== "Approved" && this.state.selectedObj.state !== "Rejected") && <Button
                    type="primary"
                    key="submit"
                    className="primary-btn"
                    htmlType="submit"
                    disabled={!this.state?.selectedState}
                    loading={isBtnApprove}
                  >
                    Save
                  </Button>}
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ oidc, userConfig }) => {
  return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(BankWithdraw);
