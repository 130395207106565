import { fetchCurrencyConvertionValue } from "./api"
import {numberWithCommas} from '../../utils/service'

export const convertCurrency = async ({ from, to, value, isCrypto, customer_id, screenName }) => {
    if (customer_id) {
        const response = await fetchCurrencyConvertionValue({ from, to, value, isCrypto, customer_id, screenName });
        if (response.ok) {
            return response.data;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}
export const convertCurrencyDuplicate = async ({ from, to, value, isCrypto, customer_id, screenName }) => {
    if (customer_id) {
        const response = await fetchCurrencyConvertionValue({ from, to, value, isCrypto, customer_id, screenName });
        if (response.ok) {
            return response;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}
export const validatePreview = ({ localValue, cryptValue, wallet, minPurchase, maxPurchase,coinData }) => {
    const validate = {
        message: null,
        valid: true
    };
    if (localValue === "0" || cryptValue === "0") {
        validate.message = 'Amount must be greater than zero';
        validate.valid = false;
    }
    else if  ((!localValue && !cryptValue) || (parseFloat(localValue) === 0 || parseFloat(cryptValue) === 0)) {
        validate.message = 'Please enter amount to buy';
        validate.valid = false;
    }
    else if (wallet.avilable < Number(localValue)) {
        validate.message = 'Insufficient funds';
        validate.valid = false;
    }
    else {
        if (cryptValue < minPurchase) {
            validate.valid = false;
            validate.message = `You have entered an amount below the minimum purchase. The minimum amount is ${minPurchase} ${coinData?.coin}.
            `;
        } else if (parseFloat(cryptValue.replace(/,/g, "")) > maxPurchase) {
            validate.valid = false;
            validate.message = 'The maximum purchase amount is ' + 
            `${numberWithCommas(maxPurchase)}`
             + " "+coinData?.coin+". Please contact support for higher amounts.";
        }
    }
    return validate;
}