import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Button, Alert, Form, DatePicker, Modal, Typography, Input , Tooltip } from "antd";
import List from "../grid.component";
import moment from 'moment';
import Moment from 'react-moment';
import { setCurrentAction } from '../../reducers/actionsReducer';
import apiCalls from "../../api/apiCalls";
import TransactionComplianceDeatils from './transactionComplianceDeatils'
import TransactionSummary from './transactionSummary'
import SuccessMsg from './successMsg'
import {sendreceiveSteps as config} from './config';
import {setStep} from '../../reducers/transactionComplianceReducer'
import TransactionCryptoSummary from "./transactionCryptoSummary";
import TransactionCryptoDetialis from "./transactionCryptoDetialis";
import WithdrawCryptoSuccess from './withdrawCryptoSuccess';
import Config from "../../config";

const { Option } = Select;
const {Text } = Typography;
class TransactionComplience extends Component {
    formRef = React.createRef();
    constructor(props) {
      super(props);
      this.state = {
        selection:[],
        selectedObj: {},
        check: false,
        value: "",
        message: "",
        searchObj: {
          OperationType: "All",
          IsCheck:false,
          fromdate: "",
          todate: "",
        },
        transactionModal:false,
        transactionCryptoModal:false,
        gridUrl: process.env.REACT_APP_GRID_API + "TransactionsCompliance",
        operationType:[{name:"All"},{name:"Buy"},{name:"Sell"},
                        {name:"Deposit Crypto"},
                        {name:"Deposit Fiat"},
                     ]
  
      };
      this.gridRef = React.createRef();
    }
    handleSelectionChange = (prop, e) => {
      const rowObj = prop.dataItem;
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      const name = e.target.name;
      let { selection } = this.state;
      let idx = selection.indexOf(rowObj.id);
      if (selection) {
        selection = [];
      }
      if (idx > -1) {
        selection.splice(idx, 1);
      } else {
        selection.push(rowObj.id);
      }
      this.setState({
        ...this.state,
        [name]: value,
        selectedObj: rowObj,
        selection,
        warningMsg: null,
        message:null
      });
    };
    gridColumns = [
      {
        field: "",
        title: "",
         locked: true,
        width: 50,
        customCell: (props) => (
            <label className="text-center custom-checkbox">
              <input
                id={props.dataItem.id}
                name="check"
                type="checkbox"
                checked={this.state.selection.indexOf(props.dataItem.id) > -1}
                onChange={(e) => this.handleSelectionChange(props, e)}
              />
              <span></span>
            </label>
        )
      },
      { field: "transactionId", title: "Transaction ID", filter: true, width: 180, locked: true, },
      {
        field: "date",
        title: "Date",
        filter: true,
        isShowTime: true,       
        filterType: "date",
        width: 200,
        customCell: (props) => (
          <td>
            {props.dataItem?.date && <Moment format={Config?.dateFormates?.dateFormate}>{props.dataItem?.date ? apiCalls.convertUTCToLocalTime(props.dataItem?.date) : props.dataItem?.date}</Moment>}
          </td>
        )
      
      },
      { field: "operationType", title: "Transaction Type", filter: true, width: 180 },
      { field: "fullName", title: "Business Name/Personal Name", filter: true, width: 270 },
      { field: "email", title: "Email", filter: true, width: 200 },
      { field: "originalAmount", title: "Original Amount", filter: true ,width: 200, dataType: "number", filterType: "numeric"},
      { field: "amount", title: "Amount", filter: true ,width: 200, dataType: "number", filterType: "numeric"},
      { field: "currency", title: "Currency", filter: true,width: 150, },
      { field: "counter", title: "Counter (Days)", filter: true ,width: 150, dataType: "number", filterType: "numeric"},
      { field: "state", title: "Status", filter: true,width: 150 },
    ]
  

    handleChange = (val,type) => {
      let { searchObj } = this.state;
     let formatDate=val?moment(val).format('MM-DD-YYYY'):'';
    if(type==="fromdate" || type==="todate"){
      searchObj[type]=formatDate
      this.setState({ ...this.state, searchObj:searchObj });
    }else{
      searchObj[type]=val;
      this.setState({ ...this.state, searchObj:searchObj });
    }

    };
    handleOnClick=(e,type)=>{
      let { searchObj } = this.state;
      searchObj[type]=e.target.checked;
      this.setState({ ...this.state, searchObj:searchObj });
    }
  
  
    hideTransactionModal = () => {
      this.setState({ ...this.state,transactionModal:false, selection: [] } ,()=>{
        this.gridRef.current.refreshGrid();
      }
      )
        this.props.history.push("transactionscompliance")
    }
    hideTransactionCryptoModal = () => {
      this.setState({ ...this.state,transactionCryptoModal:false, selection: []  
      },()=>{this.gridRef.current.refreshGrid();
      })
        this.props.history.push("transactionscompliance")
    }
    handleSearch = () => {
      let { searchObj } = this.state;
      this.setState({ ...this.state, message:null,selection:[] })
      if(this.state.searchObj.OperationType && this.state.searchObj.todate===""&&this.state.searchObj.fromdate===""){
        this.setState({ ...this.state, searchObj,message:null }, () => {
          this.gridRef.current.refreshGrid();})
      }
      else if(this.state.searchObj.todate===""){
        this.setState({ ...this.state, message:"Please select the To Date" });
      }
      else if(this.state.searchObj.fromdate===""){
        this.setState({ ...this.state, message:"Please select the From Date" });
      }else
       if(this.state.searchObj.todate<this.state.searchObj.fromdate){
        this.setState({ ...this.state, message:"To Date must be greater than or equal to From Date." })

        }
        else{
        this.setState({ ...this.state, searchObj,message:null }, () => {
          this.gridRef.current.refreshGrid();
        });
      }
     
  
    };
  
    Withdraw = () => {
      if (!this.state.check) {
        this.setState({ ...this.state, transactionModal: false, message: 'Please select the one record',selection:[],check:false });
      }  else if(this.state.selectedObj.state==="Completed" || this.state.selectedObj.state==="Inprogress"){
        this.setState({ ...this.state, transactionModal: false, message: `You can't withdraw this transaction it is already completed`,selection:[],check:false });
      } else if(this.state.selectedObj?.counter < 60){
        this.setState({ ...this.state, transactionModal: false, message: `Only records with a 60-day counter are permitted for withdrawal`,selection:[],check:false });
      } else if(this.state.selectedObj.operationType==="Withdraw Fiat" ||this.state.selectedObj.operationType==="Deposit Fiat"||this.state.selectedObj.operationType==="Sell") {
        this.setState({ ...this.state, transactionModal: true, check:false,message:"" });
        this.props.dispatch(setStep("withdraw_transaction_selected"))
      }
      else{
        this.setState({ ...this.state, transactionCryptoModal: true,check:false,message:""  });
        this.props.dispatch(setStep("withdraw_transaction_crypto_selected"))
      }
    }
    handleFeatureChange(value, id) {
      let { searchObj } = this.state;
      searchObj[id] = value;
      this.setState({ ...this.state, searchObj: searchObj });
    }
    setFiatModal=(fiatModal)=>{
      this.setState({...this.state,transactionModal:fiatModal,selection:[],check:false})
    }

    setCryptoModal=(cryptoModal)=>{
      this.setState({...this.state,transactionCryptoModal:cryptoModal,selection:[],check:false})
    }
    onActionClick = (key) => {
      const actions = {
        Withdraw: "Withdraw",
      };
      this[actions[key]]();
    };
    renderContent = () => {
        const stepcodes = {
            withdraw_transaction_selected:<TransactionComplianceDeatils obj={this.state.selectedObj}/>,
            withdraw_transaction_summary: <TransactionSummary />,
            withdraw_transaction_success: <SuccessMsg setModal={this.setFiatModal}/>,
            withdraw_transaction_crypto_selected:<TransactionCryptoDetialis obj={this.state.selectedObj}/>,
            withdraw_transaction_crypto_summary:<TransactionCryptoSummary />,
            withdraw_transaction_crypto_success:<WithdrawCryptoSuccess setModal={this.setCryptoModal}/>

        }
        return stepcodes[config[this.props.transactionCompliance.stepcode]]
    }
    renderTitle = () => {
        const stepcodes = {
            withdraw_transaction_selected: <><span/><Text style={{marginLeft:"200px"}}>SEND FIAT</Text></>,
            withdraw_transaction_summary:<> <span 
            onClick={() => this.props.dispatch(setStep("withdraw_transaction_selected"))} 
            className="icon md lftarw-white c-pointer" 
            ></span> <Text style={{marginLeft:"165px"}}>SEND FIAT SUMMARY</Text></> ,
            withdraw_transaction_crypto_selected:<><span/><Text style={{marginLeft:"200px"}}>SEND CRYPTO </Text></>,
            withdraw_transaction_crypto_summary:<><span 
            onClick={() => this.props.dispatch(setStep("withdraw_transaction_crypto_selected"))} 
            className="icon md lftarw-white c-pointer"/>
            <Text style={{marginLeft:"165px"}}>SEND CRYPTO SUMMARY</Text></>,
            withdraw_transaction_success:  <span />,
            withdraw_transaction_crypto_success:<span/>
        }
        return stepcodes[config[this.props.transactionCompliance.stepcode]]
    }
    renderIcon = () => {
        const stepcodes = {

            withdraw_transaction_selected: <span onClick={this.hideTransactionModal} className="icon md x c-pointer" />,
            withdraw_transaction_summary: <span onClick={this.hideTransactionModal} className="icon md x c-pointer" />,
            withdraw_transaction_crypto_selected:<span onClick={this.hideTransactionCryptoModal} className="icon md x c-pointer" />,
            withdraw_transaction_crypto_summary:<span onClick={this.hideTransactionCryptoModal}
            className="icon md x c-pointer" 
            />,
            withdraw_transaction_success: <span onClick={this.hideTransactionModal} className="icon md x c-pointer" />,
            withdraw_transaction_crypto_success:<span onClick={this.hideTransactionCryptoModal} className="icon md x c-pointer" />,
        }
        return stepcodes[config[this.props.transactionCompliance.stepcode]]
    }
    render() {
        const { searchObj, gridUrl,message,operationType } = this.state;
        return (
        <>
          {message !== undefined && message !== null &&
          message !=="" && (
            <div style={{ width: '100%' }}>
              <Alert
                className="w-100 mb-16"
                type="warning"
                description={message}
                showIcon
              />
            </div>
          )}
         <>
        <div>
          <Form
            className="ant-advanced-search-form form form-bg search-bg pt-8"
            autoComplete="off"
            ref={this.formRef}
            initialValues
          >
            <Row style={{ alignItems: 'flex-end' }}>
            <Col sm={24} md={7} lg={5} xl={5} xxl={4} className="px-8">
                <Form.Item
                  name="operationType"
                  className="input-label mb-0"
                  label="Transaction Type"
                >
                  <Select
                    defaultValue="All"
                    className="cust-input w-100 bgwhite"
                    showSearch
                    onChange={(e) => this.handleChange(e,"OperationType")}
                    placeholder="Select Users"
                  >
                    {operationType.map((item,idx)=><Option key={idx} value={item.name}>
                    {item.name}
                   </Option>)}
                   

                   </Select>
                </Form.Item>
              </Col>
              <Col sm={24} md={7} lg={5} xl={5} xxl={5} className="px-8">
                <Form.Item
                  name="fromdate"
                  className="input-label mb-0"
                  label="From Date"
                  
                >
                   <DatePicker className="cust-input w-100 bgwhite custom-datepiker" 
                  placeholder="Select From Date"
                  format={Config?.dateFormates?.dateFormate}  onChange={(e) => this.handleChange(e, 'fromdate')}/>   
                </Form.Item>
              </Col> 
              <Col sm={24} md={7} lg={5} xl={5} xxl={5} className="px-8">
                <Form.Item
                  name="todate"
                  className="input-label mb-0"
                  label="To Date"
                >
                   <DatePicker className="cust-input w-100 bgwhite custom-datepiker"
                    placeholder="Select To Date"
                    format={Config?.dateFormates?.dateFormate}  onChange={(e) => this.handleChange(e, 'todate')}/>  
                </Form.Item>
              </Col> 
              <Col sm={24} md={3} lg={6} xl={6} xxl={5} className="pl-8 mb-8" >
              <div className=" d-flex align-center justify-content-between">
              <div className="d-flex align-center mt-16" style={{margin:"0 auto"}}>

            <label className="custom-checkbox  p-relative">
              <Input
              name="IsCheck"
                type="checkbox"
                className="c-pointer"
                checked={this.state.searchObj.IsCheck}
              onChange={(e) => this.handleOnClick(e, "IsCheck")}
              />
              <span></span>
            </label>
              <div className="fs-14 ml-4">Include Completed Transactions</div>

            </div>
             
              </div>
              </Col>
              <Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-left">
             
                <Button
                  type="primary"
                  className="primary-btn px-24 search-btn mt-20"
                  htmlType="submit"
                  onClick={this.handleSearch}
                >Search
                </Button>
               
              </Col>
            </Row>
          </Form>
        </div>
        <List
        showActionBar={true}
          url={gridUrl} 
          onActionClick={(key) => this.onActionClick(key)}
          additionalParams={searchObj}
          ref={this.gridRef}
          key={gridUrl}
          pKey={"Transactions Compliance"}
          showExcelExport={true}
          columns={this.gridColumns}
        />
      </>
            
        <Modal destroyOnClose={true}
        width={1000}
          // style={{ top: 16 }}
            title={[<div className="side-drawer-header">
                {this.renderTitle()}
                <div className="text-center fs-16">
                        </div>
                        <>
              <Tooltip title={"close"}>
              {this.renderIcon()}</Tooltip></>
                
            </div>]}
            placement="right"
            closable={false}
            visible={this.state.transactionModal}
            closeIcon={null}
            footer={null}
            className="side-drawer"
        >
            {this.renderContent()}
        </Modal>
        <Modal destroyOnClose={true}
        width={1000}
       // style={{ top: 16 }}
            title={[<div className="side-drawer-header">
                {this.renderTitle()}
                <div className="text-center fs-16">
                        </div>
                       <><Tooltip title={"close"}>
              {this.renderIcon()}</Tooltip></>
            </div>]}
            placement="right"
            closable={false}
            visible={this.state.transactionCryptoModal}
            closeIcon={null}
            footer={null}
            className="side-drawer"
        >
            {this.renderContent()}
        </Modal>
        </>
        );
        
    }
}
const connectStateToProps = ({ userConfig,transactionCompliance }) => {
    return { userConfig: userConfig.userProfileInfo,transactionCompliance }
  }
  
  const connectDispatchToProps = dispatch => {
    return {
      setAction: (val) => {
        dispatch(setCurrentAction(val))
      },
      dispatch
    }
  }
  
  export default connect(connectStateToProps,connectDispatchToProps)(TransactionComplience);