
import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  message,
  DatePicker,
  Alert, Modal, Tooltip
} from "antd";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import Loader from "../loader.component";
import { saveDepositeFiat, getDepositeFiat, getReferenceId, getProviderFee, getCountryStateLu, getCurrencyBankLu, saveCommissionDetails, getCopyDetails } from "./api";
import moment from "moment";
import NumberFormat from "react-number-format";
import apiCalls from "../../api/apiCalls";
import { setCurrentAction } from "../../reducers/actionsReducer";
import { validateContentRule } from "../../utils/custom.validator";
import { publishShowActions, mainSubject } from "../grid.component/subscribir";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import Info from "../info/Info";
import { redirectToCaseView } from "../../utils/caseRediractions";
import { Link } from "react-router-dom";
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import Notes from "../../utils/notes";
import Config from "../../config";
import DepositStateChange from './stateChange'
import CreateCase from "../../utils/createCase";
import { DYNAMIC_STATECHANGE_FIELDS } from './constants'
import {depositDetails} from '../../reducers/depositReducer'

const { TextArea } = Input;

const FiatDetails = (props) => {
  const [form] = Form.useForm();
  const useDivRef = React.useRef(null);
  const history = useHistory();
  const [depositObject, setDepositObject] = useState({});
  const [bank, setBank] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [type] = useState(props.match.params.type);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [bankRef, setBankRef] = useState(false);
  const [bankId, setBankId] = useState(null)
  const [grossAmount, setGrossAmount] = useState(null);
  const [refrenceData, setRefrenceData] = useState(null)
  const [netvalue, setNetvalue] = useState(null)
  const [country, setCountry] = useState([]);
  const [bankCurrency, setBankCurrency] = useState([]);
  const [isModalVissible, setIsVissible] = useState(false);
  const [docViewObj, setDocViewObj] = useState([]);
  const [isCommissionCheck, setIsCommissionCheck] = useState(false);
  const [reCalculateLoading, setIsReCalculateLoading] = useState(false);
  const [isCopyCheck, setIsCopyCheck] = useState(null);
  const [isReCalculateCheck, setIsReCalculateCheck] = useState(false);
  const [showStateChange, setShowStateChange] = useState(false)
  const [caseModal, setCaseModal] = useState(false)
  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Is required",
        whitespace: true
      }
    ]
  };
  useEffect(() => {
    if (props.match.params.type == "view") {
      publishShowActions(true);
      mainSubject.subscribe((data) =>
        onActionClick(data)

      );
    }
    else {
      publishShowActions(false)

    }
    if (props.match.params.type) {
      props.setAction(null);
    } else if (
      props.match.params.id !== "00000000-0000-0000-0000-000000000000"
    ) {
      props.setAction("edit");
    } else {
      props.setAction("add");
    }
    if (props.match.params.id !== "00000000-0000-0000-0000-000000000000") {
      loadData();
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getCountryLU();
    if (bankCurrency && !isCopyCheck) {
      handleCurrencyChange(depositObject?.bankCurrency, false);
    }

  }, [bankCurrency])// eslint-disable-line react-hooks/exhaustive-deps 


  const onActionClick = (key) => {
    const action = {

      "State Change": statusChange,
      "Create Case": createCase,

    };
    action[key]();
  };

  const statusChange = () => {
    let _data = form.getFieldsValue();
    setErrorMsg(null)
    if (depositObject?.status == "Draft" || props.location.state?.details?.status == "Draft") {
      setErrorMsg("The record is in draft state, so it cannot be changed.")
      setShowStateChange(false)
      return;
    }
    else if (depositObject?.lockUnlock == "Locked" || props.location.state?.details?.lockUnlock == "Locked") {
      setErrorMsg("This transaction was locked, Please unlock before state change")
      setShowStateChange(false)
      return;

    }
    else {
      setErrorMsg(null)
      setShowStateChange(true)
    }
  }

  const createCase = () => {
    setErrorMsg(null)
    setCaseModal(true)
  }



  const handleCurrency = async (bankData, customerID, isCopy) => {
    const response = await getCurrencyBankLu(bankData, customerID);
    if (response.ok) {
      setIsCopyCheck(isCopy);
      setBankCurrency(response.data);
    } else {
      setErrorMsg(apiCalls.isErrorDispaly(response))
    }
  }
  const handleIsCommissionCheck = () => {
    setIsCommissionCheck(!isCommissionCheck)
  }
  const loadData = async () => {
    setLoading(true);
    let response;
    if (type == "copy") {
      response = await getCopyDetails(props.match.params.id);
    } else {
      response = await getDepositeFiat(props.match.params.id, props?.match?.params?.isPendingDeposit == "isCommissionCheck" ? null : props?.match?.params?.isPendingDeposit);
    }
    if (response.ok) {
      if (props.match.params.type == "view") {
        publishShowActions(true);
      }
      else {
        publishShowActions(false)
      }
      let obj = response.data;
      const val = response.data?.id;
      props.dispatch(
        setBreadcrumb({
          key: "/deposit/" + val + `/fiatDetails/view`,
          val: response.data?.transactionId + " / " + response.data?.status,
        })
      );

      if (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && response.data) {
        obj["date-picker"] = response.data?.date && moment(new Date(response.data?.date), "DD/MM/YYYY");
        setCustomerId(response.data?.customerId);
        setBankId(response.data?.bankId);
      }
      setDepositObject(obj);
      props.dispatch(depositDetails(obj))
      setDocViewObj(obj?.adminDocuments)
      handleCurrency(
        (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")
          ? response.data?.bankName
          : null, response.data?.customerId
      );
      handleCurrencyChange(response.data?.bankCurrency, false);
      form.setFieldsValue({ ...response.data });
      setLoading(false);
      setErrorMsg(null);
    } else {
      setLoading(false);
      setErrorMsg(
        apiCalls.isErrorDispaly(response)
      );
    }
  };
  const getCountryLU = async () => {
    let res = await getCountryStateLu();
    if (res.ok) {
      setCountry(res.data);
    }
  }
  const backToDeposit = () => {
    history.push({
      pathname: "/deposit"
    });
  };

  const redirectCaseView = (caseData) => {
    redirectToCaseView(caseData, props)
  };
  const handleUserLabel = async (e) => {
    setBankId(null)
    if (e.target.value && !/^[A-Za-z0-9]+$/.test(e.target.value)) {
      setBankCurrency([])
      setBank([])
      form.setFieldsValue({ name: null, userName: null, customerEmail: null, currency: null, bankId: null, bankAccountNumber: null, grossAmount: null })
      return setErrorMsg("Please enter correct Customer/Contract ID to proceed");
    }
    if (
      typeof e.target.value !== "undefined" &&
      e.target.value !== null &&
      e.target.value !== ""
    ) {
      let refId = e.target.value;
      let cleanStr = refId.trim()

      let response = await getReferenceId(cleanStr);

      if (response.ok) {
        if (
          typeof response.data !== "undefined" &&
          response.data !== null &&
          response.data !== ""
        ) {
          setBank(null);
          let _data = form.getFieldsValue();
          _data.name = response.data.name;
          _data.customerEmail = response.data.email;
          _data.userName = response.data.userName;
          _data.refrenceId = cleanStr;
          setRefrenceData(response.data)
          setCustomerId(response.data.id);
          form.setFieldsValue(_data);
          amountChange(bankId, response.data.id);
          if (response.data.id === "00000000-0000-0000-0000-000000000000") {
            setRefrenceData(null)

          }
        }
        form.setFieldsValue({ currency: null, bankId: null, bankAccountNumber: null });
        handleCurrency((props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")
          ? response.data?.bankName
          : null, response.data?.customerId || response.data.id, "isCopy");
        setErrorMsg(null);
      } else {
        setBankCurrency([]);
        setBank([])
        form.setFieldsValue({ name: null, userName: null, customerEmail: null, currency: null, bankId: null, bankAccountNumber: null })
        setErrorMsg(apiCalls.isErrorDispaly(response))
      }
    }

    else {
      setRefrenceData(null)
      setBankCurrency([])
      setBank([])
      form.setFieldsValue({ name: null, userName: null, customerEmail: null, currency: null, bankId: null, bankAccountNumber: null, })
      setCustomerId("");
      amountChange(grossAmount ? grossAmount : depositObject?.grossAmount, bankId, null, true);
    }
  };
  const handleCurrencyChange = (name, cleanBank) => {
    let currency = bankCurrency;
    let index = currency.findIndex(function (o) {
      return o.name === name;
    });
    const bankLu = currency != "" ? currency[index]?.bankDetails : [];
    setBank(bankLu ? bankLu : []);
    if (cleanBank) {
      let _data = form.getFieldsValue();
      _data.bankId = null;
      _data.referenceNo = null;
      _data.bankAccountNumber = '';
      setBankRef(false);
      form.setFieldsValue(_data);
    }
    if (bankLu?.length === 1) {
      setBank(bankLu);
      let _data = form.getFieldsValue();

      if (!cleanBank) {
        _data.bankId = depositObject.bankId;

        _data.referenceNo = depositObject?.referenceNo;
        form.setFieldsValue(_data);
        if (depositObject?.referenceNo) setBankRef(true);

      } else {
        setBankRef(false);
      }
      form.setFieldsValue(_data);
    } else if (bankLu?.length > 1) {

      if (!cleanBank && depositObject?.referenceNo) {

        let _FliteredData = bankLu.filter(function (o) {
          return o.id === depositObject?.bankId;
        });
        if (_FliteredData[0]?.referenceNo) setBankRef(true);

      } else {
        setBankRef(false);
      }
    } else {
      setBank(bankLu ? bankLu : []);
      setBankRef(false);
    }
    amountChange();
  };
  const handleImpNote = (e) => {
    if (e.currentTarget.checked) {

      setDepositObject(prevState => ({
        ...prevState,
        isCheckBox: true
      }));
    } else {

      setDepositObject(prevState => ({
        ...prevState,
        isCheckBox: false
      }));
    }

  };

  const handleBankChange = async (name, cleanBank) => {
    let _FliteredData = bank.filter(function (o) {
      return o.id === name;
    });
    if (cleanBank) {
      let _data = form.getFieldsValue();
      _data.bankAccountNumber = null;
      setBankRef(false);
      form.setFieldsValue(_data);
    }
    if (_FliteredData?.length === 1) {
      let _data = form.getFieldsValue();
      _data.bankAccountNumber = _FliteredData[0].accountNumber;
      form.setFieldsValue(_data);
      if (_FliteredData[0]?.bankAccountNumber)
        setBankRef(true);
    } else {
      setBankRef(false);
    }
    setBankId(name)
    amountChange(grossAmount ? grossAmount : depositObject?.grossAmount, name);
  };
  const amountChange = async (e, _bankId, _customerId, isRefRemove) => {
    let grossValue;
    if (e && e.target && e.target.value !== undefined) {
      grossValue = e.target.value;
    } else {
      grossValue = e;
    }
    if (e?.target?.value) {
      setGrossAmount(e?.target?.value);
    }
    let selectbankId = _bankId ? _bankId : bankId;
    let selectedcustomerId;
    if (_customerId) {
      selectedcustomerId = _customerId;
    } else if (isRefRemove) {
      selectedcustomerId = null;
    } else {
      selectedcustomerId = customerId;
    }
    if (selectedcustomerId && selectbankId && grossValue) {
      let response = await getProviderFee(selectedcustomerId, selectbankId, grossValue)
      if (response.ok) {
        let obj = response.data
        setNetvalue(response?.data?.netAmount);
        let _data = form.getFieldsValue();
        _data.comissionvalue = obj?.commissionFee;
        _data.pcomissionvalue = obj?.providerFee;
        _data.sweepSourceComissionValue = obj?.sweepSourceCommissionFee;
        _data.sweepDestinationcomissionValue = obj?.sweepDestinationCommissionFee;
        _data.comissionvalueInBase = obj?.commissionInBase;
        _data.pcomissionvalueInBase = obj?.providerFeeIBase;
        _data.sweepSourceValueInBase = obj?.sweepSourceCommissionInBase;
        _data.sweepDestinationvalueInBase = obj?.sweepDestinationCommissionInBase;
        _data.comissiontype = obj?.commissionType;
        _data.pcomissiontype = obj?.providerCommissionType;
        _data.sweepSourceType = obj?.sweepSourceCommissionType;
        _data.sweepDestinationType = obj?.sweepDestinationCommissionType;
        _data.sbCredit = obj?.sbCredit;
        _data.sbCreditUsed = obj?.sbCreditUsed;
        _data.netAmount = obj?.netAmount;
        _data.tierDiscount = obj?.tierDiscount
        _data.originalCommission = obj?.originalCommission;
        _data.profit = obj?.profit;
        _data.profitInBase = obj?.profitInBase;

        form.setFieldsValue(_data);
      }
    }
    else {
      let _data = form.getFieldsValue();
      _data.comissionvalue = depositObject?.comissionvalue ? depositObject?.comissionvalue : 0;
      _data.pcomissionvalue = depositObject?.pcomissionvalue ? depositObject?.pcomissionvalue : 0;
      _data.sweepSourceComissionValue = depositObject?.sweepSourceComissionValue ? depositObject?.sweepSourceComissionValue : 0;
      _data.sweepDestinationcomissionValue = depositObject?.sweepDestinationcomissionValue ? depositObject?.sweepDestinationcomissionValue : 0;
      _data.originalCommission = depositObject?.originalCommission ? depositObject?.originalCommission : 0;
      _data.comissionvalueInBase = depositObject?.comissionvalueInBase ? depositObject?.comissionvalueInBase : 0;
      _data.pcomissionvalueInBase = depositObject?.pcomissionvalueInBase ? depositObject?.pcomissionvalueInBase : 0;
      _data.sweepSourceValueInBase = depositObject?.sweepSourceValueInBase ? depositObject?.sweepSourceValueInBase : 0;
      _data.sweepDestinationvalueInBase = depositObject?.sweepDestinationvalueInBase ? depositObject?.sweepDestinationvalueInBase : 0;
      _data.comissiontype = depositObject?.comissiontype ? depositObject?.comissiontype : "";
      _data.pcomissiontype = depositObject?.pcomissiontype ? depositObject?.pcomissiontype : "";
      _data.sweepSourceType = depositObject?.sweepSourceType ? depositObject?.sweepSourceType : "";
      _data.sweepDestinationType = depositObject?.sweepDestinationType ? depositObject?.sweepDestinationType : "";
      _data.netAmount = depositObject?.netAmount ? depositObject?.netAmount : 0;
      _data.sbCredit = depositObject?.sbCredit ? depositObject?.sbCredit : 0;
      _data.sbCreditUsed = depositObject?.sbCreditUsed ? depositObject?.sbCreditUsed : 0;
      _data.tierDiscount = depositObject?.tierDiscount ? depositObject?.tierDiscount : 0;
      _data.profit = depositObject?.profit ? depositObject?.profit : 0;
      _data.profitInBase = depositObject?.profitInBase ? depositObject?.profitInBase : 0;
      form.setFieldsValue(_data);
    }
  }
  const saveDepositeFiatInfo = async (saveType) => {
    const fieldsValue = form.getFieldsValue();
    setErrorMsg(null);
    let objval = Object.assign({}, fieldsValue);
    objval.userName = apiCalls.encryptValue(
      objval.userName,
      props.userConfig?.sk
    );
    objval.info = JSON.stringify(props.trackAuditLogData);
    if (
      parseFloat(
        typeof objval.amountDeposit == "string"
          ? objval.amountDeposit.replace(/,/g, "")
          : objval.amountDeposit
      ) <= 0
    ) {
      setErrorMsg("Amount must be greater than zero.")
      useDivRef.current.scrollIntoView();
      setIsLoading(false);
      return;
    }
    if (objval.amountDeposit === ".") {
      setErrorMsg('Amount must be greater than zero.')
      useDivRef.current.scrollIntoView();
      setIsLoading(false);
      return;
    }
    setErrorMsg(null);
    objval.Id = type == "copy" ? "00000000-0000-0000-0000-000000000000" : props.match.params.id;
    objval.customerId = customerId;
    objval.bankAccountNumber = fieldsValue.bankAccountNumber;
    fieldsValue.grossAmount = typeof (fieldsValue.grossAmount) == "string" ? fieldsValue.grossAmount?.replace(/,/g, '') : fieldsValue.grossAmount;
    objval.grossAmount = parseFloat(fieldsValue.grossAmount);
    objval.pcomissionvalue = parseFloat(fieldsValue.pcomissionvalue);
    objval.sweepDestinationcomissionValue = parseFloat(fieldsValue.sweepDestinationcomissionValue);
    objval.suisseBaseCreditUsed = depositObject?.suisseBaseCreditUsed ? depositObject?.suisseBaseCreditUsed : "";
    objval.suisseBaseCreditBalance = depositObject?.sbCredit ? depositObject?.sbCredit : "";
    objval.netAmount = parseFloat(fieldsValue.netAmount);
    objval.originalCommission = parseFloat(fieldsValue.originalCommission);
    objval.referenceNo = depositObject?.referenceNo;
    objval.statusRemarks = fieldsValue.statusRemarks;
    objval.providerTxnId = fieldsValue.providerTxnId;
    objval.bankTxnId = fieldsValue.bankTxnId
    objval.createdby = (props.match.params.id == "00000000-0000-0000-0000-000000000000" || type == "copy") && props.userConfig.userName;
    objval.date = objval["date-picker"];
    if (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy") {
      objval.date = moment(objval.date).format("YYYY-MM-DD HH:mm:ss");
      objval.modifiedby = props.userConfig.userName;
    }
    else {
      objval.date = new Date(moment(objval.date).format("YYYY-MM-DD HH:mm:ss"));
    }
    objval.createdDate = new Date();
    if (saveType == "re_calculate") {
      setIsReCalculateLoading(true);

      let obj = Object.assign({}, fieldsValue);
      obj.Id = props.match.params.id;
      obj.customerId = customerId;
      obj.ModifiedBy = props.userConfig.userName;
      let res = await saveCommissionDetails(obj);
      if (res.ok) {
        setIsReCalculateCheck(true);
        form.setFieldsValue(res.data);
        setIsReCalculateLoading(false);
        message.success({
          content: "Commissions successfully recalculated.",
          className: "custom-msg",
          duration: 4
        })
        setIsLoading(false);
      } else {
        setIsReCalculateLoading(false);
        useDivRef.current.scrollIntoView();
        setErrorMsg(apiCalls.isErrorDispaly(res));
        setIsLoading(false);
      }
    } else {
      if (isCommissionCheck && !isReCalculateCheck) {
        setErrorMsg(`Please click on the 'Re-Calculate' button before saving.`)
        useDivRef.current.scrollIntoView();
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      if ((netvalue > -0) && (netvalue !== 0 || netvalue > 0) || (depositObject?.netAmount)) {
        let response = await saveDepositeFiat(objval, type !== "copy" ? props.match.params.id : "00000000-0000-0000-0000-000000000000");
        if (response.ok) {
          message.destroy();
          message.success({
            content: type == 'view' ? "Commissions updated successfully" : "Fiat details saved successfully",
            className: "custom-msg",
            duration: 4
          }
          );
          history.push("/deposit");
          setErrorMsg(null)
        } else {
          useDivRef.current.scrollIntoView();
          setErrorMsg(apiCalls.isErrorDispaly(response))
          setIsLoading(false);
        }
      }
      else {
        setIsLoading(false);
        useDivRef.current.scrollIntoView();
        window.scrollTo(0, 0);
        setErrorMsg("We can not process this request, Since commission is more than or equal to requested amount")
      }
    }
  };
  const handleAmount = (e, key) => {
    let _data = form.getFieldsValue();
    _data.comissionvalue = _data?.commissionFee;
    _data[key] = e.target.value;
    _data.sweepSourceComissionValue = _data?.sweepSourceCommissionFee;
    _data.sweepDestinationcomissionValue = _data?.sweepDestinationCommissionFee;
    _data.comissionvalueInBase = _data?.commissionInBase;
    _data.pcomissionvalueInBase = _data?.providerFeeIBase;
    _data.sweepSourceValueInBase = _data?.sweepSourceCommissionInBase;
    _data.sweepDestinationvalueInBase = _data?.sweepDestinationCommissionInBase;
    _data.comissiontype = _data?.commissionType;
    _data.pcomissiontype = _data?.providerCommissionType;
    _data.sweepSourceType = _data?.sweepSourceCommissionType;
    _data.sweepDestinationType = _data?.sweepDestinationCommissionType;

  }

  const redirectCustomerView = () => {
    const items = depositObject;
    const val = items.customerId;
    props.dispatch(
      setBreadcrumb({ key: "/customers/details/" + val, val: depositObject?.name })
    );
    localStorage.setItem("MyURL", "/customers/details/" + val)
    window.open("/customers/details/" + val);
  };
  const options = bankCurrency?.map((item, idx) => (
    <Select.Option key={idx} value={item.name}>
      {item.name}
    </Select.Option>
  ));
  const bankOptions = bank?.map((item) => (
    <Select.Option value={item.id}>{item.name}</Select.Option>
  ));
  const countryOptions = country?.map((item, indx) => (
    <Select.Option key={indx} value={item.name}>
      {item.name}
    </Select.Option>
  ));
  const validateNumber = (_, value) => {
    if (value === ".") {
      return Promise.reject(`${DYNAMIC_STATECHANGE_FIELDS.PLEASE_ENTER_VALID_CONTENT}`);
    }
    return Promise.resolve();
  }
  const docOpen = () => {
    setIsVissible(true);
  }
  const CloseModal = () => {
    setIsVissible(false);
  }
  const updateAttachemnts = (attachemnts) => {
    setDocViewObj(attachemnts)
  }
  const isCommissionEdit = () => {
    let editCheck = props.permissions?.[(props?.permissions?.currentScreenTabId) || (props?.permissions?.currentScreenId)]?.filter((item) => (item.permissionName === "Edit Commissions")).map((item) => (item.values))
    return editCheck?.[0]
  }
  const renderKpiLabelContent = () => {
    const { id } = props.match.params;
    const isIdValid = id !== "00000000-0000-0000-0000-000000000000";
    const isBusiness = depositObject?.isBusiness;

    if (isIdValid) {
      return (
        <div>
          {isBusiness ? <><b>Business Name</b>/Personal Name</> : <>Business Name/<b>Personal Name</b></>}
        </div>
      );
    } else {
      return <div>Business Name/Personal Name</div>;
    }
  };
  const renderLabelContent = () => {
    const { id } = props.match.params;
    const isIdValid = id !== "00000000-0000-0000-0000-000000000000";
    const isBusiness = depositObject?.isBusiness || refrenceData?.isBusiness;
    const isIdValidOrRefData = isIdValid || refrenceData;

    let contentLable;
    if (isIdValidOrRefData) {
      contentLable = (
        <div>
          {isBusiness ? (
            <><b>Business Name</b>/Personal Name</>
          ) : (
            <>Business Name/<b>Personal Name</b></>
          )}
        </div>
      );
    } else {
      contentLable = <div>Business Name/Personal Name</div>;
    }

    return <div>{contentLable}</div>;

  };

  const handleSuccess = () => {
    setShowStateChange(false)
    loadData()
  }
  const hideCaseModal = () => {
    setCaseModal(false)
  };

  const gotoProfile = () => {
    const items = depositObject;
    const val = items.customerId;
    props.dispatch(
      setBreadcrumb({
        key: `${DYNAMIC_STATECHANGE_FIELDS.CUSTOMERS_DETAILS}` + val,
        val: items.customerName
      })
    );
    localStorage.setItem("MyURL", `${DYNAMIC_STATECHANGE_FIELDS.CUSTOMERS_DETAILS}${val}/${null}/${null}/10`)
    window.open(`${DYNAMIC_STATECHANGE_FIELDS.CUSTOMERS_DETAILS}${val}/${null}/${null}/10`);
  }

  return (
    <>
      <div ref={useDivRef}></div>
      {loading && <Loader />}
      {errorMsg !== undefined && errorMsg !== null && (
        <Alert className="w-100 mb-16" type="error" showIcon description={errorMsg} />
      )}
      {props.match.params.type == "view" &&
        <div className="text-right mb-8"><Button className="primary-btn text-right" style={{ margin: "8px 8px" }}
          onClick={() => gotoProfile()}
        >{depositObject?.isBusiness == true ? "KYB" : "KYC"}</Button></div>}

      <Form
        time_related_controls="true"
        initialValues={depositObject}
        form={form}
        name="time_related_controls"
        className="ant-advanced-search-form"
        onFinish={saveDepositeFiatInfo}
        autoComplete="off"
      >
        <div><h4 className="fs-20 fw-400 text-textDark ml-12">Transaction Details</h4></div>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="date-picker"
              className="input-label"
              label="Date"
              {...config}
              rules={[
                {
                  required: true,
                  message: "Is required"
                }
              ]}
            >
              <DatePicker
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
                format={Config?.dateFormates?.dateFormate}
                className="cust-input"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="refrenceId"
              label="Customer/Contract ID"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                },
                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                disabled={props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy"}
                placeholder="Customer/Contract ID"
                className="cust-input"
                maxLength={20}
                onChange={(e) => handleUserLabel(e)}
              />
            </Form.Item>
          </Col>
          {type === "view" ? (
            <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
              <Form.Item className="mt-24 ml-8">
                <label className="kpi-label">{renderKpiLabelContent()}</label>
                <span
                  className="fw-600 fs-14 c-pointer">

                  <Link
                    onClick={() => redirectCustomerView()}>

                    {depositObject?.name}</Link>
                </span>
              </Form.Item>
            </Col>)
            :
            (<Col xs={24} sm={24} md={12} lg={8} xxl={6}>
              <Form.Item
                name="name"
                label={renderLabelContent()}
                className="input-label"
                rules={[
                  {
                    required: true,
                    message: "Is required",
                    whitespace: true
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  disabled={true}
                  placeholder="Business Name/Personal Name"
                  className="cust-input"
                  maxLength={50}
                />
              </Form.Item>
            </Col>)
          }

          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="userName"
              label="User Name"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                },
                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                disabled={true}
                placeholder="User Name"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="customerEmail"

              label="Email"

              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                },
                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                disabled={true}
                placeholder="Email"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="currency"
              label="Currency"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                }
              ]}
            >
              <Select
                disabled={(props.match.params.id !== "00000000-0000-0000-0000-000000000000" || type == "view") && type !== "copy"}
                className={`cust-input w-80 
              ${(props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy") && "cust-input-disabled"}`}
                onChange={(e) => {
                  handleCurrencyChange(e, true);
                }}
                placeholder="Select Currency"
              >
                {options}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="bankId"
              label="Bank Name"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                }
              ]}
            >
              <Select
                disabled={(props.match.params.id !== "00000000-0000-0000-0000-000000000000" || type == "view") && type !== "copy" ? true : false}
                className={`cust-input w-80 
                ${(props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy") && "cust-input-disabled"}`}
                onChange={(e) => {
                  handleBankChange(e, true);
                }}
                placeholder="Select Bank"
              >
                {bankOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="bankAccountNumber"
              label="Bank Account Number "
              className="input-label"
              required
              rules={[
                {
                  required: true,
                  message: "Is required"
                }
              ]}
            >
              <Input
                placeholder="Bank Account Number"
                disabled={true}
                decimalScale={2}
                className="cust-input"
                maxLength={30}
              />
            </Form.Item>
          </Col>
          {bankRef && (
            <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
              <Form.Item
                name="referenceNo"
                label="Bank Reference No"
                className="input-label"
                rules={[
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder="Bank Reference No"
                  disabled={true}
                  className="cust-input"
                  maxLength={30}
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="senderName"
              label="Sender's Name"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                },
                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy"}
                placeholder="Sender's Name"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="senderAccount"
              label="Sender's Bank Account Number/IBAN"
              className="input-label"
              required
              rules={[
                {
                  required: true,
                  message: "Is required",
                },
                {
                  validator(_, value) {
                    if (value && !(/^[A-Za-z0-9]+$/.test(value))) {
                      return Promise.reject("Invalid Sender's Bank Account Number/IBAN");
                    }
                    else {
                      return validateContentRule(_, value)
                    }
                  },
                },

              ]}
            >
              <Input
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
                placeholder="Sender's Bank Account Number/IBAN"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="senderBankName"
              label="Sender's Bank Name"
              className="input-label"
              rules={[
                {
                  required: true,
                  message: "Is required",
                  whitespace: true
                },
                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
                placeholder="Sender's Bank Name"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="senderBankAddress"
              label="Sender's Bank Address"
              className="input-label"
              rules={[
                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
                placeholder="Sender's Bank Address"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="senderCountry"
              label="Sender's Country"
              className="input-label"
              rules={[
                {
                  whitespace: true
                }
              ]}
            >
              <Select
                showSearch
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
                placeholder="Select Country"
                className={`cust-input w-80 
                ${depositObject?.isDigitaltransfer !== null && props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy" && "cust-input-disabled"}`}
                dropdownClassName="select-drpdwn"
              >
                {countryOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="providerTxnId"
              label="Kraken/Binance/Txn ID"
              className="input-label"
              rules={[

                {
                  validator: validateContentRule
                }
              ]}

            >
              <Input
                placeholder="Kraken/Binance/Txn ID"
                className="cust-input"
                maxLength={50}
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="bankTxnId"
              label="Bank Txn ID"
              className="input-label"
              rules={[

                {
                  validator: validateContentRule
                }
              ]}
            >
              <Input
                placeholder="Bank Txn ID"
                className="cust-input"
                maxLength={50}
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null && (props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="grossAmount"
              label="Gross Amount"
              className="input-label"
              rules={[
                { required: true, message: "Is required" }
                , {
                  validator: validateNumber
                }
              ]}
            >
              <NumberFormat
                decimalScale={type == "view" ? 8 : 2}
                className="cust-input"
                disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" || props.match.params.id !== "00000000-0000-0000-0000-000000000000" && type !== "copy"}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Gross Amount"
                allowNegative={false}
                maxLength={13}
                onBlur={(e) => amountChange(e)}
              />
            </Form.Item>
          </Col>
          {type == "view" && <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="transactionrefId"
              label="Reference ID"
              className="input-label"

            >
              <Input
                disabled={true}
                placeholder="Reference ID"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>}
          {(type == "view" && depositObject?.senderBankName == "PIX") && <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="documentId"
              label="Document ID"
              className="input-label"

            >
              <Input
                disabled={true}
                placeholder="Document ID"
                className="cust-input"
                maxLength={50}
              />
            </Form.Item>
          </Col>}
          {type === "view" && (depositObject?.status === "Rejected" && (
              <Col xs={24} sm={24} md={12} lg={16} xxl={12}>
                <Form.Item
                  name="rejectReason"
                  label="Reason For Rejection"
                  className="input-label"
                >
                  <TextArea
                    disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" && type !== "copy"}
                    placeholder="Reason For Rejection"
                    rows={2}
                    maxLength={100}
                    showCount
                  />
                </Form.Item>
              </Col>
            ))}
          {(depositObject?.status === "Approved" || depositObject?.status === "Rejected") &&
            <Col xs={24} sm={24} md={12} lg={16} xxl={12}>
              <Form.Item
                name="statusRemarks"
                label="Remarks"
                className="input-label"
                rules={[
                  {
                    whitespace: true,
                    message: "Is required"
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <TextArea
                  placeholder="Remarks"
                  showCount
                  maxLength={100}
                  rows={2}
                  disabled={depositObject?.status === "Approved" || depositObject?.status === "Rejected" || type == "view" && type !== "copy"}
                />
              </Form.Item>
            </Col>}
          {type === "view" && (depositObject?.caseIds !== null && (
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
              <Form.Item className="mt-24 ml-8">
                <label className="kpi-label d-block">Case Number</label>
                <span
                  className="fw-600 fs-14">
                  {depositObject?.caseIds?.map(item => <Link className="c-pointer" onClick={() => redirectCaseView(item)}>{item.caseNumber}<br /></Link>)}
                </span>
              </Form.Item>
            </Col>
          ))}
        </Row>
        <div><h4 className="fs-20 fw-400 text-textDark ml-12 mt-20">Commissions</h4></div>
        {(type === "view" && isCommissionEdit() && depositObject?.isComissionAllowed && depositObject?.status !== "Rejected" && depositObject?.depositType !== "Automatic" && depositObject?.isDigitaltransfer == null) && <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <div className="d-flex align-center ml-12 py-16">
              <label className="mr-12">Edit Commissions</label>
              <label className="text-center custom-checkbox ml-12">
                <input name="check" type="checkbox"
                  disabled={(!isCommissionEdit() || !depositObject?.isComissionAllowed || depositObject?.status === "Rejected")}
                  onClick={handleIsCommissionCheck}
                  //checked={isCommissionCheck}
                  checked={props?.match?.params?.isPendingDeposit == "isCommissionCheck" || isCommissionCheck}

                />
                <span></span>{" "}
              </label>
              <Button
                type="primary"
                className="primary-btn ml-16"
                //disabled={!isCommissionCheck}
                disabled={!isCommissionCheck && props?.match?.params?.isPendingDeposit !== "isCommissionCheck"}

                loading={reCalculateLoading}
                htmlType="button"
                onClick={() => saveDepositeFiatInfo("re_calculate")}
              >
                Re-Calculate
              </Button>
            </div>

          </Col>
        </Row>}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="comissionvalue"
              label="Commission"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Commission"
                allowNegative={false}
                maxLength={13}
                value={depositObject?.commissionValue}
                suffix={type == "view" && `${" "}${depositObject.cTypeFlat}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="comissionvalueInBase"
              label="Commission in Base"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Commission in Base"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="originalCommission"
              label="Fees"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Fees"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="tierDiscount"
              label="Tier Discount"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Tier Discount"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="sbCreditUsed"
              label="SuisseBase Credit Used"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="SuisseBase Credit Used"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="sbCredit"
              label="SuisseBase Credit Balance"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="SuisseBase Credit Balance"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6} className="p-relative">

            <Form.Item
              name="pcomissionvalue"
              label="Provider Commission"
              className="input-label"
              required
              rules={[
                { required: true, message: "Is required" },
                {
                  validator: validateNumber
                }]}>
              <NumberFormat
                decimalScale={(type == "view" && props.match.params.type == 'view') && (props?.match?.params?.isPendingDeposit === "isCommissionCheck" || isCommissionCheck) ? 2 : 8}
                className="cust-input"
                disabled={depositObject.status === "Rejected" || (!isCommissionCheck && props?.match?.params?.isPendingDeposit !== "isCommissionCheck")}

                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Provider Commission"
                allowNegative={false}
                maxLength={13}
                onChange={(e) => { handleAmount(e, "pcomissionvalue") }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="pcomissionvalueInBase"
              label="Provider Commission in Base"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Provider Commission in Base"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6} className="p-relative">

            <Form.Item style={{ flex: 1 }}
              name="sweepSourceComissionValue"
              label="Sweep Source Commission"
              className="input-label p-relative"
              required
              rules={[
                { required: true, message: "Is required" },
                {
                  validator: validateNumber
                }]}

            >
              <NumberFormat
                decimalScale={((type == "view" && props.match.params.type == 'view') && (props?.match?.params?.isPendingDeposit === "isCommissionCheck" || isCommissionCheck) ? 2 : 8)}
                className="cust-input"
                //disabled={depositObject.status==="Rejected" || !isCommissionCheck}
                disabled={depositObject.status === "Rejected" || (!isCommissionCheck && props?.match?.params?.isPendingDeposit !== "isCommissionCheck")}

                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Sweep Source Commission"
                allowNegative={false}
                maxLength={13}
                onChange={(e) => { handleAmount(e, "sweepSourceComissionValue") }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="sweepSourceValueInBase"
              label="Sweep Source Commission in Base"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Sweep Source Commission in Base"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6} className="p-relative">

            <Form.Item
              style={{ flex: 1 }}
              name="sweepDestinationcomissionValue"
              label="Sweep Destination Commission"
              className="input-label p-relative"
              required
              rules={[
                { required: true, message: "Is required" },
                {
                  validator: validateNumber
                }]}
            >
              <NumberFormat
                decimalScale={((type == "view" && props.match.params.type == 'view') && (props?.match?.params?.isPendingDeposit === "isCommissionCheck" || isCommissionCheck) ? 2 : 8)}
                className="cust-input"
                // disabled={depositObject.status==="Rejected" || !isCommissionCheck}
                disabled={depositObject.status === "Rejected" || (!isCommissionCheck && props?.match?.params?.isPendingDeposit !== "isCommissionCheck")}

                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Sweep Destination Commission"
                allowNegative={false}
                maxLength={13}
                onChange={(e) => { handleAmount(e, "sweepDestinationcomissionValue") }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="sweepDestinationvalueInBase"
              label="Sweep Destination Commission in Base"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Sweep Destination Commission in Base"
                allowNegative={false}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="profit"
              label="Profit"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Profit"
                allowNegative={true}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="profitInBase"
              label="Profit In Base"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Profit In Base"
                allowNegative={true}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
            <Form.Item
              name="netAmount"
              label="Net Amount"
              className="input-label"
            >
              <NumberFormat
                decimalScale={8}
                className="cust-input"
                disabled={true}
                customInput={Input}
                thousandSeparator={true}
                prefix={""}
                placeholder="Net Amount"
                allowNegative={true}
                maxLength={13}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="text-right mt-24">
          {(type == "view" && depositObject?.status !== "Rejected" && isCommissionEdit() && depositObject?.isComissionAllowed && depositObject?.depositType !== "Automatic" && depositObject?.isDigitaltransfer == null) && (
            <Button
              type="primary"
              className="primary-btn"
              htmlType="submit"
              //disabled={!isCommissionCheck || !isReCalculateCheck}
              disabled={!isReCalculateCheck}
              loading={isLoading}
            >
              Save
            </Button>
          )}
        </div>
        {props.match.params.type == 'view' && <><b> Admin Upload</b> <PlusCircleOutlined onClick={() => docOpen()} />

          <Row gutter={24} className="mb-24 pb-24 border-bottom">
            <>

              <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                {<DocumentsUploade
                  pop_up_cancel={() => CloseModal()}
                  docID={depositObject?.id}
                  previewModal={isModalVissible}
                  attachmentUpdate={(attachemnts) =>
                    updateAttachemnts(attachemnts)
                  }
                  docViewObj={docViewObj}
                  screenTitle={"Receive Fiat"}
                  ModifiedBy={props.userConfig.userName}
                />}
              </Col>
            </>
          </Row></>}

        {type === "view" && <Notes screenId={props.match.params.id} screenName={"ReceiveFiat"} createdNote={depositObject?.notes} />}

        <Info  Id={depositObject?.id} Status={depositObject?.status} Createdby={depositObject?.createdby} CreatedDate={depositObject?.createdDate} ModifiedDate={depositObject?.modifiedDate} Modifiedby={depositObject?.modifiedby} isShowInfo={false} screenName={"Receive"} loadData={loadData} />

        <div className="text-right mt-24">
          {(!(type == "view" || depositObject?.status === "Approved" || depositObject?.status === "Rejected" || depositObject?.senderBankName == "PIX" || depositObject?.isDigitaltransfer !== null) || props.match.params.id == "00000000-0000-0000-0000-000000000000") && (<>
            <Button
              type="primary"
              className="primary-btn"
              htmlType="submit"
              disabled={type == 'view' && !isCommissionCheck}
              loading={isLoading}
            >
              Save
            </Button></>)}
          {type !== "view" && <Button
            type="primary"
            className="primary-btn cancel-btn"
            style={{ margin: "0 8px" }}
            onClick={backToDeposit}
          >
            Cancel
          </Button>}
        </div>
      </Form>
      <DepositStateChange visible={showStateChange} deposit={true} selectedTab={1}
        selectedObj={depositObject} status={depositObject.status}
        onClose={() => {
          setShowStateChange(false)
          loadData()
        }}
        onSuccess={() => {
          handleSuccess()
        }}
        onNote={(v) => handleImpNote(v)}

      />
      <Modal
        title={`${DYNAMIC_STATECHANGE_FIELDS.CREATE_CASE}`}
        visible={caseModal}
        width={1000}
        style={{ top: 16 }}
        closeIcon={
          <Tooltip title="Close">
            <span className="icon md x c-pointer" onClick={hideCaseModal} />
          </Tooltip>
        }
        footer={null}
        destroyOnClose>
        <CreateCase
          FromScreen={`${DYNAMIC_STATECHANGE_FIELDS.RECEIVEFIAT}`}
          ScreenId={depositObject.id}
          ScreenName={`${DYNAMIC_STATECHANGE_FIELDS.RECEIVEFIAT}`}
          closeModal={hideCaseModal}
          loadData={loadData}
        />
      </Modal>
    </>
  );
};
const connectStateToProps = ({
  depositStore,
  userConfig,
  permissions
}) => {
  return {
    depositStore,
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData, permissions
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    setAction: (val) => {
      dispatch(setCurrentAction(val));
    },
    dispatch
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(FiatDetails);
