
import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Row,
	Col,
	Select,
	Button,
	Form,
	Alert,Radio
} from "antd";
import List from "../grid.component";
import apiCalls from "../../api/apiCalls";
import { userNameLuSearch, getTransactionSearch,getAllTransactionSearch } from "./api";
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import Info from "../shared/sharedInfo";

const { Option } = Select;

class Transaction extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading: false,
			customerData: [],
			typeData: [],
			doctypeData: [],
			errorMsg: null,
			value: "",
			searchObj: {
				type: "All",
				docType: "All",
				customerId: "",
			},
			cryptoModal: false,
			selectedModal: "",
			tranObj: {},
			gridUrl: process.env.REACT_APP_GRID_API + "Transaction",
			allTransactionGridURL: process.env.REACT_APP_GRID_API + "Transaction/AllTransactions",

			selectedTab: 1,

		};
		this.gridRef = React.createRef();

		this.formref = React.createRef();

	}
	gridColumns = [
		{
			
			field: "transactionId",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			customCell: (props) => (
				<span className="gridLink trans-id" onClick={() => this.update(props)}>
					{props.dataItem?.transactionId}
				</span>
			),
		},
		{
			field: "date",
			title: "Submission Date",
			filter: true,
			width: 210,
			isShowTime: true,
			filterType: "date",
		},
		{
			field: "approvedDate",
			title: "Approval Date",
			filter: true,
			width: 210,
			isShowTime: true,
			filterType: "date",
		},
		{ field: "status", title: "Status", filter: true, width: 180, 
        
		customCell: (props) => {
			const statusColorConfig = this.props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === props.dataItem.status);
			const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
			const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';
	
			return (
				<td className="whitelist-name">
					<div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
						{props.dataItem.status}
					</div>
				</td>
			);
		},
		},			{
			field: "memberName",
			title: "Business Name/Personal Name",
			filter: true,
			width: 300,
			customCell: (props) => (
				<td className="whitelist-name">
					<div className="d-flex">
						<div className="white-address address-width">{props.dataItem.memberName}</div>
						{(props?.dataItem?.accountType || props.dataItem?.isDigitaltransfer !== null) && (
							<div className="file-labels ml-8 fs-12 address-label address-label-width">
								{props?.dataItem?.accountType || (props.dataItem.isDigitaltransfer && "Pyrros" || !props.dataItem?.isDigitaltransfe && "OpenPayd")}
							</div>)}
							{props?.dataItem?.externalAccount === "PIX" && (
							<div className="file-labels ml-8 fs-12 address-label address-label-width">
								{"PIX"}
							</div>
						)}
							</div>
				</td>
			),
			
		},
		{ field: "docType", title: "Transaction ", filter: true, width: 150 },
		{
			field: "fromWalletCode",
			title: "From Wallet Code",
			filter: true,
			width: 180,
		},
		{
			field: "fromValue",
			title: "From Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toWalletCode",
			title: "To Wallet Code",
			filter: true,
			width: 170,
		},
		{
			field: "toValue",
			title: "To Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "effectiveFee",
			title: "Fees",
			filter: true,
			width: 220,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comission",
			title: "Commission",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "comissionInBase",
			title: "Commission in Base",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComission",
			title: "Provider Commission",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "providerComissionInBase",
			title: "Provider Commission in Base",
			width: 270,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "sweepsourceComissionValue", title: "Sweep Source Commission", filter: true, width: 230, dataType: "number", filterType: "numeric" },
		{ field: "sweepSourceValueInBase", title: "Sweep Source in Base", filter: true, width: 230, dataType: "number", filterType: "numeric" },
		{ field: "sweepDestinationComissionValue", title: "Sweep Destination Commission value", filter: true, width: 280, dataType: "number", filterType: "numeric" },
		{ field: "sweepDestinationValueInBase", title: "Sweep Destination in Base", filter: true, width: 270, dataType: "number", filterType: "numeric" },
		{
			field: "totalAmount",
			title: "Total Cost",
			filter: true,
			width: 130,
			dataType: "number", filterType: "numeric"
		},
		{
			field: "profit",
			title: "Profit",
			width: 130,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			width: 160,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "provider",
			title: "Provider",
			filter: true,
			width: 160
		},
		{
			field: "fromValueBefore",
			title: "From Before Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "fromValueAfter",
			title: "From After Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueBefore",
			title: "To Before Value",
			width: 180,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "toValueAfter",
			title: "To After Value",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "referenceId", title: "Customer/Contract ID", filter: true, width: 200 },
		{ field: "memberEmail", title: "Email", filter: true, width: 220 },
		{
			field: "walletScore", title: "Wallet Score", filter: true, width: 160, dataType: "number",
			filterType: "numeric",
		},
		{ field: "userName", title: "User Name", filter: true, width: 200 },
	];

	gridAllTxnColumns = [
		{
			
			field: "transactionID",
			title: "Transaction ID",
			filter: true,
			locked: true,
			width: 210,
			// customCell: (props) => (
				
			// 		<span className="gridLink trans-id" onClick={() => this.update(props)}>
            //           {props.dataItem?.transactionID }

			// 		</span>
			// ),
		},
		{
			field: "createdDate",
			title: "Submission Date",
			filter: true,
			width: 210,
			isShowTime: true,
			filterType: "date",
		},
		{
			field: "approvedDate",
			title: "Approval Date",
			filter: true,
			width: 210,
			isShowTime: true,
			filterType: "date",
		},
		{ field: "status", title: "Status", filter: true, width: 180, 
        
		customCell: (props) => {
			const statusColorConfig = this.props?.userConfig?.statusColors?.find(colorConfig => colorConfig.status === props.dataItem.status);
			const statusColor = statusColorConfig?.color.toLowerCase() || 'black';
			const textColor = (statusColor === 'red' || statusColor === 'darkgreen') ? 'white' : 'black';
	
			return (
				<td className="whitelist-name">
					<div className={`white-address ibancolor ${statusColor}`} style={{ color: textColor }}>
						{props.dataItem.status}
					</div>
				</td>
			);
		},
		},			{
			field: "memberName",
			title: "Business Name/Personal Name",
			filter: true,
			width: 300,
			customCell: (props) => (
				<td className="whitelist-name">
				<div className="d-flex">
				<div className="white-address address-width">{props.dataItem.memberName}</div>
					{(props?.dataItem?.bankAccountType || props.dataItem?.isDigitalTransfer || props.dataItem?.isDigitalTransfer==false) && (
						<div className="file-labels ml-8 fs-12 address-label address-label-width">
							{props?.dataItem?.bankAccountType || (props.dataItem.isDigitalTransfer && "Pyrros" || props.dataItem?.isDigitalTransfer==false && "OpenPayd")}
						</div>)}
						{props?.dataItem?.senderBankName === "PIX" && (
							<div className="file-labels ml-8 fs-12 address-label address-label-width">
								{"PIX"}
							</div>
						)}
						</div>
				</td>
			),
		},
		{ field: "txnType", title: "Transaction", filter: true, width: 170 },
		{ field: "fromWalletCode", title: "From Wallet", filter: true, width: 170 },
		{ field: "fromValue", title: "From Value", filter: true, width: 150 ,dataType: "number",
		filterType: "numeric",},
		{ field: "toWalletCode", title: "To Wallet", filter: true, width: 150, },
		{ field: "tovalue", title: "To Value", filter: true, width: 150,dataType: "number",
		filterType: "numeric", },
		{ field: "beforeValue", title: "Before Value", filter: true, width: 150,dataType: "number",
		filterType: "numeric", },
		{ field: "afterValue", title: "After Value", filter: true, width: 150,dataType: "number",
		filterType: "numeric", },
		{ field: "provider", title: "Provider", filter: true, width: 150 },
		{ field: "currency", title: "Currency", filter: true, width: 150 },
		

		{
			field: "grossAmount",
			title: "Gross Amount",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "grossAmountInBase",
			title: "Gross Amount in Base",
			filter: true,
			width: 200,
			dataType: "number",
			filterType: "numeric",
		},

		{
			field: "netAmount",
			title: "Net Amount",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "netAmountInBase",
			title: "Net Amount in Base",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "senderName",
			title: "Sender's Name",
			filter: true,
			width: 170,
		},
		{
			field: "senderAccount",
			title: "Sender's Bank Account Number/IBAN",
			width: 300,
			filter: true,
			footerCell: true,
			
		},
		{
			field: "senderBankName",
			title: "Sender's Bank Name",
			filter: true,
			width: 220,
			
				
			},
			
		
		{
			field: "bankName",
			title: "Bank Name",
			width: 150,
			filter: true,
			
		},
		{
			field: "bankAccountNumber",
			title: "Bank Account Number/IBAN",
			width: 250,
			filter: true,
			footerCell: true,
			
		},
		{
			field: "depositType",
			title: "Deposit Type",
			width: 150,
			filter: true,
			
		},
		
		{ field: "addressType", title: "Address Type", filter: true, width: 150 },
		{ field: "addressBookStatus", title: "Recipient Status", filter: true, width: 180 },

		{ field: "recepientName", title: "Recipient Full Name", filter: true, width: 180 },
		{
			field: "routingNumber",
			title: "BIC/SWIFT/ABA Routing/Uk Sort Code",
			filter: true,
			width: 320,
		},
		{ field: "coin", title: "Coin", filter: true, width: 150 },
		{ field: "network", title: "Network", filter: true, width: 150 },
		{ field: "walletAddress", title: "Wallet Address", filter: true, width: 150 },
		{ field: "sourceWalletAddress", title: "Source Wallet Address", filter: true, width: 200 },
		{ field: "amlScreeningStatus", title: "AML Screening Status", filter: true, width: 250 },

		{ field: "walletScore", title: "Wallet Score", filter: true, width: 130,dataType: "number",
		filterType: "numeric", },
		{ field: "amlRating", title: "AML Rating", filter: true, width: 150 },
		
		{ field: "comission", title: "Commission", filter: true, width: 150, dataType: "number", filterType: "numeric" },
		{ field: "comissionInBase", title: "Commission In Base", filter: true, width: 180, dataType: "number", filterType: "numeric" },
		{ field: "effectiveFee", title: "Fees", filter: true, width: 150, dataType: "number", filterType: "numeric" },
		{
			field: "tierDiscount",
			title: "Tier Discount",
			width: 150,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sbCredit",
			title: "SuisseBase Credit Used",
			width: 200,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "pcomissionvalue",
			title: "Provider Commission",
			filter: true,
			 width: 220,
			filterType: "numeric",
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "pcomissionvalueInBase",
			title: "Provider Commission in Base",
			width: 270,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepsourceComissionValue",
			title: "Sweep Source Commission",
			width: 270,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepSourceValueInBase",
			title: "Sweep Source in Base",
			width: 220,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepDestinationComissionValue",
			title: "Sweep Destination Commission",
			width: 270,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "sweepDestinationValueInBase",
			title: "Sweep Destination in Base",
			width: 250,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "totalAmount",
			title: "Total Cost",
			filter: true,
			width: 130,
			dataType: "number", filterType: "numeric"
		},
		{
			field: "totalAmountInBase",
			title: "Total Cost in Base",
			filter: true,
			width: 200,
			dataType: "number", filterType: "numeric"
		},
		{
			field: "profit",
			title: "Profit",
			width: 130,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{
			field: "profitInBase",
			title: "Profit in Base",
			width: 160,
			filter: true,
			footerCell: true,
			dataType: "number",
			filterType: "numeric",
		},
		{ field: "userName", title: "User Name", filter: true, width: 200 },
		{ field: "memberEmail", title: "Email", filter: true, width: 280 },
		{
			field: "referenceId",
			title: "Customer/Contract ID",
			width: 200,
			filter: true,
		
		},
		{ field: "rejectReason", title: "Reason For Rejection", filter: true, width: 250 },
		{ field: "remarks", title: "Remarks", filter: true, width: 250 },
		{ field: "note", title: "Notes", filter: true, width: 250 },
		{
			field: "lockUnlock",
			title: "Lock/Unlock",
			width: 150,
			filter: true,
			
		},
		{ field: "reference", title: "Reference ID", filter: true, width: 150 },
				
		
	];

	componentDidMount = () => {

		this.TransactionSearch(this.state.selectedTab);
		this.props.dispatch(updateCurrentScreen("transaction"));

	};

	update = (e) => {
		const items = e.dataItem;
		const val = items.id;
		this.props.dispatch(
			setBreadcrumb({ key: "/transaction/" + val, val: items.transactionId || items.transactionID })
		);
		this.props.history.push("/transaction/" + val);

	};

	TransactionUserSearch = async (userVal) => {
		let response = await userNameLuSearch(userVal);
		if (response.ok) {
			this.setState({
				customerData: response.data,
				errorMsg: null
			});
		} else {
			this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
		}
	};

	handleUserChange = (event) => {
		if (event.target.value != null && event.target.value.length > 2) {
			let userVal = event.target.value;
			this.TransactionUserSearch(userVal);
		}
	};

	TransactionSearch = async (selectedTab) => {
		let response = selectedTab == 1 ? await getTransactionSearch() : await getAllTransactionSearch();
		//	let response = await getTransactionSearch()		
		if (response.ok) {
			this.setState({
				typeData: response.data.types,
				doctypeData: response.data.docTypes,
				errorMsg: null
			});
		}
		else {
			this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
		}
	};
	handleChange = (value, prop) => {
		var val = "";
		let { customerData, searchObj } = this.state;
		if (prop === "customerId") {
			let index = customerData.findIndex(function (o) {
				return o.name === value;
			});
			val = customerData[index].id;
		}
		searchObj[prop] = prop === "customerId" ? val : value;
		this.setState({ ...this.state, searchObj });
	};
	handleSearch = (values) => {
		let { searchObj } = this.state;
		this.setState({ ...this.state, searchObj }, () => {
			this.gridRef.current.refreshGrid();
			//this.formref.current?.resetFields();

		});

	};
	handleTransactionTabs = (e) => {
        this.setState({
            ...this.state,
            selection: [],
            selectedObj: {},
            check: false,
            warningMsg: null,
            selectedTab: e.target.value,
            istabAcessdenied: false,
            searchObj: {
                type: "All",
                docType: "All",
                customerId: "",
            },
        },()=>{
            this.formref.current?.resetFields();
            this.TransactionSearch(e.target.value);
        });
    };

	generateOptions = (data) => {
		return data.map((d) => (
			<Option key={d.value} value={d.name}>
				{d.name}
			</Option>
		));
	};
	CloseCrypto = () => {
		this.setState({ ...this.state, cryptoModal: false })
	}
	handleTransactionTabs = (e) => {
        this.setState({
            ...this.state,
            selection: [],
            selectedObj: {},
            check: false,
            warningMsg: null,
            selectedTab: e.target.value,
            istabAcessdenied: false,
            searchObj: {
                type: "All",
                docType: "All",
                customerId: "",
            },
        },()=>{
            this.formref.current?.resetFields();
            this.TransactionSearch(e.target.value);
        });
    };
	render() {
		const { customerData, typeData, doctypeData, gridUrl, searchObj, errorMsg, selectedTab,allTransactionGridURL } =
			this.state;
		const options = this.generateOptions(customerData);
		const options1 = this.generateOptions(typeData);
		const options2 = this.generateOptions(doctypeData);

		return (
			<>
				{errorMsg !== undefined && errorMsg !== null && (
					<div style={{ width: '100%' }}>
						<Alert
							className="w-100 mb-16 align-center"
							type="warning"
							description={errorMsg}
							showIcon
						/>
					</div>
				)}

				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					className="mb-16">
					<Radio.Group
						value={selectedTab}
						onChange={this.handleTransactionTabs}
						className="buysell-toggle mb-0">
						<Radio.Button value={1}> Transactions </Radio.Button>
						<Radio.Button value={2}> All Transactions </Radio.Button>
					</Radio.Group>


				</div>
				<div>
					<Form
						initialValues={this.state.customerData} ref={this.formref}
						className="ant-advanced-search-form form form-bg search-bg pt-8"
						autoComplete="off">
						<Row style={{ alignItems: "flex-end" }}>
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="customerId"
									className="input-label mb-0"
									label="User Name">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onKeyUp={(event) =>
											this.handleUserChange(event, "customerId")
										}
										onChange={(e) => this.handleChange(e, "customerId")}
										placeholder="Select Members">
										{options}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="type"
									className="input-label mb-0"
									label="Type">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "type")}
										placeholder="Select Type">
										{options1}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} xs={24} md={12} lg={7} className="px-8">
								<Form.Item
									name="docType"
									className="input-label mb-0"
									label="Transaction ">
									<Select
										defaultValue="All"
										className="cust-input w-100 bgwhite"
										showSearch
										onChange={(e) => this.handleChange(e, "docType")}
										placeholder="Select Transaction">
										{options2}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={24} md={3} lg={3} xl={3} xxl={3} className="px-8 text-right">

								<Button
									type="primary"
									className="primary-btn px-24 search-btn mt-20"
									htmlType="submit"
									onClick={this.handleSearch}
								>Search
								</Button>

							</Col>
						</Row>
					</Form>
				</div>
				<List
					showActionBar={true}
					url={selectedTab == 1 ? gridUrl : allTransactionGridURL}
					pKey={"Transactions"}
					additionalParams={searchObj}
					ref={this.gridRef}
					key={selectedTab == 1 ? gridUrl : allTransactionGridURL}
					columns={selectedTab == 1 ? this.gridColumns : this.gridAllTxnColumns}
					showExcelExport={true}
					className="transaction-table-header"
				/>


				{this.state.cryptoModal && <Info id={this.state.selectedId} type={this.state.selectedModal} screen={'Deposit'} cryptoModal={this.state.cryptoModal} CloseCrypto={this.CloseCrypto} />}

			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc };
};
export default connect(connectStateToProps, (dispatch) => {
	return { dispatch };
})(Transaction);
