export const DYNAMIC_STATECHANGE_FIELDS = {
    PLEASE_ENTER_VALID_CONTENT: "Please enter valid content",
    CONFIRM_APPROVE_REJECT:"Confirm Pending/Approve/Reject?",
    IS_CHECKBOX:"isCheckBox",
    PLEASE_SELECT_CHECKBOX:"Please select checkbox",
    CHECK:"check",
    REMARKS:"Remarks",
    TRANSACTION_REFID:"transactionrefId",
    REFERENCE_ID:"Reference ID",
    STATUS:"status",
    STATE:"State",
    SELECT_STATE:"Select State",
    PROVIDER_TXNID:"providerTxnId",
    KRAKEN_BINANCE:"Kraken/Binance/Txn ID",
    BANK_TXNID:"bankTxnId",
    BANK_TXN_ID:"Bank Txn ID",
    PREVIOUSSTATE:"previousState",
    PREVIOUS_STATE:"Previous State",
    P_COMMISSION:"pCommisionValue",
    PROVIDER_FEES:"Provider fees",
    SWEEP_SOURCE_COMMUNICATION:"sweepSourceCommisionValue",
    SWEEP_AT_SOURCE:"Sweep at source",
    SWEEP_DESTINATION_COMMUNICATION:"sweepDestinationCommissionValue",
    SWEEP_DESTINATION:"Sweep at destination",
    BANK_VALUE_DATE:"BankValueDate",
    BANK_VALUE:"Bank Value Date",
    REJECT_REASEON:"rejectReason",
    REASON_FOR_REJECTION:"Reason For Rejection",
    STATUS_REMARKS:"statusRemarks",
    CUSTOMERS_DETAILS:"/customers/details/",
    CUSTOMER_CONTRACTID:"Customer/Contract ID",
    CREATE_CASE:"Create Case",
    CLOSE:"Close",
    RECEIVECRYPTO:"ReceiveCrypto",
    RECEIVEFIAT:"ReceiveFiat",
    APPROVAL_IN_PROGRESS:"approval in progress",
    APPROVED:"Approved",
    REGISTERED:"Registered",
    UNDER_REVIEW:"under review",
    REJECTED:"Rejected",
    REASON_FOR_CLOSE:"Reason For Close",
    CHECKED:"checked",
    CHECKBOX:"checkbox",
    REJECT:"Reject",
    APPROVE:"Approve",
    PENDING:"Pending",
    LP:'LP',
    NAME_LP:'lp',
    SELECT_LP:'Select LP',
    SWEPT:'Swept',
    LPSUBACCOUNT:'Sub Account',
    SELECT_LP_SUBACCOUNT:'Select Sub Account',
    NAME_SUBACCOUNT:'subAccounts',
    NAME_BANKDETAILS:'bankPartner',
    BANK_DETAILS:'Bank Partner',
    SELECT_BANKDETAILS:'Select Bank Partner'
}