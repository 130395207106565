import React, { Component } from "react";
import { Table, Form, Typography, Alert } from "antd";
import { getCompanyData } from "./api";
import { publishShowActions } from "../grid.component/subscribir";
import Loader from "../loader.component";
import apiCalls from "../../api/apiCalls";

const { Paragraph } = Typography;

class CompanyWallets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyWallet: [],
      loading: false,
      errorMsg: null,
    };
    this.formref = React.createRef();
  }

  columns = [
    {
      title: <div style={{ fontWeight: "bold" }}>Wallet</div>,
      dataIndex: "wallet",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Vault Id</div>,
      dataIndex: "vaultId",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Network</div>,
      dataIndex: "netWork",
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Address</div>,
        dataIndex: "address",
        render: (text) =>
          text ? (
            <Paragraph copyable={{ text }}>{text}</Paragraph>
          ) : (
            <span></span>
          ),
    },
  ];

  componentDidMount() {
    publishShowActions(false);
    this.getCompanyData();
  }

  getCompanyData = async () => {
    this.setState({ ...this.state, loading: true });
    let response = await getCompanyData();
    if (response.ok) {
      this.setState({ ...this.state, companyWallet: response.data, loading: false, errorMsg: null });
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) });
    }
  };

  render() {
    const { companyWallet } = this.state;
    return (
      <>
        {this.state.errorMsg && (
          <div style={{ width: "100%" }}>
            <Alert className="mb-16 w-100" type="warning" description={this.state.errorMsg} showIcon />
          </div>
        )}
        {this.state.loading && <Loader />}
        <Form initialValues={companyWallet} ref={this.formref} autoComplete="off">
          <Table
            columns={this.columns}
            dataSource={companyWallet}
            bordered
            className="Company-custom-table"
            pagination={{ pageSize: 50 }}  
          />
        </Form>
      </>
    );
  }
}

export default CompanyWallets;
