import React, { Component } from 'react';
import { Tooltip, message, Modal, Button, Alert, Form, Typography, Select } from 'antd';
import List from "../grid.component";
import apiCalls from '../../api/apiCalls';
import { connect } from 'react-redux';
import { setBreadcrumb } from '../../reducers/breadcrumbReducer';
import { setCurrentAction } from '../../reducers/actionsReducer';
 import {  activeInactive } from './api';
import { getCurrencyLU } from '../banks.component/api';

import VerificationsModal from '../banks.component/verificationsModal';

const { Title } = Typography;
const { Option } = Select;
class PersonalBanks extends Component {
    constructor (props) {
        super(props);
        this.state = {
            check: false,
            selection: [],
            selectedObj: {},
            modal: false,
            obj: {
                "id": [],
                "tableName": "Common.ExternalAccounts",
                "status": []
            },
            isCheck:false,
            isCheckBorderError:null,
            alert: false,
            searchObj: { currency: "All" },
            currencyLU: [],
            error: null,
            btnDisabled: false,
            warningMsg: null,
            isBnkLoading: false,
            mdlerror:null,
            isVerificationsComplete: false,
            VerificationsModalIsOpen: false
        }
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.gridRef = React.createRef();
    }
    async getCurrencyData() {
        const response = await getCurrencyLU();
        if (response.ok) {
            this.setState({ ...this.state, currencyLU: response.data,error:null });
        }else{
            this.setState({...this.state,error:apiCalls.isErrorDispaly(response)})
        }
    }
    componentDidMount() {
        this.props.dispatch(setCurrentAction(null));
        setTimeout(() => this.getCurrencyData(), 1000)


    }
 
    gridColumns = [
        {
            field: "", title: "", width: 50, locked: true,
            customCell: (props) => (
                <td className="text-center">
                    <label className="text-center custom-checkbox">
                        <input
                            id={props.dataItem.id}
                            name="check"
                            type="checkbox"
                            checked={this.state.selection.indexOf(props.dataItem.id) > -1}
                            onChange={(e) => this.handleInputChange(props, e)}
                            className="grid_check_box"
                        />
                        <span></span>
                    </label>
                </td>
            )
        },
        { field: "currencyCode", title: "Currency", width: 120, locked: true, filter: true, customCell: (props) => <td><div className="gridLink" onClick={() => this.update(props)}>{props.dataItem.currencyCode}</div></td> },
        { field: "bankName", title: "Bank Name", width: 250, filter: true },
        { field: "bankAddress", title: "Bank Address", width: 270, filter: true },
        { field: "accountName", title: "Account Name", width: 250, filter: true },
        { field: "accountNumber", title: "Account Number/IBAN", width: 250, filter: true},
        { field: "accountAddress", title: "Account Address", width: 270, filter: true },
        { field: "status", title: "Status", width: 150, filter: true },
        { field: "isDefaultBank", title: " Is Default Bank", width: 180, filter: true ,filterType:'boolean', customCell: (props) => <td>{props.dataItem.isDefaultBank ? 'True':'False'}</td> }
    ]

    statusSuccess = () => {
        message.destroy();
        message.success({
            content: 'Record ' + (this.state.selectedObj.status === 'Active' ? 'deactivated' : 'activated') + ' successfully',
            className: 'custom-msg',
            duration: 4
        });
    };

    add = () => {
        this.props.history.push({ pathname: '/personalbanks/00000000-0000-0000-0000-000000000000', state: { pKey: "banks", action: "add" } });
        this.props.dispatch(setBreadcrumb({ key: '/personalbanks/00000000-0000-0000-0000-000000000000', val: "Add Personal Bank" }))

    }

    update = (e) => {
        const items = e.dataItem;
        const val = (items.id);
        this.props.history.push('/personalbanks/' + val + '/disabled');
        this.props.dispatch(setBreadcrumb({ key: '/personalbanks/' + val, val: e.dataItem.currencyCode }))
     
    }
    handleInputChange = (prop, e) => {
        const rowChecked = prop.dataItem;
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let { selection } = this.state;
        let idx = selection.indexOf(rowChecked.id);
        if (selection) {
            selection = [];
        }
        if (idx > -1) {
            selection.splice(idx, 1)
        }
        else {
            selection.push(rowChecked.id)
        }
        this.setState({ ...this.state, [name]: value, selectedObj: rowChecked, selection, warningMsg: null });
    }

    statusUpdate = () => {
        if (!this.state.check) {
            this.setState({ ...this.state, warningMsg: 'Please select the one record' })
        }
        else {
            this.setState({ modal: true, warningMsg: null })
        }
    }

    onActionClick = (key) => {
        const actions = {
            add: "add",
            edit: 'edit',
            disable: 'statusUpdate',
        }
        this[actions[key]]();
    }

    handleOk = async () => {
        this.setState({ ...this.state, btnDisabled: true, isBnkLoading: true,mdlerror:null })
        let statusObj = this.state.obj;
        statusObj.id.push(this.state.selectedObj.id);
        statusObj.modifiedBy = this.props.userConfig?.userName;
        statusObj.status.push(this.state.selectedObj.status);
        statusObj.info = JSON.stringify(this.props.trackAuditLogData);
        statusObj.customerId = this.props.userConfig?.id;
        statusObj.modifiedDate = new Date();
        const response = await activeInactive(statusObj);
        if (response.ok) {
            this.setState({ modal: false, btnDisabled: false,isBnkLoading:false, selection: [], check: false });
            this.setState({...this.state, VerificationsModalIsOpen: false, modal: false,})
            this.statusSuccess();
            this.props.dispatch(setCurrentAction(null));
            this.gridRef.current.refreshGrid();
        } else {
            this.setState({ ...this.state, isLoading: false,isBnkLoading:false, selection: [], isCheck: false, mdlerror: response.status === 401 ? (response.data.message) : "True records can't be deactivate"});
        }


    };
    handleVerificationModelOpen=()=>{
        if(this.state.selectedObj.status==="Inactive"&&!this.state.isCheck){
            this.setState({...this.state, VerificationsModalIsOpen: true, })
        }else if(this.state.isCheck){
            this.setState({...this.state, VerificationsModalIsOpen: true,isCheckBorderError:null,isCheck:false})
        }
        else if(this.state.selectedObj.status==="Active" &&!this.state.isCheck){
            this.setState({...this.state, VerificationsModalIsOpen: false,isCheckBorderError:"Please select checkbox"})
        }
        
        
    }
     handleBankChange = (e) => {
        this.setState({...this.state,isCheck:e.target.checked,isCheckBorderError:null});
    };
    handleCancel = e => {
        this.props.dispatch(setCurrentAction(null));
        this.setState({ modal: false, selection: [], check: false, warningMsg: null,mdlerror:null,isCheck:false,isCheckBorderError:null });
    }
    edit = () => {
        if (!this.state.check) {
            this.setState({ ...this.state, warningMsg: 'Please select the one record' })
        } else {
            const obj = this.state.selectedObj;
            const val = obj.id;
            if (obj.status === 'Inactive') {
                this.props.history.push('/personalbanks/' + val + '/disabled');
                this.props.dispatch(setBreadcrumb({ key: '/personalbanks/' + val, val: obj.currencyCode }))
            }
            else {
                this.props.history.push('/personalbanks/' + val);
                this.props.dispatch(setBreadcrumb({ key: '/personalbanks/' + val, val: obj.currencyCode }))
            }
        }
    }
    render() {
        const { error, warningMsg,isBnkLoading, mdlerror,isCheckBorderError } = this.state;
        return (
            <>
            {error !== undefined && error !== null && <Alert type="error" className="mb-16" showIcon  description={error} />}
     
            {warningMsg !== undefined && warningMsg !== null && (
                <Alert
                    className="w-100 mb-16 banks-errmsg"
                    type="warning"
                    description={warningMsg}
                    showIcon
                />
            )}
            <div>
                <div className="d-flex justify-content align-center mb-16">
                    <Title className="page-title mb-0">

                    </Title>
                    <div>
                        <Form
                            initialValues={{ currency: "all" }}
                            className="form form-bg mb-0 d-flex align-center "
                            autoComplete="off">
                            <div>
                                <Form.Item name="gridSearch" className="">
                                    <Select
                                        style={{ width: 250 }}
                                        className="cust-input mb-0"
                                        onChange={(val) => {
                                            this.setState({ ...this.state, searchObj: { currency: val } }, () => {
                                                this.gridRef.current.refreshGrid();
                                            })
                                        }}
                                        defaultValue="All" >
                                        <Option value="All">All</Option>
                                        {this.state.currencyLU?.map((currency, idx) => <Option key={idx} value={currency.code}>{currency.code}</Option>)}
                                    </Select>
                                </Form.Item></div>
                        </Form>
                    </div>
                </div>
                <List showActionBar={true} onActionClick={(key) => this.onActionClick(key)} pKey={"banks"} ref={this.gridRef} url={process.env.REACT_APP_GRID_API + `Banks/Personal/${this.state.searchObj.currency}`} columns={this.gridColumns} />
                <Modal
                    title={this.state.selectedObj.status === 'Active' ? 'Confirm Deactivate?' : 'Confirm Activate?'}
                    visible={this.state.modal}
                    closeIcon={<Tooltip title="Close"><span className="icon md x c-pointer" onClick={this.handleCancel} /></Tooltip>}
                    footer={<>
                        <Button
                            type="primary"
                            className="primary-btn cancel-btn"
                            onClick={this.handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button type="primary"
                         className="primary-btn"
                         onClick={()=> this.handleVerificationModelOpen()}>
                            Confirm 
                        </Button>
                    </>}
                >
                    {isCheckBorderError !== undefined && isCheckBorderError !== null && <Alert type="error" className="mb-16" showIcon  description={isCheckBorderError} />}
                     {mdlerror !== undefined && mdlerror !== null && <Alert type="error" className="mb-16" showIcon  description={mdlerror} />}
                   {this.state.selectedObj.status!=="Inactive" &&
                     <div className="d-flex my-16 check-bank">
                            <label className="text-center custom-checkbox">
                                <input
                                    name="isCustomerDocument"
                                    type="checkbox"
                                    className={`grid_check_box mr-8`}
                                    onChange={(e) => this.handleBankChange(e)}
                                    checked={this.state.isCheck}
                                />
                                <span></span>
                            </label>
                            <p className="fs-16 mb-0">When you change to {this.state.selectedObj.status === 'Active' ? 'inactivate' : 'activate'}, the bank commissions will be {this.state.selectedObj.status === 'Active' ? 'inactivate' : 'activate'} across all customers and templates. Please select the checkbox to confirm.
                        </p>
                        </div>}
                        {this.state.selectedObj.status==="Inactive" &&
                     
                     <p className="fs-16 mb-0">Do you really want to {this.state.selectedObj.status === 'Active' ? 'deactivate?' : 'activate?'}</p>
                        }
                    
                </Modal>
                {this.state.VerificationsModalIsOpen &&<VerificationsModal
                        verificationsComplete={() => this.setState({...this.state, verificationsComplete: true}) }
                        visible={this.state.VerificationsModalIsOpen}
                        closeModal={()=> {
                            this.setState({...this.state, VerificationsModalIsOpen: false, modal: false, selection: [], check: false, warningMsg: null,mdlerror:null })
                            this.props.dispatch(setCurrentAction(null));

                        } }
                        onConfirmButton={()=>{
                            this.handleOk()
                            
                        }}
                        loadingBtn={isBnkLoading}
                    />} 
            </div>
            </>
        );
    }
}
const connectStateToProps = ({ oidc, userConfig }) => {
    return { userConfig: userConfig.userProfileInfo, oidc, trackAuditLogData: userConfig.trackAuditLogData }
}
export default connect(connectStateToProps, (dispatch) => { return { dispatch } })(PersonalBanks);
