import React, { useEffect, useState } from "react";
import { getCommissions, synceCommission } from "../api";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Modal, Button, Form, Col, Row, Input, message, Alert,Select } from "antd";
import Loader from '../../../components/loader.component';
import apiCalls from '../../../api/apiCalls';
import { publishShowActions } from "../../grid.component/subscribir";
import NumberFormat from "react-number-format";
import { validateContentRule } from '../../../utils/custom.validator'
const AddCommissionsView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [commissionData, setCommissionData] = useState(null);
  const [suisseBaseCreditvisible, setSuisseBaseCreditvisible] = useState(false);
  const [syncItem, setSyncItem] = useState(null)
  const history = useHistory();
  const [form] = Form.useForm();
  const [commissionList, setCommissionList] = useState([])
  const [errorMsg, setErrorMsg] = useState(null);
  useEffect(() => {
    getCommissionsData();
    publishShowActions(false);
  }, []);//eslint-disable-line react-hooks/exhaustive-deps
  const getCommissionsData = async () => {
    setIsLoading(true)
    let res = await getCommissions(props.match.params.id);
    if (res.ok) {
      setCommissionData(res.data, "commission")
      form.setFieldsValue({ templateName: res.data.templateName,exchange:res.data?.exchange });
      setIsLoading(false)
      let products = res.data.commissionDetails
      const groupByCategory = products.reduce((group, product) => {
        const { operation } = product;
        group[operation] = group[operation] ?? [];
        group[operation].push(product);
        return group;
      }, {});
      const tableData = Object.entries(groupByCategory);
      setCommissionList(tableData)
    } else {
      setErrorMsg(apiCalls.isErrorDispaly(res));
      setIsLoading(false)
      window.scrollTo(0, 0)
    }
  }
  const putCommissionsSync = async () => {
    setIsLoading(true)
    let response = await synceCommission(syncItem?.id);
    if (response.ok) {
      message.success({
        content: 'Record synced successfully',
        className: "custom-msg",
        duration: 3
      })
      setSuisseBaseCreditvisible(false)
      getCommissionsData()

    } else {
      setErrorMsg(apiCalls.isErrorDispaly(response));
      window.scrollTo(0, 0)
    }
  }
  const handleSuisseBaseCreditModal = (item) => {
    setSyncItem(item)
    setSuisseBaseCreditvisible(true)
  }
  const handleModalCloseCreidit = () => {
    setSuisseBaseCreditvisible(false)
  }
  const handleSyncConfirm = () => {
    putCommissionsSync();
  }
  const handleCancel = () => {
    history.push(`/commissions`)
  }
  const renderNumberField=(val)=>{
    return val != null ? Number(val).toLocaleString(undefined, {maximumFractionDigits: 2}) : "-";
  }
  return (
    <>
      {errorMsg !== undefined && errorMsg !== null && errorMsg !== "" &&
        <div style={{ width: '100%' }}>
          <Alert
            className="w-100 mb-16"
            type="warning"
            description={errorMsg}
            showIcon
          />
        </div>}

      <Form
        autoComplete="off"
        className="commission-form"
        initialValues={commissionData}
        form={form}
      >
        <Row className="Commission-Template customer-feesform Template-Name">
          <Col xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={6}
            xxl={6}>
            <Form.Item
              name="templateName"
              label="Template Name"
              style={{ paddingLeft: "10px" }}
              rules={[
                {
                  required: true,
                  message: 'Is required'
                },
                {
                  whitespace: true,
                  message: 'Is required'
                }, {
                  validator: validateContentRule
                }
              ]}
            >
              <Input type="text"
                placeholder="Template Name"
                maxLength={50}
                style={{ width: "290px" }}
                className="cust-input"
                disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={6}
                xxl={6}>
                <Form.Item
                  name="exchange"
                  label="Exchange"
                  rules={[
                    {
                      required: true,
                      message: "Is required",
                      whitespace: true
                    },
                    {
                      validator: validateContentRule
                    }
                  ]}
                >
                  <Select
                    className="cust-input"
                    style={{ width: "290px" }}
                    disabled={true}
                    placeholder="Select Exchange"
                  >
                    
                  </Select>
                </Form.Item>
              </Col>
        </Row>
        {isLoading ? <Loader /> : (
          <div className="table-scroll">
            <table className="commision-table table-border edit-commition-table view-commition-table" border="1">
              <thead><tr className="table-header-row">
                <th style={{ width: "150px" }}></th>
                <th className="p-0">
                  <table className={`${commissionData?.templateName =='Default' && "table-partner-head" || "normal-template"}` }>
                    <tr>
                      <th className="text-center" style={{ paddingLeft: "55px" }}>SuisseBase Fees</th>
                      <th colSpan={5} className="table-border-right text-center"></th>
                      <th style={{ width: "100px" }} className="table-border-right" >Partner</th>
                      <th style={{ width: "124px" }} className="table-border-right">Sub-Partner</th>
                      {commissionData?.templateName == "Default" &&
                        <th ></th>
                      }
                    </tr>
                  </table>
                </th>
              </tr></thead>
              <tbody>
                {commissionList?.map(([operation, items]) => (
                  <React.Fragment key={operation}>
                    <tr>
                      <td style={{ width: "100px" }}>{operation}</td>

                      <td className="p-0" >

                        {items?.map((item) => {
                        const iconClassOneTime = item.isOneTime ? "icon md greenCheck mr-8" : "icon md greyCheck mr-8";
                        const iconClassMonthly = item.isMonthly ? "icon md greenCheck mr-8" : "icon md greyCheck mr-8";
                         return <table className="row-border inside-table" >
                            <tr>
                              <td style={{width:"91px"}}> <tr>{item.currencyType} </tr> {" "}<tr>{item.currency}</tr></td>
                              <td style={{ width: "187px" }} >{item.bankName}
                                <div>
                                  {" "} {item.isPersonalBank && <span className="file-labels  fs-12 address-label address-label-width">Personal</span>}
                                  {" "}
                                  {item.status === "Inactive" &&
                                    <span className="file-labels  fs-12 address-label address-label-width in-active">Inactive</span>
                                  }{" "}
                                    {" "} {item.type == "Card" && <span className="file-labels  fs-12 address-label address-label-width">Card</span>}

                                </div>
                              </td>
                              <td style={{ width: "150px" }}>
                                <Form.Item className="customised-input">
                                  <div className="d-flex align-center">
                                    <span className={item.isMinMax ? item.isMinMax === true && "icon md greenCheck mr-8" : "icon md greyCheck mr-8"}></span>
                                    <label>Min: <span className="minmax-value"><>
                                      {item.minFee !=null ?(<NumberFormat value={item.minFee} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)
                                        : ("-")}
                                    </></span></label>
                                  </div>
                                </Form.Item>
                              </td>
                              <td style={{ width: "150px" }}>
                                <Form.Item className="customised-input">
                                  <div className="d-flex align-center">
                                    <label>Max: <span className="minmax-value"><>{item.maxFee !=null ? <>{`${item.maxFee}%`}</> : "-"}</></span></label>
                                  </div>
                                </Form.Item>
                              </td>
                              <td style={{ width: "150px" }}>
                                <Form.Item className="customised-input">
                                  <div className="d-flex align-center">
                                    <span className={item.isFlat ? item.isFlat === true && "icon md greenCheck mr-8" : " icon md greyCheck  mr-8"}></span>
                                    <label>Flat: <span className="minmax-value"><>
                                    {item.flatFee!=null ? <><NumberFormat value={item.flatFee} decimalSeparator="." displayType={'text'} thousandSeparator={true}/></> : "-"}
                                    </></span></label>
                                  </div>
                                </Form.Item>
                              </td>

                              <td style={{ width: "220px" }}>
                                {item.isPersonalBank ?
                                  <div className="d-flex align-center mr-16">
                                    <span className={iconClassOneTime}></span>
                                    <label>One Time: <span className="minmax-value">
                                    {renderNumberField(item.oneTimeFee)}
                                    </span></label>
                                  </div> : <div className="text-center">-</div>}
                              </td>
                              <td style={{ width: "200px" }}>
                                {item.isPersonalBank ?
                                  <div className="d-flex align-center mr-16">
                                    <span className={iconClassMonthly}></span>
                                    <label>Monthly: <span className="minmax-value">
                                    {renderNumberField(item.monthlyFee)}
                                  </span></label>
                                  </div> : <div className="text-center">-</div>}
                              </td>

                              <td style={{ width: "100px" }} ><div className="text-center">

                                {item.partnerPercentage !=null ? <span className="minmax-value">{`${item.partnerPercentage}%`}</span> : "-"}
                                
                                </div></td>
                              <td style={{ width: "124px" }} ><div className="text-center">

                                {item.subPartnerPercentage !=null ? <span className="minmax-value">{`${item.subPartnerPercentage}%`}</span> : "-"}
                                
                                </div></td>
                              {commissionData?.templateName == "Default" &&
                                <td ><Button onClick={() => handleSuisseBaseCreditModal(item)} type="primary" className="primary-btn" disabled={true} >Sync</Button></td>
                              }
                            </tr>
                          </table>
                        })}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>

          </div>
        )}
        <Modal
          title={<p className="mb-0">Sync</p>}
          visible={suisseBaseCreditvisible}
          className="crypto-list"
          closeIcon={
            <span onClick={handleModalCloseCreidit}
              className="icon md x c-pointer"
            />
          }
          destroyOnClose={true}

          footer={false}
        >
          <Form
            className="risk-popup"
          >
            <div>
              <p className="mb-16">This will sync the commission fields
                across all the customers and templates.
                Are you sure you want to proceed?</p>
            </div>
            <Form.Item className="text-right mb-0">
              <Button
                type="primary"
                className="primary-btn cancel-btn reject-btn"
                onClick={handleModalCloseCreidit}
              >
                Back
              </Button>
              <Button
                type="primary"
                className="primary-btn ml-8"
                htmlType="submit"
                onClick={handleSyncConfirm}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="text-right mt-16 mb-16">
          <Button
            type="primary"
            className="primary-btn cancel-btn"
            style={{ margin: "0 8px" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig?.userProfileInfo,
  };
};
export default connect(connectStateToProps)(AddCommissionsView);