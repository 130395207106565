import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Collapse,
  Alert,
  Button,
  Select,
  Tooltip,
  Modal,
  Form,
  Input,
  message,
  Tabs,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import defaultUser from "../../assets/images/defaultuser.jpg";
import moment from "moment";
import Loader from "../loader.component";
import { handleMembers } from "../../reducers/customerReducer";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import {
  saveCustomer, getCustomer, getCaseLu, getRefferalCode, getCommissionsLU, getAvailableBanks, getCredits, getCtrlOwnerShipDetails as getCtrlOwnerShipDetailsApi,
  getCompanyStructureApi, getCompanyOfficersApi, getCompanyCheckApi, getCompanySignificantPersonsApi
} from "./api";
import apiCalls from "../../api/apiCalls";
import SharedInfo from "../shared/sharedInfo";
import Info from "../info/Info";
import { publishShowActions } from "../grid.component/subscribir";
import { validateContentRule } from "../../utils/custom.validator";
import { redirectToCaseView } from "../../utils/caseRediractions";
import { Link } from "react-router-dom";
import CustomerBatchFee from "./customerBatchFee";
import CustomerAddressBook from "./customerAddressBook";
import SuisseBaseCredits from "../../utils/suissebaseCredits";
import BackupAddress from "./backupAddress";
import CopyToClipboard from "react-copy-to-clipboard";
import KYB from "./KYB";
import KYC from "./KYC";
import config from "../../config";
import { getCountryStateLu } from "../deposit.component/api";
import WalletsDetials from "./wallets.component";
import Transactions from "./transactions.component";
import PartnerDetails from "./partner.component.js";
import ScreeningDetails from "./screening.component";
import CustomerDeductions from './customerdeduction.js'
import { setBreadcrumb } from "../../reducers/breadcrumbReducer.js";

const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
class CustomerSummary extends Component {
  constructor (props) {
    super(props);
    this.state = {
      btnDisabled: false,
      isLoading: false,
      customerObj: {
        id: [],
        riskLevel: null,
        amountThreshold: null,
        accumulatedDeposit: null
      },
      cryptoModal: false,
      loading: false,
      fiat: true,
      errorMsg: null,
      type: this.props.match.params.type,
      assignedTo: [],
      userName: null,
      customerdata: {},
      assignedToObj: {},
      accountManagerObject: [],
      getData: {},
      refferalName: "",
      isInvalidPartnerReferralCode: false,
      disable: false,
      popupSymbole: false,
      crossCheckIcon: false,
      loader: false,
      activeTab: (this.props?.match?.params?.type === "fees" || this.props?.match?.params?.type === "batchedit")
        ? "7" : this.props?.match?.params?.tab || "1",
      tabclose: false,
      infoDetails: false,
      isModalOpen: false,
      suisseBaseCreditvisible: false, commissionsLu: [], banksLu: [], templateObj: { customerId: '', commissionId: '', modifiedBy: '', AvailableBanks: [] }, banksModal: false, bankLoader: false, bankerrorMsg: null, commissionTempId: '', defaultBanks: [],
      isWalletModal: false,
      referralError: null,
      isBankModelClose: true,
      credit: null,
      isWalletLoader: false,
      isWalletError: null,
      error: null,
      countryData: [],
      ctrlOwnerShipDetails: [],
      companyStructureDetails: {},
      companyOfficers: [],
      companyCheck: {},
      companySignificantPersons: []
    };

    this.gridRef = React.createRef();
    this.formRef = React.createRef();
    this.useDivRef = React.createRef();
  }
  DivRef = React.createRef();
  componentDidMount = () => {
    this.useDivRef.current.scrollIntoView(0, 0);
    publishShowActions(false);
    let selectedId = this.props.match.params.id;
    this.props.fetchUserData(selectedId);
    this.getCountryLU();
    this.loadCustomers();
  };
  getCtrlOwnerShipDetails = async () => {
    let res = await getCtrlOwnerShipDetailsApi(this.props.match.params.id);

    if (res.ok) {
      this.setState({ ...this.state, ctrlOwnerShipDetails: res.data })
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(res) })
    }
  }

  getCompanyStructureDetails = async () => {
    let response = await getCompanyStructureApi(this.props.match.params.id)

    if (response.ok) {
      this.setState({ ...this.state, companyStructureDetails: response.data })
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
    }
  }

  getcompanyOfficers = async () => {
    let response = await getCompanyOfficersApi(this.props.match.params.id)

    if (response.ok) {
      this.setState({ ...this.state, companyOfficers: response.data })
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
    }
  }
  getCompanyCheck = async () => {
    let response = await getCompanyCheckApi(this.props.match.params.id)
    if (response.ok) {
      this.setState({ ...this.state, companyCheck: response.data })
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })

    }
  }

  getCompanySignificantPersons = async () => {
    let response = await getCompanySignificantPersonsApi(this.props.match.params.id)
    if (response.ok) {
      this.setState({ ...this.state, companySignificantPersons: response.data })
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })

    }
  }



  getCountryLU = async () => {
    let res = await getCountryStateLu();
    if (res.ok) {
      this.setState({ ...this.state, countryData: res.data });
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(res) })
    }
  }
  getCredit = async () => {
    let response = await getCredits(this.props.match.params.id);
    if (response.ok) {
      this.setState({
        ...this.state,
        credit: response.data.credit,
      });
    }
    else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
    }
  }
  loadCommissionsLu = async (customersData) => {
    let response = await getCommissionsLU(this.props.match.params.id);
    if (response.ok) {
      this.setState({
        ...this.state,
        commissionsLu: response.data,
      });
      for (var k in response.data) {
        if (response.data[k].templateName == customersData?.commissionTemplate) {
          this.getBanksData(response.data[k].id)
          this.setState({ commissionTempId: response.data[k].id })
        }
      }
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response) })
    }
  }
  changeTab = (activeKey) => {
    if (activeKey === "1") {
      this.loadCustomers();
    }
    else if (activeKey === "10") {
      this.getCredit();
      this.getCountryLU();
      this.getCtrlOwnerShipDetails();
      this.getCompanyStructureDetails();
      this.getcompanyOfficers();
      this.getCompanySignificantPersons();
      this.getCompanyCheck()
    }
    this.setState({
      activeTab: activeKey,
      errorMsg: null
    });
  };

  loadCustomers = async () => {
    this.setState({ ...this.state, isLoading: true });
    let selectedId = this.props.match.params.id;
    let response = await getCustomer(selectedId);
    if (response.ok) {
      const val = response.data.id;
      this.props.dispatch(
        setBreadcrumb({ key: "/customers/details/" + val, val: response.data?.memberName })
      );
      let obj = response.data;
      this.setState({
        ...this.state,
        customerdata: response.data,
        credit: response.data?.credit,
        assignedToObj: {
          id: obj.accountManager,
          assignedTo: obj.accountManagerName
        },
        crossCheckIcon: false,
        errorMsg: null,
        isLoading: false
      });
      if (response.data.partnerReferralCode !== null) {
        this.getRefferallCode(response.data?.partnerReferralCode);
        this.setState({ disable: true });
      }
      this.formRef.current?.setFieldsValue({ ...response?.data });

      this.loadCommissionsLu(response.data);
      this.getdefaultBanks(response.data.availableBanks)
    } else {
      this.setState({ ...this.state, errorMsg: apiCalls.isErrorDispaly(response), isLoading: false })
    }
  };

  transView = (e) => {
    const items = e.dataItem;
    const val = items.id;
    window.open("/transaction/" + val, "_blank");
    this.props.fetchBreadCrumb({
      key: "/transaction/" + val,
      val: items.walletName
    });
  };

  transectionColumn = [
    {
      field: "accountNumber",
      title: "Account Number",
      filter: true,
      width: 300,
      customCell: (props) => (
        <td>
          {" "}
          <div className="gridLink" onClick={() => this.accountView(props)}>
            {props.dataItem.accountNumber}
          </div>
        </td>
      )
    },

    {
      field: "routingNumber",
      title: "Routing Number",
      filter: true,
      dataType: "number",
      filterType: "numeric",
      width: 250
    },
    { field: "type", title: "Type", filter: true, width: 100 },
    { field: "isVerified", title: "Is Verified", filter: true, width: 250 },
    { field: "currency", title: "Currency", filter: true, width: 250 },
    {
      field: "availableBalance",
      title: "Available Balance",
      filter: true,
      width: 250
    },
    {
      field: "sponsorBankName",
      title: "Sponsor Bank Name",
      filter: true,
      width: 250
    },
    { field: "pendingDebit", title: "Pending Debit", filter: true, width: 250 },
    {
      field: "pendingCredit",
      title: "Pending Credit",
      filter: true,
      width: 250
    },
    {
      field: "createdPersonId",
      title: "Created Person Id",
      filter: true,
      width: 250
    }
  ];

  transferSummary = [
    {
      field: "createdAt",
      title: "Date",
      filter: true,
      width: 200,
      customCell: (props) => (
        <td>
          {moment(new Date(props.dataItem.createdAt)).format(
            config?.dateFormates?.dateTimeFormate
          )}
        </td>
      )
    },

    {
      field: "baneficiaryName",
      title: "Name",
      filter: true,
      width: 200
    },

    {
      field: "currency",
      title: "Currency",
      filter: true,
      width: 150
    },
    {
      field: "credit",
      title: "Transfer Amount",
      filter: true,
      width: 180
    },
    {
      field: "beneficiaryAccountNumber",
      title: "Account Number ",
      filter: true,
      width: 180
    },
    {
      field: "beneficiaryRoutingNumber",
      title: "Routing Number",
      filter: true,
      width: 250
    },

    {
      field: "status",
      title: "Status",
      filter: true,
      width: 180
    }
  ];

  success = (msg, duration) => {
    message.destroy();
    message.success({
      content: msg,
      className: "custom-msg",
      duration: 4
    });
  };
  handleSearch = (e, type) => {
    let sval = e.target.value;
    if (sval != null && sval.length > 2) {
      if (type === "AssignedTo") {
        this.fetchAccountManagerLu(sval);
      }
    }
  };

  handleChange = (val, type) => {
    if (type === "AssignedTo") {
      const obj = this.state.assignedTo?.find((item) => item.id === val);
      this.setState({ ...this.state, assignedToObj: obj });
    }
  };
  fetchAccountManagerLu = async (searchName, id) => {
    let response = await getCaseLu(searchName);
    if (response.ok) {
      const obj = this.state.assignedTo?.find((item) => item.id === id);
      this.setState({
        ...this.state,
        assignedTo: response.data,
        getData: obj,
        isLoading: false,
        errorMsg: null
      });
    } else {
      this.setState({ ...this.state, isLoading: false, errorMsg: apiCalls.isErrorDispaly(response) });
    }
  };

  handleRiskLevel(val) {
    this.state.customerObj["riskLevel"] = val;
  }
  handleIntroducer(val) {
    this.state.customerObj["introducer"] = val;

  }
  handleisTopUp(val) {
    this.state.customerObj["isTopUp"] = val;

  }
  checkVerificationMsgs = (values) => {
    let count = this.state.banksLu.filter(val => val.status === 'Active').length;
    let objval = values;
    if (count == 0) {
      this.setState({ ...this.state, loading: false, btnDisabled: false, errorMsg: 'Please select at least one bank', isLoading: false });
      this.useDivRef.current.scrollIntoView(0, 0);
      return false;
    }
    if (parseFloat(typeof objval.amountThreshold == "string" ? objval.amountThreshold.replace(/,/g, "") : objval.amountThreshold) <= 0) {
      this.setState({ ...this.state, loading: false, isLoading: false, btnDisabled: false, errorMsg: "Amount must be greater than zero." });
      this.useDivRef.current.scrollIntoView(0, 0);
      return false;
    }
    if (parseFloat(typeof objval.accumulatedDeposit == "string" ? objval.accumulatedDeposit.replace(/,/g, "") : objval.accumulatedDeposit) <= 0) {
      this.setState({ ...this.state, loading: false, btnDisabled: false, errorMsg: "Amount must be greater than zero.", isLoading: false });
      this.useDivRef.current.scrollIntoView(0, 0);
      return false;
    }
    if (count == 0) {
      this.setState({ ...this.state, loading: false, btnDisabled: false, errorMsg: 'Please select at least one bank', referralError: null, isLoading: false });
      window.scrollTo(0, 0);
      return false;
    }
    return true;
  }
  saveCustomerInfo = async (values) => {
    this.setState({
      loading: true,
      btnDisabled: true,
      errorMsg: null,
      isLoading: true,
      referralError: null
    });
    const updatedBanksLu = this.state.banksLu.map(items => {
      const isActive = this.state.defaultBanks.some(item => item === items.bankName);
      return {
        ...items,
        status: isActive ? 'Active' : items.status
      };
    });

    this.setState({
      banksLu: updatedBanksLu
    });
    if (!this.checkVerificationMsgs(values)) {
      return;
    }
    values.id = this.props.match.params.id;
    values.info = JSON.stringify(this.props.trackAuditLogData);
    values.accountManager = this.state.assignedToObj.id;
    values.accountManagerName = this.state.assignedToObj.assignedTo;
    values.modifiedBy = values.id ? this.props.userConfig?.userName : null;
    values.CommissionId = this.state.commissionTempId ? this.state.commissionTempId : values.CommissionId;
    values.createdBy = this.props.userConfig?.userName;
    values.commissionTemplate = this.state.customerdata.commissionTemplate;
    values.availableBanks = this.state.banksLu;
    values.amount = this.state.credit;

    values.sourceOfClient = values.sourceOfClient == "" ? null : values.sourceOfClient;
    if (this.state.isInvalidPartnerReferralCode === false) {
      let response = await saveCustomer(values);
      if (response.ok) {
        this.setState({
          ...this.state,
          disable: true,
          btnDisabled: false,
          errorMsg: null,
          referralError: null,
          loading: false,
          isLoading: false
        });
        this.useDivRef.current.scrollIntoView(0, 0);
        this.success("Customer details saved successfully");
        this.props.history.push("/customers");
      } else {
        this.setState({
          ...this.state,
          errorMsg: apiCalls.isErrorDispaly(response),
          loading: false,
          isLoading: false,
          btnDisabled: false
        });
        this.useDivRef.current.scrollIntoView(0, 0);
        window.scrollTo(0, 0);
      }
    } else {
      this.setState({
        ...this.state,
        referralError: "Invalid referral party",
        loading: false,
        isLoading: false,
        btnDisabled: false
      });
    }
  };

  numberValidator = async function (rule, value, callback) {
    if (value) {
      if (typeof value === "number") {
        value = value.toString();
      }
      if (
        (value.indexOf(".") > -1 && value.split(".")[0].length >= 11) ||
        (value.indexOf(".") < 0 && value.length >= 11)
      ) {
        throw new Error("Amount exceeded");
      } else {
        callback();
      }
    }
  };

  handleDepositTabs = (e) => {
    this.setState({
      ...this.state,
      fiat: e.target.value === 1
    });
  };

  redirectCaseView = (caseData) => {
    redirectToCaseView(caseData, this.props);
  };
  isUrlValid(userInput) {
    var res = userInput.match(
      /(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return !!res;
  }
  isSumSubUrlValid(userInput) {
    var res = userInput.match(
      /(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res === null) return false;
    else if (userInput.startsWith("https://cockpit.sumsub.com")) {
      return true;
    } else {
      return false;
    }
  }
  getRefferallCode = async (id) => {
    if (!id == " ") {
      let response = await getRefferalCode(id, this.props.match.params.id);
      if (response.ok) {
        this.setState({
          refferalName: response.data.name,
          popupSymbole: true,
          btnDisabled: true,
          errorMsg: null,
          referralError: null,
          crossCheckIcon: false,
          isInvalidPartnerReferralCode: false,
          isLoading: false
        });
      } else {
        this.setState({
          refferalName: null,
          referralError: "Invalid referral party",
          crossCheckIcon: true,
          popupSymbole: false,
          isInvalidPartnerReferralCode: true,
          isLoading: false
        });
      }
    } else {
      this.setState({
        ...this.state,
        popupSymbole: false,
        crossCheckIcon: false,
        errorMsg: null,
        referralError: null,
        isInvalidPartnerReferralCode: false,
        isLoading: false
      });
    }
  };

  antIcon = () => {
    <LoadingOutlined
      style={{ fontSize: 18, color: "#fff", marginRight: "16px" }}
      spin
    />;
  };
  handleSuisseBaseCreditModal = () => {
    this.setState({ ...this.state, suisseBaseCreditvisible: true })
  }
  handleSuissecreditModalClose = (amount) => {
    this.setState({ ...this.state, suisseBaseCreditvisible: false, })
    if (amount) {
      this.getCredit();
    }
  }
  handleBanks = async (value) => {
    this.state.customerdata.commissionTemplate = value;
    this.setState({ customerdata: this.state.customerdata, errorMsg: null, defaultBanks: [] })
    let { commissionsLu } = this.state;
    for (var k in commissionsLu) {
      if (commissionsLu[k].templateName == value) {
        this.getBanksData(commissionsLu[k].id); this.setState({ commissionTempId: commissionsLu[k].id })
      }
    }
  }
  getBanksData = async (templateId) => {
    let { templateObj } = this.state;
    templateObj.commissionId = templateId
    let response = await getAvailableBanks(this.props.match.params.id, templateId);
    if (response.ok) {
      this.setState({
        ...this.state,
        banksLu: response.data, templateObj: templateObj, commissionTempId: templateId
      });
      this.getdefaultBanks(response.data)
    } else {
      this.setState({
        ...this.state,
        errorMsg: apiCalls.isErrorDispaly(response)
      });
    }
  }
  openBankspopUp = () => {
    if (!this.state.customerdata.commissionTemplate) {
      this.setState({
        ...this.state,
        errorMsg: 'Please select commission template'
      });
      window.scrollTo(0, 0)
    } else {
      const updatedBanksLu = this.state.banksLu.map((items) => {
        const newItem = { ...items };
        this.state.defaultBanks.forEach((item) => {
          if (item === items.bankName) {
            newItem.status = 'Active';
          }
        });
        return newItem;
      });
      this.setState({
        ...this.state,
        banksModal: true,
        bankerrorMsg: null,
        banksLu: updatedBanksLu,
      });
    }
  }
  checkBanks = (bank) => {
    let { banksLu } = this.state;
    for (var k in banksLu) {
      if (banksLu[k].id == bank.id) {
        banksLu[k].status = bank.status == 'Active' ? 'Inactive' : 'Active'
      }
    }
    this.setState({ ...this.state, banksLu: banksLu, bankerrorMsg: null })
  }
  saveBanks = async () => {
    let count = this.state.banksLu.filter(val => val.status === 'Active').length;
    if (count == 0) {
      this.setState({
        ...this.state,
        loading: false,
        btnDisabled: false,
        bankerrorMsg: 'Please select at least one bank',
        isLoading: false
      });
    }
    else {
      this.setState({
        ...this.state,
        bankerrorMsg: null,
        bankLoader: false, banksModal: false,
        banksLu: this.state.banksLu
      });
      this.getdefaultBanks(this.state.banksLu)
    }
  }
  handleBankModalClose = () => {
    let updatedBanksLu = [];
    if (this.state.defaultBanks.length !== 0) {
      updatedBanksLu = this.state.banksLu.map(items => {
        const isActive = this.state.defaultBanks.some(item => item === items.bankName);
        return {
          ...items,
          status: isActive ? 'Active' : 'Inactive'
        };
      });
    } else {
      updatedBanksLu = this.state.banksLu.map(items => ({
        ...items,
        status: 'Inactive'
      }));
    }

    this.setState({
      banksLu: updatedBanksLu,
      bankLoader: false,
      banksModal: false,
      isBankModelClose: false
    });
  };

  validateMinLength = (_, value) => {
    if (value) {
      if (value.length < 100 || value.length > 2000) {
        return Promise.reject(
          "Minimum length must be 100 characters."
        );
      }
      else {
        return validateContentRule(_, value)
      }
    }
    return Promise.resolve();
  }
  getdefaultBanks = (availableBanks) => {
    let data = [];
    for (let i = 0; i < availableBanks?.length; i++) {
      if (availableBanks[i].status == 'Active') {
        data.push(availableBanks[i].bankName);
      }
    }
    this.setState({ defaultBanks: data })
  }
  GotoProfileEdit = () => {
    this.props.history.push(`/customers/details/${this.props.match?.params?.id}/${this.props.match?.state ? this.props.match?.state : ""}`)
  }
  isCheckEdit = () => {
    let editCheck = this.props.permissions?.[(this.props?.permissions?.currentScreenTabId) || (this.props?.permissions?.currentScreenId)]?.filter((item) => (item.permissionName === "edit")).map((item) => (item.values))
    return editCheck[0]
  }

  getRenderCustomerValue = (customervalue) => {
    if (customervalue === true) {
      return "True"
    }
    else if (customervalue === false) {
      return "False"
    }
    else {
      return customervalue || "-"

    }
  }
  renderReferralPartyField = (referrallableClass) => (
    <Form.Item
      name="partnerReferralCode"
      label="Referral Party"
      className="input-label cust-inputlabel-style mb-0"
    >
      <Input
        style={{ position: "relative" }}
        placeholder="Referral Party"
        className={referrallableClass}
        maxLength={250}
        disabled={
          this.state.type === "disabled" ||
          this.state.type === "view" ||
          this.state.disable ||
          this.state.customerdata.status ===
          "Inactive"
        }
        onBlur={(val) =>
          this.getRefferallCode(
            val.currentTarget.value
          )
        }
      />
    </Form.Item>
  )
  renderKYCandKYBField = (kyckyblableclass, customer) => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      xxl={8}
      className="item"
    >
      {this.state.type === "view" && (<>
        <label className="kpi-label">KYC/KYB</label><br /></>
      )}
      {this.state.type === "view" ? (
        <>
          {customer?.sumSub && <CopyToClipboard text={customer?.sumSub} style={{ "cursor": "pointer", "align-items": "center" }}>
            <Paragraph Text copyable={{ tooltips: ["copy", "copied"] }} className="sumsub-url">
              <Link onClick={() => window.open(`${customer?.sumSub}`, '_blank')}>{customer?.sumSub}</Link></Paragraph>
          </CopyToClipboard> || <p className=" kpi-val fw-700 mb-0">-</p>}
        </>
      ) :
        <Form.Item
          name="sumSub"
          label="KYC/KYB"
          className={kyckyblableclass}
          rules={[
            {
              required: false,
              validator: (_, value) => {
                if (value) {
                  if (!this.isSumSubUrlValid(value)) {
                    return Promise.reject(
                      "Enter valid url"
                    );
                  }
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            placeholder="KYC/KYB"
            className="cust-input"
            maxLength={250}
            disabled={
              this.state.type === "disabled" ||
              this.state.customerdata.status ===
              "Inactive"
            }
          />
        </Form.Item>
      }</Col>
  )
  renderEngniteField = (eName, eLabel, egnyteFieldClass) => {
    const rules = eName === "Risk Score Document" ? [] : [
      {
        required: false,
        validator: (_, value) => {
          if (value) {
            if (!this.isUrlValid(value)) {
              return Promise.reject("Enter valid url");
            }
          }
          return Promise.resolve();
        }
      }
    ];

    return (
      <Form.Item
        name={eName}
        label={eLabel}
        className={egnyteFieldClass}
        rules={rules}
      >
        <Input
          placeholder={eLabel}
          className="cust-input"
          maxLength={250}
          disabled={
            this.state.type === "disabled" ||
            this.state.customerdata.status === "Inactive"
          }
        />
      </Form.Item>
    );
  }


  renderCommissionTemplateField = (customer) => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      xxl={8}
      className="item"
    >
      {this.state.type === "view" ? (
        <>
          <label className="kpi-label">
            Commission Template
          </label>
          <p className=" kpi-val fw-700 mb-0">
            {this.getRenderCustomerValue(customer?.commissionTemplate)}
          </p>
        </>
      ) :
        <Form.Item
          name="commissionTemplate"
          className="input-label cust-inputlabel-style"
          label="Commission Template"
          rules={[
            {
              required: true,
              message: "Is required"
            }
          ]}
        >
          <Select
            className="cust-input"
            onChange={(e) => this.handleBanks(e)}
            placeholder="Select Commission Template"
            disabled={
              this.state.type === "disabled" ||
              this.state.customerdata.status ===
              "Inactive"
            }
          >
            {this.state.commissionsLu.map((item) => <Option key={item.id} value={item.templateName}>{item.templateName}</Option>)}

          </Select>
        </Form.Item>}</Col>
  )
  renderRiskLevelField = (customer) => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      xxl={8}
      className={"item"}
    >
      {this.state.type === "view" ? (
        <>
          <label className="kpi-label">
            Risk Level
          </label>
          <p className=" kpi-val fw-700 mb-0">
            {this.getRenderCustomerValue(customer?.riskLevel)}
          </p>
        </>
      ) :
        (<Form.Item
          name="riskLevel"
          className="input-label cust-inputlabel-style"
          label="Risk Level"
          rules={[
            {
              required: true,
              message: "Is required"
            }
          ]}
        >
          <Select
            className="cust-input"
            onChange={(e) => this.handleRiskLevel(e)}
            placeholder="Select Risk Level"
            disabled={
              this.state.type === "disabled" ||
              this.state.customerdata.status ===
              "Inactive"
            }
          >
            <Option value="Low Risk">Low Risk</Option>
            <Option value="Medium Risk">
              Medium Risk
            </Option>
            <Option value="High Risk">
              High Risk
            </Option>
          </Select>
        </Form.Item>)}
    </Col>
  )
  renderAccountManagerField = (customer) => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      xxl={8}
      className="item"
    >
      {this.state.type === "view" ? (
        <>
          <label className="kpi-label">
            Account Manager
          </label>
          <p className=" kpi-val fw-700 mb-0">
            {this.getRenderCustomerValue(customer?.accountManagerName)}
          </p>
        </>
      ) : (<Form.Item
        name="accountManagerName"
        label="Account Manager"
        className="input-label cust-inputlabel-style"
        rules={[
          {
            required: true,
            message: "Is required"
          }
        ]}
      >
        <Select
          showSearch
          className="cust-input"
          onKeyUp={(e) =>
            this.handleSearch(e, "AssignedTo")
          }
          onChange={(e) =>
            this.handleChange(e, "AssignedTo")
          }
          placeholder="Select Account Manager"
          optionFilterProp="children"
          disabled={
            this.state.type === "disabled" ||
            this.state.customerdata.status ===
            "Inactive"
          }
        >
          {this.state.assignedTo?.map((assign) => (
            <Option key={assign.id} value={assign.id}>
              {assign.assignedTo}
            </Option>
          ))}
        </Select>
      </Form.Item>)}
    </Col>
  )
  renderAvailableBanksField = (customer) => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={24}
      xxl={24}
      className="item available-banks"
    >
      {this.state.type === "view" ? (
        <>
          <label className="kpi-label">
            Available Banks
          </label>


          <p className=" kpi-val fw-700 mb-0">
            {this.getRenderCustomerValue(this.state.defaultBanks?.toString())}
          </p>
        </>
      ) : <Form.Item
        name="availableBanks"
        className="input-label cust-inputlabel-style"
        label={<label> Available Banks</label>}
        required
      >
        {this.state.customerdata && <Select
          className="cust-input"
          placeholder="Available Banks"
          disabled
          defaultValue={this.state.defaultBanks} mode="multiple"
          value={this.state.defaultBanks}>

        </Select>}
        {!(this.state.customerdata?.customerState === "Rejected"
          || this.state.customerdata?.customerState === "Closed") &&
          <span onClick={() => this.openBankspopUp()} className="icon md edit banks-edit"></span>
        }
      </Form.Item>
      }
    </Col>
  )
  renderAmoutThreshoudField = (customer) => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      xxl={8}
      className="item"
    >
      {this.state.type === "view" && (
        <label className="kpi-label">
          Amount Threshold
        </label>
      )}

      {this.state.type === "view" ? (
        <div className="kpi-val fw-700">
          <NumberFormat
            value={this.getRenderCustomerValue(customer?.amountThreshold)}
            displayType={"text"}
            thousandSeparator={true}
          />
        </div>
      ) : (
        <Form.Item
          name="amountThreshold"
          className="input-label cust-inputlabel-style"
          rules={[
            {
              required: true,
              message: "Is required"
            },
            {
              type: "number",
              validator: this.numberValidator
            }
          ]}
          label="Amount Threshold"
        >
          <NumberFormat
            disabled={
              this.state.type === "disabled" ||
              this.state.customerdata.status ===
              "Inactive"
            }
            className="cust-input"
            placeholder="Amount Threshold"
            customInput={Input}
            thousandSeparator={true}
            prefix={""}
            decimalSeparator={false}
            style={{ width: "100%" }}
            allowNegative={false}
          />
        </Form.Item>
      )}
    </Col>
  )
  renderAccumulatedDepositField = (customer) => (
    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
      {this.state.type === "view" && (
        <label className="kpi-label">
          Accumulated Deposit
        </label>
      )}

      {this.state.type === "view" ? (
        <div className="kpi-val fw-700">
          <NumberFormat
            value={this.getRenderCustomerValue(customer?.accumulatedDeposit)}
            displayType={"text"}
            thousandSeparator={true}
          />
        </div>
      ) : (
        <Form.Item
          name="accumulatedDeposit"
          className="input-label cust-inputlabel-style"
          rules={[
            {
              required: true,
              message: "Is required"
            },
            {
              type: "number",
              validator: this.numberValidator
            }
          ]}
          label="Accumulated Deposit"
        >
          <NumberFormat
            disabled={
              this.state.type === "disabled" ||
              this.state.customerdata.status ===
              "Inactive"
            }
            className="cust-input"
            placeholder="Accumulated Deposit"
            customInput={Input}
            thousandSeparator={true}
            decimalSeparator={false}
            prefix={""}
            allowNegative={false}
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}
    </Col>
  )

  renderSuissebaseCreditsField = (customer) => {
    return this.state.type === "view" ? (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={8}
        xxl={8}
        className="item"
      >
        <label className="kpi-label">
          SuisseBase Credits
        </label>
        <p className=" kpi-val fw-700 mb-0">
          {this.getRenderCustomerValue(customer?.credit?.toLocaleString())}
        </p>
      </Col>
    ) : (<Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={8}
      xxl={8}
      className="item"
    >

      <Form.Item
        name="credit"
        label="SuisseBase Credits"
        className="input-label cust-inputlabel-style"
      >
        <Input
          placeholder="SuisseBase Credits"
          className="cust-input"
          maxLength={250}
          disabled
          defaultValue={this.state.credit?.toLocaleString()} value={this.state.credit?.toLocaleString()} />
        {!(this.state.customer?.customerState === "Rejected"
          || customer?.customerState === "Closed") &&
          <span onClick={this.handleSuisseBaseCreditModal} className="icon md edit banks-edit"></span>}
      </Form.Item>
      {this.state.suisseBaseCreditvisible && <SuisseBaseCredits showModal={this.state.suisseBaseCreditvisible} handleModalCloseCreidit={(amnt) => this.handleSuissecreditModalClose(amnt)} isShowLogs={true} customerId={this.props.match.params.id} availableBlc={this.state.credit} />}
    </Col>)
  }
  renderSourceOfClientField = (customer) => {

    return this.state.type !== "view" && (
      <Form.Item
        name="sourceOfClient"
        className="input-label cust-inputlabel-style"
        label={<label>Remarks</label>}

        rules={[{
          validator: this.validateMinLength
        },
        ]}
      >
        <TextArea
          placeholder="Remarks"
          className="input-label cust-inputlabel-style"
          rows={4}
          disabled={this.state.type === "disabled" || (customer?.state === "Approved" || customer?.state === "Rejected") || customer?.isPersonal}
          maxLength={2000}
          showCount
        />
      </Form.Item>
    )
  }

  renderRejectMsgDisplay = (customer) => (
    customer?.customerState === "Rejected" ||
    customer?.customerState === "Closed") && (
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={8}
        xxl={8}
        className="item"
      >
        <label className="kpi-label">
          {customer?.customerState === "Rejected"
            ? "Reason For Rejection"
            : "Reason For Close"}
        </label>
        <p className=" kpi-val fw-700 mb-0">
          {customer?.customerState
            ? customer?.customerRemarks
            : "-"}
        </p>
      </Col>
    )

  renderCommonBussinessAccDetails = (customer) => (
    customer?.accountType === "Business" && (
      <Row className="cust-row-design">
        {this.renderAvailableBanksField(customer)}
        {this.renderSuissebaseCreditsField(customer)}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item"
        >
          <div className="p-relative">
            {this.state.type === "view" ? (
              <>
                <label className="kpi-label">
                  Referral Party
                </label>
                <p className="kpi-val fw-700 mb-0">
                  {this.getRenderCustomerValue(customer?.partnerReferralCode)}
                </p>
              </>
            ) : (
              <>
                {this.renderReferralPartyField("cust-input cust-inputlabel-style")}
                {this.state.referralError && <span className="referral-error"> {this.state.referralError}</span>}
              </>
            )}

            {this.state.type !== "view" && <span
              className="referralparty-icon"

            >
              {" "}
              {this.state.popupSymbole === true ? (
                <span className="icon md greenCheck" />
              ) : (
                ""
              )}
              {this.state.crossCheckIcon === true && (
                <span className="icon md redclose" />
              )}
              {this.state.crossCheckIcon === false && (
                <span />
              )}
            </span>
            }</div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item risk-compliance"
        >
          <label className="kpi-label">
            Referral Name
          </label>
          <p className="kpi-val  fw-700  mb-0">
            {this.state.popupSymbole ? <> {this.state.refferalName}{" "}</> : "-"}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="item risk-compliance"
        >
          {this.state.type === "view" && (<>
            <label className="kpi-label">
              Remarks
            </label>
            <p className="kpi-val  fw-700  mb-0 text-word-wrap">
              {this.getRenderCustomerValue(customer?.sourceOfClient)}

            </p>
          </>)}
          {this.renderSourceOfClientField(customer)}
        </Col>
      </Row>)
  )
  renderCommonPerDetails = (customer) => (
    <Row className="cust-row-design">
      <>
        {this.renderAvailableBanksField(customer)}
        {this.renderSuissebaseCreditsField(customer)}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item"
        >
          <div>
            {this.state.type === "view" && (
              <>

                <label className="kpi-label">
                  Referral Party
                </label>
                <p className="kpi-val fw-700 mb-0">
                  {this.getRenderCustomerValue(customer?.partnerReferralCode)}
                </p>
              </>
            )}
          </div>
          <div className="p-relative">
            {this.state.type !== "view" && (
              <>
                {this.renderReferralPartyField("cust-input")}
                {this.state.referralError && <span className="referral-error"> {this.state.referralError}</span>}
                <span
                  className="referralparty-icon"
                >
                  {" "}
                  {this.state.popupSymbole === true ? (
                    <span className="icon md greenCheck" />
                  ) : (
                    " "
                  )}
                  {this.state.crossCheckIcon === true && (
                    <span className="icon md redclose" />
                  )}
                </span>
              </>
            )}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item risk-compliance"
        >
          <label className="kpi-label">
            Referral Name
          </label>
          <p className="kpi-val  fw-700  mb-0">
            {this.state.popupSymbole === true ? <> {this.state.refferalName}{" "}</> : "-"}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="item risk-compliance"
        >
          {this.state.type === "view" && (<>
            <label className="kpi-label">
              Remarks
            </label>
            <p className="kpi-val  fw-700  mb-0 text-word-wrap">
              {this.getRenderCustomerValue(customer?.sourceOfClient)}
            </p>
          </>)}
          {this.renderSourceOfClientField(customer)}


        </Col>
      </>
    </Row>
  )
  renderPersonalField = () => {
    const { data: customer } = this.props.customerStore?.customerData;

    return <>
      <Row className="cust-row-design">
        <>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            {this.state.type === "view" ? (
              <>
                <label className="kpi-label">
                  First Name
                </label>
                <p className="kpi-val fw-700 mb-0">
                  {this.getRenderCustomerValue(customer?.firstName)}
                </p>
              </>
            ) : (
              <Form.Item
                name="firstName"
                label="First Name"
                className="input-label cust-inputlabel-style"
                rules={[
                  {
                    required: true,
                    message: "Is required",
                    whitespace: true
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder="First Name"
                  className="cust-input"
                  maxLength={50}
                  disabled={
                    this.state.type === "disabled" ||
                    this.state.customerdata.status ===
                    "Inactive"
                  }
                />
              </Form.Item>
            )}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            {this.state.type === "view" ? (
              <>
                <label className="kpi-label">
                  Last Name
                </label>
                <p className="kpi-val fw-700 mb-0">
                  {this.getRenderCustomerValue(customer?.lastName)}
                </p>
              </>
            ) : (
              <Form.Item
                name="lastName"
                label="Last Name"
                className="input-label customer-lastname"
                rules={[
                  {
                    required: true,
                    message: "Is required",
                    whitespace: true
                  },
                  {
                    validator: validateContentRule
                  }
                ]}
              >
                <Input
                  placeholder="Last Name"
                  className="cust-input"
                  maxLength={50}
                  disabled={
                    this.state.type === "disabled" ||
                    this.state.customerdata.status ===
                    "Inactive"
                  }
                />
              </Form.Item>
            )}
          </Col>
        </>

        {customer?.accountType === "Personal" && (<>

          {this.renderRiskLevelField(customer)}
          {this.renderAmoutThreshoudField(customer)}
          {this.renderAccumulatedDepositField(customer)}
          {this.renderAccountManagerField(customer)}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">
              {customer?.accountType === "Personal"
                ? "Country of Residence"
                : "Country of Business"}
            </label>
            <p className="kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.country)}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">Reg Date</label>
            <p className=" kpi-val fw-700 mb-0">
              {moment(customer?.createdDate).format(
                config?.dateFormates?.dateFormate
              )}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">State</label>
            <p className=" kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.customerState)}
            </p>
          </Col>
          {this.renderRejectMsgDisplay(customer)}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">Account Type</label>
            <p className=" kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.accountType)}
            </p>
          </Col>
        </>)}
        {customer?.accountType === "Personal" && (
          <>
            {" "}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
              xxl={8}
              className="item risk-compliance"
            >
              <label className="kpi-label">
                KYC Compliance
              </label>
              <p className="kpi-val  fw-700  mb-0">
                {this.getRenderCustomerValue(customer?.kycCompleted)}
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
              xxl={8}
              className="item"
            >
              <label className="kpi-label">
                Vault Account ID
              </label>
              {customer?.vaultAccountId ? (
                <Paragraph
                  className="kpi-val fw-700 mb-0"
                  copyable
                >
                  {this.getRenderCustomerValue(customer?.vaultAccountId)}
                </Paragraph>
              ) : (
                <Paragraph className="kpi-val fw-700 mb-0">
                  {"-"}
                </Paragraph>
              )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
              xxl={8}
              className="item"
            >
              <label className="kpi-label">Account Approval Date</label>
              <p className="kpi-val fw-700 mb-0">{customer?.approvedDate === null ? "-" : moment(customer?.approvedDate).format(config?.dateFormates?.dateFormate)}</p>
            </Col>
          </>)}
      </Row>

      <Row className="cust-row-design">
        <>
          {this.state.type === "view" &&
            customer?.caseIds !== null && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={8}
                xxl={8}
                className="item"
              >

                <>
                  <label className="kpi-label d-block">
                    Case Number
                  </label>
                  <span className="fw-600 fs-14">
                    {customer?.caseIds?.map((item) => (
                      <Link key={item?.id} className="c-pointer"
                        onClick={() =>
                          this.redirectCaseView(item)
                        }
                      >
                        {item.caseNumber}
                        <br />
                      </Link>
                    ))}
                  </span>
                </>

              </Col>
            )}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            {this.state.type === "view" && (
              <label className="kpi-label">Client Upload Egnyte</label>
            )}
            {this.state.type === "view" ? (
              <Paragraph
                className="kpi-val fw-700 mb-0"
                copyable={customer?.egnyte}
              >
                {this.getRenderCustomerValue(customer?.egnyte)}
              </Paragraph>
            ) : (
              this.renderEngniteField("egnyte", "Client Upload Egnyte", "input-label mb-0 cust-inputlabel-style")
            )}
          </Col>
          {this.renderKYCandKYBField("input-label cust-inputlabel-style", customer)}
          {this.renderCommissionTemplateField(customer)}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <>
              <label className="kpi-label">
                World Check
              </label>
              <p className=" kpi-val fw-700 mb-0">

                <Link onClick={() => window.open(`${process.env.REACT_APP_RISK_URL}`, '_blank')}>Link</Link>
              </p>
            </>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <>
              <label className="kpi-label">
                LEI Search
              </label>
              <p className=" kpi-val fw-700 mb-0">

                <Link onClick={() => window.open(`${process.env.REACT_APP_LIE_SEARCH}`, '_blank')}>Link</Link>
              </p>
            </>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            {this.state.type === "view" && (
              <label className="kpi-label">Risk Score Document</label>
            )}
            {this.state.type === "view" ? (
              <Paragraph
                className="kpi-val fw-700 mb-0"
                copyable={customer?.riskScoreDocument}
              >
                {this.getRenderCustomerValue(customer?.riskScoreDocument)}
              </Paragraph>
            ) : (
              this.renderEngniteField("riskScoreDocument", "Risk Score Document", "input-label mb-0 egnyte-label")
            )}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className={"item"}
          >
            {this.state.type === "view" ? (
              <>
                <label className="kpi-label">
                  Introducer
                </label>
                <p className=" kpi-val fw-700 mb-0">
                  {this.getRenderCustomerValue(customer?.introducer)}
                </p>
              </>
            ) :
              (<Form.Item
                name="introducer"
                className="input-label cust-inputlabel-style"
                label="Introducer"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  }
                ]}
              >
                <Select
                  className="cust-input"
                  onChange={(e) => this.handleIntroducer(e)}
                  placeholder="Select Introducer"
                  disabled={
                    this.state.type === "disabled" ||
                    this.state.customerdata.status ===
                    "Inactive"
                  }
                >
                  <Option value={true}>
                    True
                  </Option>
                  <Option value={false}>
                    False
                  </Option>
                </Select>
              </Form.Item>)}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className={"item"}
          >
            {this.state.type === "view" ? (
              <>
                <label className="kpi-label">
                  My Card
                </label>
                <p className=" kpi-val fw-700 mb-0">
                  {this.getRenderCustomerValue(customer?.isTopUp)}
                </p>
              </>
            ) :
              (<Form.Item
                name="isTopUp"
                className="input-label cust-inputlabel-style"
                label="My Card"
                rules={[
                  {
                    required: true,
                    message: "Is required"
                  }
                ]}
              >
                <Select
                  className="cust-input"
                  onChange={(e) => this.handleisTopUp(e)}
                  placeholder="Select My Card"
                  disabled={
                    this.state.type === "disabled" ||
                    this.state.customerdata.status ===
                    "Inactive"
                  }
                >
                  <Option value={true}>
                    True
                  </Option>
                  <Option value={false}>
                    False
                  </Option>
                </Select>
              </Form.Item>)}
          </Col>
        </>
      </Row></>

  }
  renderCustomerCommonFieldData = (customer) => {
    const countryOptions = this.state?.countryData?.map((item, indx) => (
      <Select.Option key={indx} value={item.name}>
        {item.name}
      </Select.Option>
    ));
    return <Row className="cust-row-design">
      {this.state.type === "view" ? (<Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
        <label className="kpi-label">
          Website
        </label>
        <p className="kpi-val  fw-700  mb-0 word-break">
          {this.getRenderCustomerValue(customer?.website)}
        </p>
      </Col>) : (
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
          {this.renderEngniteField("website", "Website", "input-label mb-0 cust-inputlabel-style")}
        </Col>
      )}
      {this.state.type === "view" ? (<Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
        <label className="kpi-label">
          Business Description
        </label>
        <p className="kpi-val  fw-700  mb-0">
          {this.getRenderCustomerValue(customer?.businessdescription)}
        </p>
      </Col>) : (
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
          <Form.Item
            name="businessdescription"
            className="input-label cust-inputlabel-style"
            label={<label>Business Description</label>}
            rules={[
              {
                validator: validateContentRule
              }
            ]}
          >
            <Input
              placeholder={"Business Description "}
              className="cust-input"
              maxLength={50}
              disabled={
                this.state.type === "disabled" ||
                this.state.customerdata.status ===
                "Inactive"
              }
            />
          </Form.Item></Col>
      )}
      {this.state.type === "view" ? (<Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
        <label className="kpi-label">
          Compliance Comments
        </label>
        <p className="kpi-val  fw-700  mb-0">
          {this.getRenderCustomerValue(customer?.compliancecomments)}
        </p>
      </Col>) : (
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item" >
          <Form.Item
            name="compliancecomments"
            className="input-label cust-inputlabel-style"
            label={<label>Compliance Comments</label>}
            rules={[
              {
                validator: validateContentRule
              }
            ]}
          >
            <Input
              placeholder={"Compliance Comments"}
              className="cust-input"
              maxLength={50}
              disabled={
                this.state.type === "disabled" ||
                this.state.customerdata.status ===
                "Inactive"
              }
            />
          </Form.Item></Col>
      )}
      {this.state.type === "view" ? (
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
          <label className="kpi-label">
            UBO Nationality
          </label>
          <p className="kpi-val fw-700 mb-0">
            {this.getRenderCustomerValue(customer?.ubOnationality)}
          </p>
        </Col>
      ) : (
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="item">
          <Form.Item
            name="ubOnationality"
            label="UBO Nationality"
            className="input-label"
            rules={[
              {
                whitespace: true
              }
            ]}
          >
            <Select
              showSearch
              placeholder="Select UBO Nationality"
              className={`cust-input w-80`}
              dropdownClassName="select-drpdwn"
              disabled={
                this.state.type === "disabled" ||
                this.state.customerdata.status ===
                "Inactive"
              }
            >
              {countryOptions}
            </Select>
          </Form.Item>
        </Col>
      )}
    </Row>
  }
  renderProfileData = () => {
    const { data: customer } = this.props?.customerStore?.customerData;
    return <Col
      xs={24}
      sm={24}
      md={24}
      lg={15}
      xl={18}
      xxl={19}
      className="view-right"
    >
      {customer?.accountType === "Personal" && this.renderPersonalField()}
      {customer?.accountType === "Personal" && this.renderCommonPerDetails(customer)}
      {customer?.accountType === "Business" && (<Row className="cust-row-design">
        <>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">
              Business Name
            </label>
            <p className=" kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.businessName)}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">
              {customer?.accountType === "Personal"
                ? "Country of Residence"
                : "Country of Business"}
            </label>
            <p className="kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.country)}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">Reg Date</label>
            <p className=" kpi-val fw-700 mb-0">
              {moment(customer?.createdDate).format(
                config?.dateFormates?.dateFormate
              )}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">State</label>
            <p className=" kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.customerState)}
            </p>
          </Col>
          {this.renderRejectMsgDisplay(customer)}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">
              KYB Compliance
            </label>
            <p className="kpi-val  fw-700  mb-0">
              {this.getRenderCustomerValue(customer?.kybCompleted)}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">
              Vault Account ID
            </label>
            {customer?.vaultAccountId ? (
              <Paragraph
                className="kpi-val fw-700 mb-0"
                copyable
              >
                {this.getRenderCustomerValue(customer?.vaultAccountId)}
              </Paragraph>
            ) : (
              <Paragraph className="kpi-val fw-700 mb-0">
                {"-"}
              </Paragraph>
            )}
          </Col>
          {this.renderRiskLevelField(customer)}
          {this.renderAmoutThreshoudField(customer)}
          {this.renderAccumulatedDepositField(customer)}
          {this.renderAccountManagerField(customer)}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            xxl={8}
            className="item"
          >
            <label className="kpi-label">Account Type</label>
            <p className=" kpi-val fw-700 mb-0">
              {this.getRenderCustomerValue(customer?.accountType)}
            </p>
          </Col></>
      </Row>)}
      {customer?.accountType === "Business" && this.renderCustomerCommonFieldData(customer)}
      {customer?.accountType === "Business" && (<Row className="cust-row-design">
        {this.state.type === "view" &&
          customer?.caseIds !== null && (

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
              xxl={8}
              className="item"
            >
              {this.state.type === "view" &&
                customer?.caseIds !== null && (
                  <>
                    <label className="kpi-label d-block">
                      Case Number
                    </label>
                    <span className="fw-600 fs-14">
                      {customer?.caseIds?.map(
                        (item) => (
                          <Link key={item?.id} className="c-pointer"
                            onClick={() =>
                              this.redirectCaseView(
                                item
                              )
                            }
                          >
                            {item.caseNumber}
                            <br />
                          </Link>
                        )
                      )}
                    </span>
                  </>
                )}
            </Col>

          )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item"
        >
          {this.state.type === "view" && (
            <label className="kpi-label">Client Upload Egnyte</label>
          )}
          {this.state.type === "view" ? (
            <Paragraph
              className="kpi-val fw-700 mb-0"
              copyable={customer?.egnyte}
            >
              {this.getRenderCustomerValue(customer?.egnyte)}
            </Paragraph>
          ) : (
            this.renderEngniteField("egnyte", "Client Upload Egnyte", "input-label mb-0")
          )}
        </Col>
        {this.renderKYCandKYBField("input-label mb-0", customer)}
        {this.renderCommissionTemplateField(customer)}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item"
        >
          <>
            <label className="kpi-label">
              World Check
            </label>
            <p className=" kpi-val fw-700 mb-0">

              <Link onClick={() => window.open(`${process.env.REACT_APP_RISK_URL}`, '_blank')}>Link</Link>
            </p>
          </>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item"
        >
          <>
            <label className="kpi-label">
              LEI Search
            </label>
            <p className=" kpi-val fw-700 mb-0">

              <Link onClick={() => window.open(`${process.env.REACT_APP_LIE_SEARCH}`, '_blank')}>Link</Link>
            </p>
          </>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className="item"
        >
          {this.state.type === "view" && (
            <label className="kpi-label">Risk Score Document</label>
          )}
          {this.state.type === "view" ? (
            <Paragraph
              className="kpi-val fw-700 mb-0"
              copyable={customer?.riskScoreDocument}
            >
              {this.getRenderCustomerValue(customer?.riskScoreDocument)}
            </Paragraph>
          ) : (
            this.renderEngniteField("riskScoreDocument", "Risk Score Document", "input-label mb-0 egnyte-label")
          )}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className={"item"}
        >
          {this.state.type === "view" ? (
            <>
              <label className="kpi-label">
                Introducer
              </label>
              <p className=" kpi-val fw-700 mb-0">
                {this.getRenderCustomerValue(customer?.introducer)}
              </p>
            </>
          ) :
            (<Form.Item
              name="introducer"
              className="input-label cust-inputlabel-style"
              label="Introducer"
              rules={[
                {
                  required: true,
                  message: "Is required"
                }
              ]}
            >
              <Select
                className="cust-input"
                onChange={(e) => this.handleIntroducer(e)}
                placeholder="Select Introducer"
                disabled={
                  this.state.type === "disabled" ||
                  this.state.customerdata.status ===
                  "Inactive"
                }
              >
                <Option value={true}>
                  True
                </Option>
                <Option value={false}>
                  False
                </Option>
              </Select>
            </Form.Item>)}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          xxl={8}
          className={"item"}
        >
          {this.state.type === "view" ? (
            <>
              <label className="kpi-label">
                My Card
              </label>
              <p className=" kpi-val fw-700 mb-0">
                {this.getRenderCustomerValue(customer?.isTopUp)}
              </p>
            </>
          ) :
            (<Form.Item
              name="isTopUp"
              className="input-label cust-inputlabel-style"
              label="My Card"
              rules={[
                {
                  required: true,
                  message: "Is required"
                }
              ]}
            >
              <Select
                className="cust-input"
                onChange={(e) => this.handleisTopUp(e)}
                placeholder="Select My Card"
                disabled={
                  this.state.type === "disabled" ||
                  this.state.customerdata.status ===
                  "Inactive"
                }
              >
                <Option value={true}>
                  True
                </Option>
                <Option value={false}>
                  False
                </Option>
              </Select>
            </Form.Item>)}
        </Col>
      </Row>)}
      {this.renderCommonBussinessAccDetails(customer)}
    </Col>
  }
  renderCustomerEdit = () => (
    (this.props.match.params.type == "view" && this.state.activeTab === "1" && this.isCheckEdit()) && <div className="text-right mb-8"><Button className="primary-btn text-right" style={{ margin: "8px 8px" }} onClick={() => this.GotoProfileEdit()}>Edit</Button></div>
  )
  renderAlertError = (error, errorClass) => (
    error !== null && error !== undefined && (
      <Alert type="error" className={errorClass} showIcon description={error} />
    )
  )
  renderLoader = (load) => (
    load && <Loader />
  )
  renderProfileTabData = () => {
    const { customerdata, customerObj } = this.state;
    const { data: customer } = this.props.customerStore?.customerData;

    return <>
      {" "}
      {customer &&
        <Form
          name="customer"
          autoComplete="off"
          initialValues={customerObj}
          onFinish={this.saveCustomerInfo}
          ref={this.formRef}
        >
          <Row className="detailview">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={9}
              xl={6}
              xxl={5}
              className="view-left"
            >
              <div className="text-center user">
                <img
                  src={customer?.imageURL ? customer?.imageURL : defaultUser}
                  alt="User"
                  className="user-circle"
                />
                <div className="kpi-val text-captz fw-700 fs-18 user-name">
                  {customer?.userName}
                </div>
                <label className="kpi-label">Customer/Contract ID</label>
                <Paragraph className="kpi-val fw-700 mb-0" copyable>
                  {this.getRenderCustomerValue(customer?.depositReference)}
                </Paragraph>
              </div>

              <div className="kpi-item">
                <span className="icon xl email" />
                <div>
                  <label className="kpi-label">Email</label>
                  <div
                    className="kpi-val"
                    style={{ wordBreak: "break-all" }}
                  >
                    {this.getRenderCustomerValue(customer?.email)}
                  </div>
                </div>
              </div>
              <div className="kpi-item">
                <span className="icon xl phone" />
                <div>
                  <label className="kpi-label">Phone</label>
                  <div className="kpi-val">
                    {this.getRenderCustomerValue(customer?.phoneNo)}
                  </div>
                </div>
              </div>
              <div className="kpi-item">
                <span className="icon xl emailverified" />
                <div>
                  <label className="kpi-label">Email Verified</label>
                  <div className="kpi-val">
                    {this.getRenderCustomerValue(customer?.emailVerified)}

                  </div>
                </div>
              </div>
              <div className="kpi-item">
                <span className="icon xl phoneverified" />
                <div>
                  <label className="kpi-label">Phone Verified</label>
                  <div className="kpi-val">
                    {this.getRenderCustomerValue(customer?.phoneVerified)}
                  </div>
                </div>
              </div>
              <div className="kpi-item">
                <span className="icon xl sbstafficon" />
                <div>
                  <label className="kpi-label sb-staff">
                    SB Staff
                  </label>

                  <div className="kpi-val">
                    {customer?.sbStaff ? "true" : "false"}
                  </div>
                </div>
              </div>
            </Col>
            {this.renderProfileData()}
          </Row>
          <Info
            Id={customerdata.id}
            Status={customerdata.status}
            Createdby={customerdata?.userName}
            CreatedDate={customerdata.createdDate}
            ModifiedDate={customerdata?.modifiedDate}
            Modifiedby={customerdata?.modifiedBy}
            isShowInfo={false}
            screenName={"Customers"}
          />
          {this.state.type !== "view" &&
            this.state.type !== "disabled" &&
            customerdata.status !== "Inactive" && (
              <div className="text-right mt-16">
                <Button
                  loading={this.state.isLoading}
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  className="primary-btn cancel-btn"
                  style={{ margin: "0 8px" }}
                  onClick={() =>
                    this.props.history.push("/customers")
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
        </Form>
      }{" "}
    </>

  }
  renderTabsData = () => {
    return <>
      <TabPane tab="Screening" key="6">
        {this.state.activeTab === "6" && (

          <ScreeningDetails />

        )}

      </TabPane>
      <TabPane tab="Backup Address" key="9">
        {this.state.activeTab === "9" && (
          <BackupAddress id={this.props.match.params.id} />
        )}
      </TabPane>
      <TabPane tab="Address Book" key="8">
        {this.state.activeTab === "8" && (
          <CustomerAddressBook params={this.props} activeTab={this.state.activeTab} gridID={this.props.match.params.id} />
        )}
      </TabPane>
    </>
  }

  renderKYCKYBTab = () => {
    const { data: customer } = this.props.customerStore?.customerData;
    return <TabPane tab={customer?.isBusiness ? "KYB" : "KYC"} key="10">
      {this.state.activeTab === "10" && (
        customer?.isBusiness ? <KYB customerId={this.props.match.params.id} activeTab={this.state.activeTab} ctrlOwnerShipDetails={this.state?.ctrlOwnerShipDetails} companyStructureDetails={this.state?.companyStructureDetails} companyOfficers={this.state?.companyOfficers}
          companyCheck={this.state?.companyCheck} companySignificantPersons={this.state?.companySignificantPersons} /> : <KYC customerId={this.props.match.params.id} activeTab={this.state.activeTab} business={this.props?.userConfig?.isBusiness} />
      )}
    </TabPane>
  }
  render() {
    const { errorMsg } = this.state;
    const { data: customer, isLoading, error } = this.props.customerStore?.customerData;
    if (this.state.type !== this.props.match?.params?.type) {
      this.setState({ ...this.state, type: this.props.match?.params?.type })
    }

    return (
      <>
        {console.log(this.props?.match?.params)}
        <div ref={this.useDivRef}></div>
        {this.renderLoader(isLoading)}
        <div className="d-flex justify-content align-center">
          <Title className="page-title">Customer Detail View</Title>
          {this.renderCustomerEdit()}
        </div>
        {this.renderAlertError(error, "mb-16")}
        {this.renderAlertError(errorMsg, "w-100 mb-16")}

        <Tabs activeKey={this.state.activeTab} onTabClick={this.changeTab}>
          <TabPane tab="Profile" key="1">
            {this.state.activeTab === "1" && this.renderProfileTabData()}
          </TabPane>
          <TabPane tab="Wallets" key="2">
            {this.state.activeTab === "2" && (
              <WalletsDetials />
            )}
          </TabPane>
          <TabPane tab="Transactions" key="3">
            &emsp;&emsp;
            {this.state.activeTab === "3" && (
              <Transactions />
            )}

          </TabPane>
          <TabPane tab="Partner" key="4">
            &emsp;&emsp;
            {this.state.activeTab === "4" && (
              <PartnerDetails />
            )}
          </TabPane>
          {this.renderTabsData()}
          <TabPane tab="Fees" key="7">
            {this.state.activeTab === "7" && (
              <CustomerBatchFee params={this.props} activeTab={this.state.activeTab} />
            )}
          </TabPane>
          {this.renderKYCKYBTab()}
          {/* <TabPane tab="Fees" key="7">
            {this.state.activeTab === "7" && (
              <CustomerBatchFee params={this.props} activeTab={this.state.activeTab}/>
            )}
          </TabPane> */}
          <TabPane tab="Deductions" key="20">
            {this.state.activeTab === "20" && (
              <CustomerDeductions />
            )}
          </TabPane>
        </Tabs>

        {customer?.isBusiness &&
          process.env.REACT_APP_ISSHOWCARDS === "true" && (
            <TabPane tab="Card" key="5">
              <React.Fragment>
                <Title className="page-title mt-36">Card Status</Title>
                <Collapse
                  className="trans-collapse"
                  expandIcon={() => <span className="icon md downangle" />}
                >
                  <Panel header="Card Status" key="1" className="fs-16 fw-500">
                    <Row>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label fs-14">Card Status</label>
                        <Paragraph className="kpi-val fs-14 fw-700 mb-0">
                          {customer?.cardStatus || "--"}
                        </Paragraph>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label fs-14">Applied On</label>
                        <Paragraph className="kpi-val fw-700 mb-0 fs-14">
                          {customer?.cardAppliedOn ? moment(apiCalls.convertUTCToLocalTime(
                            customer?.cardAppliedOn
                          )
                          ).format(config?.dateFormates?.dateFormate)
                            : "--"}
                        </Paragraph>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={8}
                        className="item"
                      >
                        <label className="kpi-label fs-14">Card URL</label>
                        <Paragraph className="kpi-val fs-14 fw-700 mb-0">
                          {this.getRenderCustomerValue(customer?.cardURL)}
                        </Paragraph>
                      </Col>
                    </Row>
                    <Row className="mt-8">
                      <Col className="item">
                        <label className="kpi-label fs-14">Remarks</label>
                        <Paragraph className="kpi-val fs-14 fw-700 mb-0">
                          {this.getRenderCustomerValue(customer?.cardRemarks)}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </React.Fragment>
            </TabPane>
          )}

        {this.state.cryptoModal && <SharedInfo id={this.state.selectedId} type={this.state.selectedModal} screen={'customers'} cryptoModal={this.state.cryptoModal} CloseCrypto={this.CloseCrypto} />}

        <Modal
          title="Available Banks"
          visible={this.state.banksModal}
          className="crypto-list bank-modal"
          destroyOnClose={true}
          closeIcon={
            <Tooltip title="Close " onClick={() =>
              this.handleBankModalClose()
            }>
              <span
                className="icon md x c-pointer"
              />
            </Tooltip>
          }
          footer={null}
        ><div>
            <Form
              className="risk-popup cust-popalign"
              onFinish={() => this.saveBanks()}
            >
              {this.renderAlertError(this.state.bankerrorMsg, "mb-12 send-poperror")}
              {this.state.banksLu?.length !== 0 && <Row gutter={24} className="mb-24 pb-24 border-bottom" style={{ marginTop: 10, marginLeft: 3 }}>
                <Col xs={24} sm={24} md={12}>
                  {this.state.banksLu.map((bank) => (<div className="d-flex align-center"><label className="text-center custom-checkbox mr-4">
                    <input type="checkbox" name="banks" checked={bank.status == 'Active'} onChange={() => this.checkBanks(bank)}></input>
                    <span></span>{" "}
                  </label>{bank.bankName}</div>))}

                </Col>
              </Row>}
              {this.state.banksLu?.length == 0 && <span>No Banks Available</span>}
              <Form.Item className="text-right mb-0">
                <div className="text-right">
                  <Button
                    type="primary"
                    className="primary-btn cancel-btn reject-btn"
                    onClick={this.handleBankModalClose
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    style={{ marginLeft: 10 }} loading={this.state.bankLoader}>
                    Save
                  </Button></div>
              </Form.Item>
            </Form></div>
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({
  customerStore,
  userConfig,
  trackAuditLogData,
  permissions
}) => {
  return {
    permissions,
    customerStore,
    userConfig: userConfig.userProfileInfo,
    trackAuditLogData: userConfig.trackAuditLogData
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    fetchUserData: (id) => {
      dispatch(handleMembers(id));
    },
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(CustomerSummary);
