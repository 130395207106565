import { Form, Row, Col, Input, Button, message, Alert,Modal } from 'antd';
import React,{ useEffect, useState } from 'react';
import {customerDeductionData, customerDeductionsSaveData } from './api';
import { connect } from 'react-redux';
import apiCalls from '../../api/apiCalls';
import Loader from '../loader.component';
import Info from "../info/Info";
import NumberFormat from 'react-number-format';
import moment from 'moment';
const CustomerDeductions = (props) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loader,setLoader]=useState(false)
    const [formData,setFormData]=useState({})
    const [isCheck, setIsCheck] = useState(false);
    const [isCheckConfirm, setIsCheckConfirm] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [form] = Form.useForm();
    const useRef = React.createRef();
    const userId=window.location.pathname.split('/')[3]
    useEffect(() => {
        customerDeductionDetails();
    }, []);
    const formatDate = (dateString) => {
        return dateString ? moment(dateString).format('DD/MM/YYYY') : '';
    };
    const customerDeductionDetails = async () => {
        
        setLoader(true)
        setErrorMsg(null);
        try{
        const res = await customerDeductionData(userId);
        if (res.ok) {
            form.setFieldsValue({
                ...res.data,
                lastTransactionDate: formatDate(res.data.lastTransactionDate),
                accountApprovalDate: formatDate(res.data.accountApprovalDate)
            });
            setIsCheck(res?.data?.isExcludeMonthlyDeductions)
            setErrorMsg(null);
            setLoader(false)
            setFormData(res?.data)
            if(res.data?.onboardingFee==null || res?.data?.monthlyAccountMaintainanceFee ==null|| res?.data?.dormantAccountFee==null){
                setBtnDisabled(false)
            }else{
                setBtnDisabled(true)
            }
        }
        else{
            setErrorMsg(apiCalls.isErrorDispaly(res));
            window.scrollTo(0, 0)
            setLoader(false)
        }
    }
    catch(error){
        setErrorMsg(apiCalls.isErrorDispaly(error));
        setLoader(false)
    }
    };
    
    const handleCheckChange = (e) => {
        setIsCheck(e.target.checked);
        setIsCheckConfirm(true)
        setBtnDisabled(false)
    };

    const handleDeleteModalClose=()=>{
        setIsCheckConfirm(false)
        setIsCheck((prevState) => !prevState);  

        if(formData?.onboardingFee==null || formData?.monthlyAccountMaintainanceFee ==null|| formData?.dormantAccountFee==null){
            setBtnDisabled(false)
        }else{
            setBtnDisabled(true)
        }

    }

    const handleModalClose=()=>{
        setIsCheckConfirm(false)
    }

    const onSubmit = async(values) => {
        const { onboardingFee, monthlyAccountMaintainanceFee, dormantAccountFee } = values;
    if ( parseFloat(monthlyAccountMaintainanceFee) === 0 || parseFloat(dormantAccountFee) === 0) {
        setErrorMsg(" Amount must be greater than zero");
        return;
    }
    if (onboardingFee.toString().startsWith(".")||monthlyAccountMaintainanceFee.toString().startsWith(".")||dormantAccountFee.toString().startsWith(".")) {
        setErrorMsg( "Please enter a valid amount." );
        return;
    }
    
      setBtnLoading(true);
      setErrorMsg(null);
      const obj={
        "onboardingFee":values?.onboardingFee,
        "monthlyAccountMaintainanceFee":values?.monthlyAccountMaintainanceFee,
        "dormantAccountFee":values?.dormantAccountFee,
        "modifiedBy":props?.userConfig?.userName,
        "isExcludeMonthlyDeductions" :isCheck == null ? false : isCheck
    }
      const res = await customerDeductionsSaveData(userId,obj)
      if(res.ok){
        message.success({
            content: 'Deductions details saved successfully',
            className: 'custom-msg',
            duration: 4
        });
        setBtnLoading(false);
        setErrorMsg(null);
        customerDeductionDetails();
      }
      else {
        setBtnLoading(false);
        setErrorMsg(apiCalls.isErrorDispaly(res));
        window.scrollTo(0, 0)
    }
    };
    return (
        <div>
        {errorMsg !== undefined && errorMsg !== null && (
            <Alert className="w-100 mb-16" type="error" showIcon description={errorMsg} />
        )}
        <div ref={useRef}></div>
        {        console.log(isCheck)
        }
       {loader ?<Loader/>: (<Form
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={onSubmit}
            autoComplete="off"
        >
            <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="onboardingFee"
                        label="Onboarding Fee"
                        className="input-label"
                        rules={[{ required: true, message: 'Is required'}]}
                    >
                        <NumberFormat placeholder="Onboarding Fee" className="cust-input" 
                        decimalScale={2} thousandSeparator={true} maxLength={13} customInput={Input} 
                        disabled={formData?.onboardingFee !== null}

                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="monthlyAccountMaintainanceFee"
                        label="Monthly Account Maintenance Fees"
                        className="input-label"
                        rules={[{ required: true, message: 'Is required' }]}
                    >
                        <NumberFormat placeholder="Monthly Account Maintenance Fees" className="cust-input" decimalScale={2} 
                        thousandSeparator={true} maxLength={13} customInput={Input} 
                        disabled={formData?.monthlyAccountMaintainanceFee !== null}

                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="dormantAccountFee"
                        label="Dormant Account Fees"
                        className="input-label"
                        rules={[{ required: true, message: 'Is required' }]}
                    >
                        <NumberFormat placeholder="Dormant Account Fees" className="cust-input" 
                        decimalScale={2} thousandSeparator={true} maxLength={13} customInput={Input}
                        disabled={formData?.dormantAccountFee !== null}

                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="accountApprovalDate"
                        label="Account Approval Date"
                        className="input-label"
                    >
                        <Input placeholder="Account Approval Date" className="cust-input" disabled />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="lastTransactionDate"
                        label="Last Transaction Date"
                        className="input-label"
                    >
                        <Input placeholder="Last Transaction Date" className="cust-input" disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                    <Form.Item
                        name="isExcludeMonthlyDeductions"
                        valuePropName="checked"
                    >
                        <div className="d-flex align-center justify-content-between">
                            <div className="d-flex align-center mt-16">
                                <label className="custom-checkbox p-relative">
                                    <Input
                                        type="checkbox"
                                        className="c-pointer"
                                       // checked={formData?.isExcludeMonthlyDeductions}
                                        checked={isCheck}
                                        
                                        onChange={(e) => handleCheckChange(e)}

                                    />
                                    <span></span>
                                </label>
                                <div className="fs-14 ml-4">Exclude From Monthly Deductions</div>
                            </div>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Info
                  Id={userId}
                  ModifiedDate={formData?.modifiedDate}
                  Modifiedby={formData?.modifiedBy}
                  screenName={'Deductions'}
                />
            <div className="text-right mt-24">
                <Button type="primary" className="primary-btn" htmlType='submit' loading={btnLoading}
                disabled={btnDisabled}
                >
                    Save
                </Button>
            </div>
        </Form>)}
        
        <Modal
				title="Confirmation"
				visible={isCheckConfirm}
				className="crypto-list"
				closeIcon={
					<span onClick={handleDeleteModalClose}
						className="icon md x c-pointer"
					/>
				}
				destroyOnClose={true}

				footer={ <Form.Item className="text-right mb-0">
				<Button
					type="primary"
					className="primary-btn cancel-btn reject-btn"
					onClick={handleDeleteModalClose}
				>
					No
				</Button>
				<Button
					type="primary"
					className="primary-btn ml-8"
					htmlType="submit"
					onClick={handleModalClose}
				>
					Yes
				</Button>
			</Form.Item>}
			>
				<div>
					
					<Form
						className="risk-popup"
					>
						<div>
							<p className="mb-16">Are you sure you want to {isCheck ? "select" : "unselect"} this monthly deduction?</p>
						</div>
						
					</Form>
				</div>
			</Modal>
        </div>
    );
};

const connectStateToProps = ({ userConfig }) => {
    return { userConfig: userConfig.userProfileInfo };
};

export default connect(connectStateToProps)(CustomerDeductions);
