
import React, { useEffect, useState } from "react";
import { Button, Form, Alert,Input,message} from "antd";
import { connect } from "react-redux";
import { getProvider, getProviderEdit } from "./api";
import Loader from '../loader.component/index';
import NumberFormat from 'react-number-format';
import apiCalls from "../../api/apiCalls";

const EditProviderFee = () => {
  const [provider, setProvider] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [providerList, setProviderList] = useState(null);

  useEffect(() => {
    getProviderFees();
  }, []);

  const getProviderFees = async () => {
    setLoading(true);
    const response = await getProvider();
    if (response.ok) {
      setProvider(response.data);
      let products = response.data;
      const groupByCategory = products.reduce((group, product) => {
        const { operation } = product;
        group[operation] = group[operation] ?? [];
        group[operation].push(product);
        return group;
      }, {});
      const tableData = Object.entries(groupByCategory);
      setProviderList(tableData)
      setLoading(false)
    } else {
      setErrorMsg(apiCalls.isErrorDispaly(response));
      setBtnLoading(false)
    }
  }
  const CommissionEdit = (value, item, key) => {
    let data = provider.find((product) => product == item)
    let _obj = data
    _obj[key] = value;
    let _objData = [_obj]
    provider.forEach(obj => _objData.find(o => o.id === _obj.id) || obj);
  }
  const updateState = (e,item, key) => {
    const value = e.target.value;
    if(e.target.value){
      let val = e.target.value?.replace(/[|&;$%@"<>()+,]/g, "");
      val=parseFloat(val?.replace(/,/g, ''));
      CommissionEdit(val,item,key)
    }else{
      CommissionEdit(value,item,key)
    }
  }

  const SaveProvider = async () => {
    setBtnLoading(true)
    let response = await getProviderEdit(provider);
    if (response.ok) {
      message.success({
        content: 'Provider Fees saved successfully.',
        className: "custom-msg",
        duration: 3
      });
      setBtnLoading(false)
      getProviderFees()
    } else {
      setErrorMsg(apiCalls.isErrorDispaly(response));
      setBtnLoading(false)
    }
  }


  return (
    <>
      <>
        {errorMsg !== undefined && errorMsg !== null && errorMsg !== "" && (
          <div style={{ width: '100%' }}>
            <Alert
              className="w-100 mb-16"
              type="warning"
              description={errorMsg}
              showIcon
            />
          </div>
        )}
        {loading ? <Loader /> :
          <Form autoComplete="off" className="commission-form" onFinish={SaveProvider}>
            <div className="addcommission">
              <table className="commision-table table-border edit-commition-table" border="1">
                <thead><tr className="table-header-row">
                  <th ></th>
                  <th className="p-0">
                        <table className="table-partner-head">
                          <tr>
                            <th colSpan={2} ></th>
                            <th className="table-border-right"></th>
                            <th  style={{ width: "367px"  }} className="table-border-right text-center">Provider Fees</th>
                            <th style={{ width: "368px" }} className="table-border-right text-center" >Sweep at Source</th>
                            <th style={{ width: "366px" }} className="table-border-right text-center">Sweep at Destination</th>
                          </tr>
                        </table>
                      </th>

                </tr></thead>
                <tbody>
                  {providerList?.map(([operation, items]) => (
                    <tr>
                      <td>{operation}</td>
                     <td className="p-0">
                      {items.map((item)=>(
                       <table>
                        <tr style={{borderBottom:"1px solid #ccc", height:"65px"}}>


                           <td style={{borderRight:"1px solid #ccc",width:" 100px"}}>
                                <table>
                                  <tr>
                                  <td>{item.currencyType}</td>
                                  </tr>
                                 {item.currencyType !=="Crypto" &&
                                  <tr>
                                  <td>{item.currency}</td>
                                  </tr>}
                                </table>
                              </td>


                           <td className="inside" style={{width:"200px"}} >{item.bankName}
                           <div>
                           {" "} {item.isPersonalBank && <span className="file-labels fs-12 address-label address-label-width">Personal</span>}
                           {" "} 
                           {item.status==="Inactive" &&
                           <span className="file-labels fs-12 address-label address-label-width in-active">Inactive</span>
                           } 
                           {" "} {item.type == "Card" && <span className="file-labels fs-12 address-label address-label-width">Card</span>}

                           </div>
                           </td>


                           <td style={{ width: "380px"}} className=" inside">
                            <tr>
                            <td className="commition-input-bg " style={{ width: "190px" }}>
                              <Form.Item className="customised-input "
                                name='providerMinFee'
                              >
                                <div>

                                  <NumberFormat
                                          decimalScale={(item?.operation == "Swap" && item?.currencyType == "Crypto") ? 4 : 2}
                                          customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => updateState(e, item, "providerMinFee")}
                                    thousandSeparator={true}
                                    defaultValue={item?.providerMinFee}
                                    placeholder="Min"
                                    allowNegative={false}
                                    maxLength={6}
                                    addonAfter={<span>Min</span>}
                                    disabled={item?.status === "Inactive"}
                                  />
                                </div>
                              </Form.Item>
                            </td>
                            <td className="commition-input-bg " style={{ width: "190px" }}><Form.Item className="customised-input"
                              name="providerMaxFee"
                            >
                              <div>

                                <NumberFormat
                                          decimalScale={(item?.operation == "Swap" && item?.currencyType == "Crypto") ? 4 : 2}
                                          customInput={Input}
                                  className="cust-input"
                                  onChange={(e) => updateState(e, item, "providerMaxFee")}
                                  thousandSeparator={true}
                                  defaultValue={item.providerMaxFee}
                                  placeholder="Max"
                                  allowNegative={false}
                                  maxLength={6}
                                  addonAfter={<span>Max(%)</span>}
                                  disabled={item?.status === "Inactive"}
                                />
                              </div>
                            </Form.Item></td>
                            </tr>
                            </td>


                            <td style={{ width: "380px"}} className=" inside">
                            <tr>
                            <td className="commition-input-bg" style={{ width: "190px" }}><Form.Item className="customised-input"
                              name="sweepSourceMinFee"
                            >
                              <div>
                                <NumberFormat
                                          decimalScale={(item?.operation == "Swap" && item?.currencyType == "Crypto") ? 4 : 2}
                                          customInput={Input}
                                  className="cust-input"
                                  onChange={(e) => updateState(e, item, "sweepSourceMinFee")}
                                  thousandSeparator={true}
                                  defaultValue={item.sweepSourceMinFee}
                                  placeholder="Min "
                                  allowNegative={false}
                                  maxLength={6}
                                  addonAfter={<span>Min</span>}
                                  disabled={item?.status === "Inactive"}
                                />
                              </div>
                            </Form.Item></td>
                            <td className="commition-input-bg" style={{ width: "190px" }}><Form.Item className="customised-input"
                              name="sweepSourceMaxFee"
                            >
                              <div>
                                <NumberFormat
                                          decimalScale={(item?.operation == "Swap" && item?.currencyType == "Crypto") ? 4 : 2}
                                          customInput={Input}
                                  className="cust-input"
                                  onChange={(e) => updateState(e,  item, "sweepSourceMaxFee")}
                                  thousandSeparator={true}
                                  defaultValue={item.sweepSourceMaxFee}
                                  placeholder="Max "
                                  allowNegative={false}
                                  maxLength={6}
                                  addonAfter={<span>Max(%)</span>}
                                  disabled={item?.status === "Inactive"}
                                />
                              </div>
                            </Form.Item></td>
                            </tr>
                            </td>
                            <td style={{ width: "380px"}} className=" inside">
                            <tr>
                            <td className="commition-input-bg" style={{ width: "190px" }}>
                              <Form.Item className="customised-input"
                                name="sweepDestinationMinFee">
                                <div>
                                  <NumberFormat
                                          decimalScale={(item?.operation == "Swap" && item?.currencyType == "Crypto") ? 4 : 2}
                                          customInput={Input}
                                    className="cust-input"
                                    onChange={(e) => updateState(e, item, "sweepDestinationMinFee")}
                                    thousandSeparator={true}
                                    defaultValue={item.sweepDestinationMinFee}
                                    placeholder="Min"
                                    allowNegative={false}
                                    maxLength={6}
                                    addonAfter={<span>Min</span>}
                                    disabled={item?.status === "Inactive"}
                                  />
                                </div>
                              </Form.Item></td>
                            <td className="commition-input-bg" style={{ width: "190px" }}> <Form.Item className="customised-input"
                              name="sweepDestinationMaxFee">
                              <div>
                                <NumberFormat
                                          decimalScale={(item?.operation == "Swap" && item?.currencyType == "Crypto") ? 4 : 2}
                                          customInput={Input}
                                  className="cust-input"
                                  onChange={(e) => updateState(e,  item, "sweepDestinationMaxFee")}
                                  thousandSeparator={true}
                                  defaultValue={item.sweepDestinationMaxFee}
                                  placeholder="Max"
                                  allowNegative={false}
                                  maxLength={6}
                                  addonAfter={<span>Max(%)</span>}
                                  disabled={item?.status === "Inactive"}
                                />
                              </div>
                            </Form.Item></td>
                            </tr>
                            </td>
                           </tr>
                          </table>
                      ))}
                     
                     </td>
                    </tr>
                  ))}
                </tbody>


              </table>
            </div>
            
            <div className="text-right mt-16 mb-16">
            <Button
                type="primary"
                className="primary-btn"
                htmlType="submit"
                loading={btnLoading}
                style={{marginLeft:"10px"}}
              >
                Save
              </Button>
            </div>
          </Form>}
      </>
    </>
  );

}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig?.userProfileInfo,
  };
};
export default connect(connectStateToProps)(EditProviderFee);