import React, { Suspense, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Spin } from "antd";
import { connect } from "react-redux";
import SignInSilent from "../authentication/signinSilent";
import {getPermissions, updateCurrentScreenId, updateLoader, updateAccessDenied} from "../reducers/permissionsReducer"
import { store } from "../store";
import Transaction from "../components/transaction.component";
import CustomerSummary from "../components/customers.component/customerSummary";
import Customers from "../components/customers.component";
import CustomerCommissions from "../components/customers.component/commissions";
import CallbackPage from "../authentication/callback.component.js";
import Dashboard from "../components/dashboard.component";
import Banks from "../components/banks.component";
import addBank from "../components/banks.component/addBank";
import TransactionSummary from "../components/transaction.component/transDetailView";
import BuySell from "../components/buySell.component";
import addBuySell from "../components/buySell.component/buysellDetails";
import Deposit from "../components/deposit.component";
import Swap from "../components/swap.component";
import BuySellSummary from "../components/buySell.component/buysellSummary";
import Withdraw from "../components/withdraw.component";
import WithdrawSummary from "../components/withdraw.component/cryptoDetailView";
import SwapDetailView from "../components/swap.component/swapDetailView";
import DepositCryptoSummary from "../components/deposit.component/cryptoDetailView";
import FiatDetails from "../components/deposit.component/fiatDetails";
import Notices from "../components/notices.component";
import NoticesDetails from "../components/notices.component/noticesDetails";
import ControlCodes from "../components/controlCodes.component";
import ControlCodeDetails from "../components/controlCodes.component/controlCodeDetails";
import AuditLogs from "../components/auditlogs.component";
import RolesDetails from "../components/roles.component/rolesDetails";
import UsersComponent from "../components/users.component";
import UsersDetails from "../components/users.component/userDetails";
import AccessDenied from "../components/accessDined.component/accessDenied.js";
import Roles from "../components/roles.component";
import Document from "../components/documents.component/requested.documents";
import Documents from "../components/documents.component/documents";
import Compliance from "../components/compliance.component/compliance.component";
import Reports from "../components/report.component";
import Report from "../components/report.component/reportView";
import UploadMassPayments from "../components/uploadMasspayments.component";
import UploadMassPaymentsDetails from "../components/uploadMasspayments.component/uploadMassPaymentsDeatails";
import MassPayments from "../components/masspayments.component";
import MassPaymentsDetails from "../components/masspayments.component/masspaymentDetails";
import DepositAdmin from "../components/depositAdmin.component";
import Payments from "../components/payments.component";
import PaymentDetails from "../components/payments.component/paymentDetails";
import Alerts from "../components/alerts.component";
import AddAlert from "../components/alerts.component/addAlert";
import AlertDetails from "../components/alerts.component/alertDetails";

import BatchPayments from "../components/batchPayments.components"
import BatchPaymentsGrid from "../components/batchPayments.components/batchpayments";


import Cases from "../components/cases.component";
import CaseDetails from "../components/cases.component/casesDetails";
import CaseView from "../components/cases.component/caseView";
import CaseTemplateView from "../components/cases.component/caseTemplateView";

import CompanyDetialsView from "../components/customers.component/companyDetialsView";
import AddressBook from "../components/address.book";
import Cards from "../components/customers.component/cards.component";
import Topups from "../components/customers.component/topup.component";
import RiskDetialsView from "../components/customers.component/riskDetialsView";
import AddressFiatView from "../components/address.book/addressFiatView";
import AddressCryptoView from "../components/address.book/addressCryptoView";
import Reconcile from "../components/reconcile.component";
import ReconcileDetails from "../components/reconcile.component/reconcileDetails.js";
import ReconcileView from "../components/reconcile.component/view";
import Balances from "../components/Balances.component";
import BalancesDetails from '../components/Balances.component/balancesDetails';
import BalanceDetailView from '../components/Balances.component/balanceDetailView';
import companywallets from "../components/companyWallet.component";
import Referral from "../components/referral.component";
import Bonus from "../components/bonus.component";
import Transfer from "../components/transfer.component";
import TransferDetails from "../components/transfer.component/transferDetails";
import TransferDetailView from '../components/transfer.component/transferDetailView';
import TransactionComplience from '../components/transactionCompliance/index';

import BankWithdraw from '../components/bank.withdraw.component/index';
import WithdrawDetailSummary from '../components/bank.withdraw.component/bankWithdrawDetails'
import BankPyrrosDeposit from '../components/bankPyrrosDeposit.component'
import DepositPyrrosDetailView from '../components/bankPyrrosDeposit.component/bankPyrrosDetailView'
import BankCustomers from '../components/bank.customers/index'
import BankCustomerDetailView from '../components/bank.customers/bankCustomerDetailView';
import BankTransaction from "../components/bankTransaction.component";
import BankTransactionSummary from "../components/bankTransaction.component/bankTransDetailView.js";

import UserProfile from "../components/userProfile.component/userProfile";
import NewCommission from "../components/commissions.component/newCommissions.component";
import CommissionsTier from '../components/commissions.component/newCommissions.component/commissionsTier';
import personalBank from "../components/personalBankComponent";
import AddPersonalBank from "../components/personalBankComponent/personalAddBanks";
import AddCommissions from "../components/commissions.component/newCommissions.component/addcommissions"
import AddCommissionsView from "../components/commissions.component/newCommissions.component/addCommissionView"
import EditProviderFee from "../components/providerFee.component/editProviderFee";
import PendingDeposit from "../components/pendingDeposit/index.js";
import BankBalance from "../components/companyBanks.component/bankBalance.js";
import Deductions from "../components/deductions.component/index.js";
import DeductionsView from '../components/deductions.component/deductionhistory.component/detailView.js'
import BankSathoshiTest from "../components/bank.sathoshitest.component/index.js";
import SelfHostedDetailedView from "../components/bank.sathoshitest.component/view.js";
import bankReportsComponent from "../components/bankReports.component/index.js";
import BankReportView from "../components/bankReports.component/bankReportView.js";
import Card from '../components/card.component'
import AddCard from '../components/card.component/addCard.js'
import Introducer from '../components/introducer.component'


const Content = ({ history, screenPermissions, userProfileInfo, dispatch, ...rest }) => {

	useEffect(() => {
		window.scrollTo(0, 0);
		permissionCheck();
		history.listen((location) => {
			dispatch(updateAccessDenied(false))
			permissionCheck();
		});
	}, []);//eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(screenPermissions?.menuItems && screenPermissions?.menuItems.length > 0){
			permissionCheck()
		}
	},[screenPermissions?.menuItems]);//eslint-disable-line react-hooks/exhaustive-deps
	const getPath = ()=>{
		let path = window.location.pathname;
			path = path === "/" ? "/dashboard" : path;
			path = path.split('/')[1];
		return '/'+path;
	}
	const permissionCheck = () => {
		if(window.location.pathname.indexOf("/userprofile")>-1){
			dispatch(updateCurrentScreenId({key:'currentScreenId',data:'00000000-0000-0000-0000-000000000000'}))
			dispatch(updateLoader(false))
			return
		}
		if (window.location.pathname !== "/accessdenied") {
			let path = getPath()
			const {
				permissions,
			} = store.getState();
			if (permissions?.menuItems && permissions?.menuItems.length > 0) {
				let _menuItem = permissions.menuItems?.filter((menuitem) => menuitem.path === path)[0];
				if(_menuItem){
				if (permissions[_menuItem?.id]) {
					if(_menuItem?.parentId){
						dispatch(updateCurrentScreenId({key:'currentScreenId',data:_menuItem.parentId}))
						dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:_menuItem.id}))
					}else if(_menuItem?.isTab){
						if(permissions.currentScreenTabId){
							dispatch(updateCurrentScreenId({key:'currentScreenId',data:_menuItem.id}))
						}else{
							const _menutabItem = permissions.menuItems.filter((item) => item.parentId === _menuItem.id)[0];
							dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:_menutabItem.id}))
						}
					}else{
						dispatch(updateCurrentScreenId({key:'currentScreenId',data:_menuItem.id}))
						dispatch(updateCurrentScreenId({key:'currentScreenTabId',data:null}))
					}
					dispatch(updateLoader(false))
					checkPermission(permissions[_menuItem?.id],'view',_menuItem)
				} else {
					dispatch(getPermissions({perKey:_menuItem.id, customerId:userProfileInfo.id}));
				}
			}
		}
		}
	};
	useEffect(() => {
		if (
			screenPermissions?.accessDenied &&
			window.location.pathname !== "/accessdenied"
		) {
			history.push("/accessdenied");
		}
	}, [screenPermissions?.accessDenied]);//eslint-disable-line react-hooks/exhaustive-deps
	const checkPermission = async (perData,perName, feature) => {
		if(perData.length>0){
		const _per = perData.filter((item) => item.permissionName === perName)[0];
		if (_per) {
			if (!_per.values && !feature.parentId) {
				history.push("/accessdenied");
			}
		}
	}else{
		dispatch(getPermissions({perKey:feature.id, customerId:userProfileInfo.id}));
	}
	};
	const renderBankWithdrawalRoutes = ({ match: { url } }) => (
		<>
		  <Route path={`${url}`} component={BankWithdraw} exact />
		  <Route path={`${url}/:id/view`} component={WithdrawDetailSummary} />
		</>
	  );
	const renderBanksDepositRoutes = ({ match: { url } }) => (
		<>
			<Route path={`${url}`} component={BankPyrrosDeposit} exact />
			<Route path={`${url}/details/:id/:view?`} component={DepositPyrrosDetailView} />
		</>
	);
	const renderBanksCustomerRoutes = ({ match: { url } }) => (
		<>
		  <Route path={`${url}`} component={BankCustomers} exact />
		  <Route path={`${url}/details/:id/:type?`} component={BankCustomerDetailView} />
		</>
	  );
	  const renderBanksReportsRoutes = ({ match: { url } }) => (
		<>
		  <Route path={`${url}`} component={bankReportsComponent} exact />
		  <Route path={`${url}/:reportname`} component={BankReportView} />
		</>
	  );
	const renderBanksTransactionRoutes = ({ match: { url } }) => (
		<>
		  <Route path={`${url}`} component={BankTransaction} exact />
		  <Route path={`${url}/details/:id/:type?`} component={BankTransactionSummary} />
		</>
	  );
	if ((screenPermissions?.loading || !screenPermissions?.currentScreenId) && window.location.pathname !== "/accessdenied") {
		return (
			<div className="loader">
				<Spin size="large" tip="Loading..." />
			</div>
		);
	}else{
	return (
		<Switch>
			<Suspense
				fallback={
					<div className="loader">
						<Spin size="large" tip="Loading..." />
					</div>
				}>
				<Route path="/accessdenied" component={AccessDenied} />
				<Route path="/callback" component={CallbackPage} />
				<Route
					path="/dashboard"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Dashboard} exact />
						</>
					)}
				/>
				<Route
					path="/roles"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Roles} exact />
							<Route
								path={`${url}/:id/:roleName?/:mode?`}
								component={RolesDetails}
							/>
						</>
					)}
				/>
				<Route
					path="/userprofile"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}/:key?/:type?`} component={UserProfile} exact />
						</>
					)}
				/>
				<Route
					path="/swap"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Swap} exact />
							<Route path={`${url}/:id`} component={SwapDetailView} />
						</>
					)}
				/>
				<Route
					path="/customers"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Customers} exact />
							<Route
								path={`${url}/details/:id/:type?/:state?/:tab?`}
								component={CustomerSummary}
							/>
							<Route
								path={`${url}/kompany/:id/:type?`}
								component={CompanyDetialsView}
							/>
							<Route
								path={`${url}/commissions/:id`}
								component={CustomerCommissions}
							/>
							<Route path={`${url}/cards/:id?`} component={Cards} />
							<Route path={`${url}/topups`} component={Topups} />
							<Route
								path={`${url}/risk/:id/:type?`}
								component={RiskDetialsView}
							/>
							
						</>
					)}
				/>
				<Route
					path="/companybanks"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={BankBalance} exact />
						</>
					)}
				/>
				<Route
					path="/banks"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Banks} exact />
							<Route path={`${url}/:id/:type?`} component={addBank} />
						</>
					)}
				/>
				<Route
					path="/transaction"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Transaction} exact />
							<Route path={`${url}/:id`} component={TransactionSummary} />
						</>
					)}
				/>
				<Route
					path="/buysell"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={BuySell} exact />
							<Route path={`${url}/:id/addbuysell`} component={addBuySell} />
							<Route
								path={`${url}/:id/buysellsummary`}
								component={BuySellSummary}
							/>
						</>
					)}
				/>
				<Route
					path="/deposit"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Deposit} exact />
							<Route
								path={`${url}/:id/fiatDetails/:type?/:isPendingDeposit?`}
								component={FiatDetails}
							/>
							<Route
								path={`${url}/:id/cryptoDetailView`}
								component={DepositCryptoSummary}
							/>
						</>
					)}
				/>
				<Route
					path="/memberdepositcrypto"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={DepositAdmin} exact />
						</>
					)}
				/>
				<Route
					path="/withdraw"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Withdraw} exact />
							<Route path={`${url}/:id/:type?/:isCommissionCheck?`} component={WithdrawSummary} />
						</>
					)}
				/>
				<Route
					path="/notices"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Notices} exact />
							<Route path={`${url}/:id/:type?`} component={NoticesDetails} />
						</>
					)}
				/>
				<Route
					path="/auditlogs"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={AuditLogs} exact />
						</>
					)}
				/>
			
					<Route
					path="/controlcodes"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={ControlCodes} exact />
							<Route
								path={`${url}/:id/:mode?/:type?`}
								component={ControlCodeDetails}
							/>
						</>
					)}
				/>
				<Route
					path="/users"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={UsersComponent} exact />
							<Route path={`${url}/:id/:mode?`} component={UsersDetails} />
						</>
					)}
				/>
				<Route
					path="/documents"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Documents} exact />
							<Route path={`${url}/:id`} component={Document} />
						</>
					)}
				/>
				<Route
					path="/commissions"
					render={({ match: { url } }) => (
						<>
						<Route path={`${url}`} component={NewCommission} exact />
							<Route path={`${url}/addCommissionsView/:id/:type?`} component={AddCommissionsView} exact/>
							<Route path={`${url}/addCommission/:id/:type`} component={AddCommissions} />
							<Route path={`${url}/editCommissions/:id`} component={AddCommissions} />
							<Route path={`${url}/commissionsTier/:id/:templateName?/:type?`} component={CommissionsTier} exact/>
							
						</>
					)}
				/>

				<Route
					path="/reports"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Reports} exact />
							<Route path={`${url}/:reportname`} component={Report} />
						</>
					)}
				/>
				<Route
					path="/providerfees"
					render={({ match: { url } }) => (
						<>
						<Route path={`${url}`} component={EditProviderFee} exact />

						</>
					)}
				/>
				<Route
					path="/compliance"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Compliance} exact />
						</>
					)}
				/>
				<Route
					path="/uploadmasspayments"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={UploadMassPayments} exact />
							<Route
								path={`${url}/:id/:type?`}
								component={UploadMassPaymentsDetails}
							/>
						</>
					)}
				/>
				<Route
					path="/masspayments"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={MassPayments} exact />
							<Route
								path={`${url}/:id/:type?`}
								component={MassPaymentsDetails}
							/>
						</>
					)}
				/>
				<Route
					path="/payments"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Payments} exact />
							<Route path={`${url}/:id/:type?`} component={PaymentDetails} />
						</>
					)}
				/>
				<Route
					path="/alerts"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Alerts} exact />
							<Route
								path={`${url}/:id/alertdetails/:type?`}
								component={AddAlert}
							/>
							<Route path={`${url}/:id/alertview`} component={AlertDetails} />
						</>
					)}
				/>
				<Route
					path="/batchPayments"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={BatchPayments} exact />
							<Route path={`${url}/:id/:currency?`} component={BatchPaymentsGrid} />
						</>
					)}
				/>
				<Route
					path="/cases"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}/:key?`} component={Cases} exact />
							<Route
								path={`${url}/:key?/:id/casedetails/:type?/:alertid?`}
								component={CaseDetails}
							/>
							<Route path={`${url}/:key?/:id/view`} component={CaseView} />
							<Route path={`${url}/caseTemplateView/:id`} component={CaseTemplateView} />
						</>
					)}
				/>
				<Route
					path="/addressbook"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}/:type?`} component={AddressBook} exact />
							<Route path={`${url}/fiatView/:id/:payeeId`} component={AddressFiatView} />
							<Route path={`${url}/cryptoView/:id/:payeeId`} component={AddressCryptoView} />
						</>
					)}
				/>
				<Route
					path="/selfhostedwallets"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}/:type?`} component={BankSathoshiTest} exact />
							<Route path={`${url}/selfhostedwalletsView/:id/`} component={SelfHostedDetailedView} />
						</>
					)}
				/>
				<Route
					path="/reconcile"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Reconcile} exact />
							<Route path={`${url}/detail/:id/:docid`} component={ReconcileDetails} />
							<Route path={`${url}/view/:id`} component={ReconcileView} exact/>
							<Route path={`${url}/view/:id/detail/:docid`} component={ReconcileDetails} />
						</>
					)}
				/>
                <Route
					path="/balances"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Balances} exact />
							<Route path={`${url}/:id`} component={BalancesDetails}  exact/>
							<Route path={`${url}/view/:id`} component={BalanceDetailView}  /> 
					 </>
					)}
				/>
				<Route
					path="/companywallets"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={companywallets} exact />
							
					 </>
					)}
				/>
				<Route
					path="/referral"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Referral} exact />
						</>
					)}
				/>
				
				<Route
					path="/bonus"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Bonus} exact />
						</>
					)}
				/>
				<Route
					path="/transactionscompliance"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={TransactionComplience} exact />
						</>
					)}
				/>
				<Route
					path="/internalWalletTransfer"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Transfer} exact />
							<Route path={`${url}/:id`} component={TransferDetails} exact/>
							<Route path={`${url}/view/:id`} component={TransferDetailView}  /> 
						</>
					)}
				/>
				<Route
					path="/personalbanks"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={personalBank} exact />
							<Route path={`${url}/:id/:type?`} component={AddPersonalBank} />
							 
						</>
					)}
				/>
				<Route
					path="/card"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Card} exact />
							<Route path={`${url}/:id/:type?`} component={AddCard} />
							 
						</>
					)}
				/>
				<Route
					path="/introducers"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Introducer} exact />
							{/* <Route path={`${url}`} component={IntroducerDetails} /> */}
							 
						</>
					)}
				/>
				<Route
					path="/pendingdepostis"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={PendingDeposit} exact />							 
						</>
					)}
				/>
				<Route
					path="/pyrroswithdrawal"
					render={renderBankWithdrawalRoutes}
				/>
				<Route
					path="/openpaydwithdrawal"
					render={renderBankWithdrawalRoutes}
				/>
				<Route
					path="/evolvewithdrawal"
					render={renderBankWithdrawalRoutes}
				/>
				<Route
					path="/pyrrosdeposit"
					render={renderBanksDepositRoutes}
				/>
				<Route
					path="/openpayddeposit"
					render={renderBanksDepositRoutes}
				/>
				<Route
					path="/evolvedeposit"
					render={renderBanksDepositRoutes}
				/>
				<Route
					path="/pyrroscustomer"
					render={renderBanksCustomerRoutes}
				/>
				<Route
					path="/openpaydcustomer"
					render={renderBanksCustomerRoutes}
				/>
				<Route
					path="/evolvecustomer"
					render={renderBanksCustomerRoutes}
				/>
				<Route
					path="/pyrrostransaction"
					render={renderBanksTransactionRoutes}
				/>
				<Route
					path="/evolvetransaction"
					render={renderBanksTransactionRoutes}
				/>
				<Route
					path="/openpaydtransaction"
					render={renderBanksTransactionRoutes}
				/>	
				<Route
					path="/openpaydReports"
					render={renderBanksReportsRoutes}
				/>		
				<Route
					path="/deductions"
					render={({ match: { url } }) => (
						<>
							<Route path={`${url}`} component={Deductions} exact />
							<Route path={`${url}/detailView/:id`} component={DeductionsView} />
						</>
					)}
				/>
				<Route path="/silent_redirect" component={SignInSilent} />
				<Route path="/" exact component={Dashboard} />
			</Suspense>
		</Switch>
	);
					}
};
const connectStateToProps = ({ permissions, userConfig }) => {
	return { screenPermissions:permissions, userProfileInfo: userConfig.userProfileInfo };
};
const connectDispatchToProps = (dispatch) => {
	return {
		dispatch,
	};
};
export default connect(
	connectStateToProps,
	connectDispatchToProps
)(withRouter(Content));