import React, { Component } from "react";
import List from "../../grid.component";
import { Modal, Tooltip,Select, Button,Alert,Form,message } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { deleteDeductionRecord} from '../api'
import apiCalls from "../../../api/apiCalls";



class RecurringGrid extends Component {
	constructor (props) {
		super(props);
		this.state = {
			selection: [],
			selectedObj: {},
			selectedObjs: [],
			gridUrl: process.env.REACT_APP_GRID_API + "Withdraw/Deductions/RecurringDeductions",
			modal: false,
			getstatesData: [],
			gridError: null,
			selectedState: null,
			stateLoading: false,
			isDeductionModal: false,
			saveError: null,
			stateLoading: false,
			isRecurringCheck: false,
			selectedReason: null,
			deductionReasonDetails: [],
            frequencyDetails:[],
			deductionSaveModal:false,
			isDelete:false,
			deleteErrorMessage:null,
			loadingBtn:false,
			btnLoading:false

		};
		this.formref = React.createRef();
		this.gridRef = React.createRef();
	}
	
	gridColumns = [
		{
			field: "",
			title: "",
			width: 50,
			locked: true,
			customCell: (props) => (

				<label className="text-center custom-checkbox">
					<input
						id={props.dataItem.id}
						name="check"
						type="checkbox"
						checked={this.state.selection.indexOf(props.dataItem.id) > -1}
						onChange={(e) => this.handleSelectionChange(props, e)}
					/>
					<span></span>{" "}
				</label>
			)
		},
		{ field: "createdDate", title: "Created Date", filterType: "date",  filter: true, width: 150 },

		
		{
			field: "businessName",
			title: "Business Name/Personal Name",
			filter: true,
			width: 280,
		},
		{
			field: "referenceId",
			title: "Customer/Contract ID",
			filter: true,
			width: 250,
		},
		{
			field: "reasonForDeduction",
			title: "Reason For Deduction",
			filter: true,
			width: 250,
		},
		{
			field: "amountInUSD",
			title: "Amount in USD",
			filter: true,
			width: 180,
			dataType: "number",
			filterType: "numeric",
		},

		{ field: "createdBy", title: "Admin", filter: true, width: 200 },
		{ field: "frequency", title: "Frequency", filter: true, width: 130 },
		{
			field: "dateToStart",
			title: "Date to Start",
			filter: true,
			width: 210, filterType: "date"
		},
		{
			field: "nextDeduction",
			title: "Next Deduction",
			filter: true,
			width: 210, filterType: "date"
		}

	];



	handleSelectionChange = (prop, e) => {
		
		this.props.getErrorMsg(null)
		this.setState({ ...this.state,selection:[],check:false,selectedObjs:[],selectedObj:{} });

		this.formref.current?.resetFields();
		const rowObj = prop.dataItem;
		const value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		const name = e.target.name;
		let { selection, selectedObjs } = this.state;
		let idx = selection.indexOf(rowObj.id);
		if (selection) {
			selection = [];
		}
		if (idx > -1) {
			selection.splice(idx, 1);
			selectedObjs.splice(idx, 1);
		} else {
			selection.push(rowObj.id);
			selectedObjs.push(rowObj);
		}
		this.setState({
			...this.state,
			[name]: value,
			selectedObj: this.state.selectedObjs[0] || rowObj,
			selection,
			warningMsg: null,
			selectedObjs,
		});
		this.formref.current?.resetFields();
	};

	onActionClick = (key) => {
		const action = {

			"Delete":"delete"

		};
		this[action[key]]();
	};

	

	delete=()=>{
	
		if (this.state.selection.length == 0) {
            this.props.getErrorMsg('Please select the one record');
			this.setState({ ...this.state,selection:[],check:false,selectedObjs:[],selectedObj:{} });

		}
		else if(this.state?.selection.length >1) {
			this.setState({ ...this.state, warningMsg: "Please select only one record",selection:[],check:false,selectedObjs:[],selectedObj:{} });
		}
		else {
			this.setState({ ...this.state, isDelete: true,deleteErrorMessage:null })
			this.props.getErrorMsg(null)
		}
	
	}

	deleteSelectedRecord= async()=>{
		this.setState({...this.state,loadingBtn:true})
		let response =await deleteDeductionRecord(this.state.selection[0])
		if(response.ok){
		this.setState({ ...this.state, isDelete: false,loadingBtn:false,selection:[],check:false,selectedObjs:[],selectedObj:{} })
		message.success({
			content: "Recurring deduction deleted successfully.",
			className: "custom-msg",
			duration: 3
		});
		this.gridRef.current?.refreshGrid();

		}else{
			this.setState({...this.state,deleteErrorMessage:apiCalls.isErrorDispaly(response),isDelete:false,loadingBtn:false})
		}
	}

	handleDeleteModalClose=()=>{
		this.setState({ ...this.state, isDelete: false,selectedObj:{},selection:[],selectedObjs:[] })

	}
	
	modalCancel = () => {
		this.setState({ ...this.state, isDeductionModal: false ,selectedObj:{},selection:[],selectedObjs:[]})

	}

	handleRecurringCheck = (e) => {
		this.setState({ ...this.state, isRecurringCheck: e.target.checked })
	}
	handleReasonChange = (val) => {
		this.setState({ ...this.state, selectedReason: val })

	}



	render() {
		const { warningMsg ,deleteErrorMessage} = this.state;
		
		return (<>
			{warningMsg !== undefined && warningMsg !== null && (
				<Alert
					className="w-100 mb-16"
					type="warning"
					description={warningMsg}
					showIcon
				/>
			)}
			 {<div className="text-right">
                <Button
                    type="primary"
                    className="primary-btn mb-16"
                    onClick={this.delete}
					>
                    Delete
                </Button>
            </div>}
			<List
				ref={this.gridRef}
				showActionBar={true}
				onActionClick={(key) => this.onActionClick(key)}
				url={this.state.gridUrl}
				key={this.state.gridUrl}
				columns={this.gridColumns}
				showExcelExport={true}
				pKey={"Recurring"}
			/>
			
			
		<Modal
				title="Confirm Delete?"
				visible={this.state.isDelete}
				className="crypto-list"
				closeIcon={
					<span onClick={this.handleDeleteModalClose}
						className="icon md x c-pointer"
					/>
				}
				destroyOnClose={true}

				footer={ <Form.Item className="text-right mb-0">
				<Button
					type="primary"
					className="primary-btn cancel-btn reject-btn"
					onClick={this.handleDeleteModalClose}
				>
					No
				</Button>
				<Button
					type="primary"
					className="primary-btn ml-8"
					htmlType="submit"
					loading={this.state.loadingBtn}
					onClick={this.deleteSelectedRecord}
				>
					Yes
				</Button>
			</Form.Item>}
			>
				<div>
					{deleteErrorMessage !== undefined && deleteErrorMessage !== null && (
						<Alert
							className="w-100 mb-16"
							type="error"
							description={deleteErrorMessage}
							onClose={this.handleModalClose}
							showIcon
						/>
					)}
					<Form
						className="risk-popup"
					>
						<div>
							<p className="mb-16">Are you sure you want to delete this recurring deduction?</p>
						</div>
						
					</Form>
				</div>
			</Modal>
		
		</>)
	}

}
const connectStateToProps = ({ userConfig, permissions }) => {
	return {
		userConfig: userConfig.userProfileInfo,
		trackAuditLogData: userConfig.trackAuditLogData, permissions
	};
};
export default connect(
	connectStateToProps,
)(withRouter(RecurringGrid));