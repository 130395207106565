import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Modal, Tooltip, Alert, Form, Row, Col, Select, Input, Button, DatePicker } from 'antd';
import List from "../../grid.component";
import { withRouter } from "react-router-dom";
import Loader from "../../loader.component";
import DeductionTransaction from './deductionTransaction';
import { getDeductionReasonDetails, getCustomerWalletsDetails, saveDeductionsDetails, getCustomerWallets, getFrequencyLu, saveBatchDeductionsDetails } from "../api";
import apiCalls from "../../../api/apiCalls";
import moment from "moment";
import { validateContentRule } from '../../../utils/custom.validator'
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import config from "../../../config";
import Moment from "react-moment";

const { TextArea } = Input;
const { Option } = Select;
class DeductionCustomersGrid extends Component {
	formRef = React.createRef();
	gridRef = createRef();

	state = {
		selectedObj: {},
		gridUrl: process.env.REACT_APP_GRID_API + "Withdraw/Deductions/Customers",
		isModal: false,
		stateLoading: true,
		isDeductionModal: false,
		customerWalletsDetails: {},
		deductionReasonDetails: [],
		selectedReason: null,
		errorMsg: null,
		btnLoading: false,
		gridColumnsData: [],
		walletBalanceException: null,
		saveError: null,
		gridLoader: true,
		walletLoader: false,
		isRecurringCheck: false,
		frequencyDetails: [],
		selection: [],
		selectedObjs: [],
		isBatchDeduction: false,
		isVisible: false,
		deductionSaveModal: false,
		isError: null
	};

	componentDidMount() {
		this.getCustomerWalletsColumns();
		this.getFrequency();
	}

	getFrequency = async () => {
		let response = await getFrequencyLu();
		if (response.ok) {
			this.setState({ frequencyDetails: response.data });
		} else {
			this.props.getErrorMsg(apiCalls.isErrorDispaly(response))
		}
	};

	getCustomerWalletsColumns = async () => {
		let res = await getCustomerWallets();
		this.setState({ gridLoader: true });
		if (res.ok) {
			const original = JSON.parse(res.data);
			const originalData = JSON.parse(original);
			this.setState({ gridLoader: false, gridColumnsData: originalData });
		} else {
			this.setState({ gridLoader: false });
		}
	};

	deductionsDetailView = (e) => {
		this.props.getErrorMsg(null)
		this.setState({ isModal: true, stateLoading: false, selectedObj: e.dataItem });
		this.getCustomerWalletBalanceDetails(e.dataItem?.id);
	};

	handleCancel = () => {
		this.setState({ saveError: null, isModal: false, stateLoading: false, isDeductionModal: false, selectedObj: {}, selection: [], selectedObjs: [] });

	};

	getReasonDeductionDetails = async () => {
		let res = await getDeductionReasonDetails();
		if (res.ok) {
			this.setState({ deductionReasonDetails: res.data });
		} else {
			this.props.getErrorMsg(apiCalls.isErrorDispaly(res.data))
		}
	};

	getCustomerWalletBalanceDetails = async (custometID) => {
		this.setState({ walletLoader: true });
		let res = await getCustomerWalletsDetails(custometID);
		if (res.ok) {
			this.setState({ walletLoader: false, customerWalletsDetails: res.data });
		} else {
			this.setState({ walletLoader: false, walletBalanceException: apiCalls.isErrorDispaly(res) });
		}
	};

	handleDeductionTransaction = () => {
		this.setState({ isDeductionModal: true, isBatchDeduction: false, isRecurringCheck: false, isError: null });
		this.getReasonDeductionDetails();
	};

	handleReasonChange = (val) => {
		this.setState({ selectedReason: val });
	};


	deductionSubmit = async (values) => {
		const { amount } = values;
		const { selection, selectedObj, isRecurringCheck, isBatchDeduction } = this.state;
		const { userConfig } = this.props;

		if (parseFloat(amount) == 0) {
			this.setState({ saveError: "Amount must be greater than zero" });
			return;
		}

		if (amount.toString().startsWith(".")) {
			this.setState({ saveError: "Please enter a valid amount." });
			return;
		}

		this.setState({ btnLoading: true, saveError: null, isError: null });

		const obj = {
			[isBatchDeduction ? 'customerIds' : 'customerId']: isBatchDeduction ? selection : selectedObj.id,
			reason: values?.reason?.trim(),
			amount,
			isRecurring: isRecurringCheck,
			frequency: values.frequency,
			DateToStart: isRecurringCheck ? moment(values.date).format('MM/DD/YYYY') : null,
			createdby: userConfig?.userName,
			remarks: values?.remarks
		};


		try {


			const res = (isBatchDeduction && await saveBatchDeductionsDetails(obj)) || (!isBatchDeduction && await saveDeductionsDetails(obj))

			if (res.ok) {
				this.setState({ btnLoading: false, isError: null, saveError: null });
				if (isBatchDeduction) {
					this.setState({ ...this.state, deductionSaveModal: true });
				} else {
					this.handleCancel();
					if (isRecurringCheck) {
						this.props.handleRecurringTab()
					}
					else {
						this.props.handleDeductionTab();
					}
				}
			} else {
				const errorMsg = apiCalls.isErrorDispaly(res);
				this.setState({ btnLoading: false, saveError: errorMsg, isError: errorMsg });
			}
		} catch (error) {
			this.setState({ btnLoading: false, saveError: "An error occurred while saving deductions.", isError: "An error occurred while saving deductions." });
		}
	};


	modalCancel = () => {
		this.setState({ saveError: null, isDeductionModal: false, selectedReason: null });
		this.gridRef.current?.refreshGrid();
		if (this.state.isBatchDeduction) {
			this.setState({ ...this.state, saveError: null, isDeductionModal: false, selectedReason: null, selectedObj: {}, selection: [], selectedObjs: [] })
			this.gridRef.current?.refreshGrid();

		}

	};

	

	handleSelectionChange = (prop, e) => {
		this.props.getErrorMsg(null);
		const rowObj = prop.dataItem;

		let updatedSelection = [...this.state.selection];
		let updatedSelectedObjs = [...this.state.selectedObjs];
		let idx = updatedSelection.indexOf(rowObj.id);

		if (idx > -1) {
			updatedSelection.splice(idx, 1);
			updatedSelectedObjs.splice(idx, 1);
		} else {
			updatedSelection.push(rowObj.id);
			updatedSelectedObjs.push(rowObj);
		}

		this.setState({
			selection: updatedSelection,
			selectedObjs: updatedSelectedObjs,
			selectedObj: updatedSelectedObjs[0] || rowObj,
			errorMsg: null
		});
	};

	handleRecurringCheck = (e) => {
		this.setState({ isRecurringCheck: e.target.checked });
		this.formRef.current.setFieldsValue({ frequency: null, date: "" })

	};
	handleclientModalCancel = () => {
		this.setState({ ...this.state, isVisible: false })
		this.gridRef.current?.refreshGrid();

	}

	batchDeductions = () => {

		if (this.state.selection.length === 0) {
			this.props.getErrorMsg('Please select the one record');
		}
		else if (this.state.selection.length <= 1) {
			this.props.getErrorMsg('Please select more than one record');
		}
		else {
			this.getReasonDeductionDetails();
			this.setState({ isDeductionModal: true, stateLoading: false, isBatchDeduction: true, isRecurringCheck: false, saveError: null, isError: null });
			this.props.getErrorMsg(null)
		}
	};
	handleDeductionModalCancel = () => {
		this.setState({ ...this.state, deductionSaveModal: false, isDeductionModal: false, selectedObj: {}, selection: [], selectedObjs: [] })

	}
	handleDeductionModal = () => {
		this.setState({ ...this.state, deductionSaveModal: false, isDeductionModal: false, selectedObj: {}, selection: [], selectedObjs: [] })
		if (this.state?.isRecurringCheck) {
			this.props.handleRecurringTab()
		}
		else {
			this.props.handleDeductionTab();
		}
	}
	getClients = () => {
		this.setState({ ...this.state, isVisible: true })
	}
	render() {
		const countryOptions = this.state?.deductionReasonDetails?.map((item, indx) => (
			<Select.Option key={indx} value={item?.name}>
				{item?.name}
			</Select.Option>
		));
		return (
			<>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
					className="mb-16"
				></div>
				{this.state.errorMsg && (
					<div style={{ width: '100%' }}>
						<Alert
							className="w-100 mb-16 align-center"
							type="warning"
							description={this.state.errorMsg}
							showIcon
						/>
					</div>
				)}
				{<div className="text-right">
					<Button
						type="primary"
						className="primary-btn mb-16"
						onClick={this.batchDeductions}
					>
						Batch Deduction
					</Button>
				</div>}
				{console.log("colums", this.state.gridColumnsData)}
				{this.state.gridLoader && <Loader />}
				{this.state.gridColumnsData?.length > 0 && (
					<List
						showActionBar={true}
						url={this.state.gridUrl}
						showExcelExport={true}
						pKey={"Deduction Customers"}
						ref={this.gridRef}
						columns={this.state.gridColumnsData?.map((column) => {
							if (column.isCustomCell && column.field === "") {
								return {
									...column,
									customCell: (props) => (
										<td>
											<label className="text-center custom-checkbox">
												<input
													id={props.dataItem.id}
													name="check"
													type="checkbox"
													checked={this.state.selection.indexOf(props.dataItem.id) > -1}
													onChange={(e) => this.handleSelectionChange(props, e)}
												/>
												<span></span>{" "}
											</label></td>
									)
								};
							} else if (column.isCustomCell && column.field === "customerName") {
								return {
									...column,
									customCell: (prop) => (
										<td>
											<div className="gridLink" onClick={() => this.deductionsDetailView(prop)}>
												{prop.dataItem?.customerName}
											</div>
										</td>
									),
								}
							 }
							 else if (column.isCustomCell && column.field === "Last Transaction Date") {
								return {
									...column,
									customCell: (props) => (
										<td>
											<div className="text-center">
												{props.dataItem?.lastTransactionDate && (
													<Moment format={config?.dateFormates?.dateTimeFormate}>
														{apiCalls.convertUTCToLocalTime(props.dataItem?.lastTransactionDate)}
													</Moment>
												)}
												{!props.dataItem?.createdDate && ("--")}
											</div>
										</td>
									),
								};
							}
							else if (column.isCustomCell && column.field === "Account Approval Date") {
								return {
									...column,
									customCell: (props) => (
										<td>
											<div className="text-center">
												{props.dataItem?.accountApprovalDate && (
													<Moment format={config?.dateFormates?.dateTimeFormate}>
														{apiCalls.convertUTCToLocalTime(props.dataItem?.accountApprovalDate)}
													</Moment>
												)}
												{!props.dataItem?.createdDate && ("--")}
											</div>
										</td>
									),
								};
							}
							 else {
								return {
									...column
								};
							}
						})}
					/>
				)}
				<Modal width={1200}
					destroyOnClose={true}
					title={this.state.selectedObj?.customerName}
					visible={this.state.isModal}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.handleCancel} />
						</Tooltip>
					}
					footer={null}
				>
					<div className="mb-6">
						{this.state.stateLoading && <Loader />}
						{this.state.isModal && <DeductionTransaction searchId={this.state.selectedObj?.id} />}
					</div>
					{this.state.walletBalanceException && (
						<div style={{ width: '100%' }}>
							<Alert
								className="w-100 mb-16 align-center mt-16"
								type="warning"
								description={this.state.walletBalanceException}
								showIcon
							/>
						</div>
					)}
					<div className="">
						<div className="d-flex justify-content align-center my-16">
							<h2 className="ant-modal-title">Wallet Balance(USD)</h2>
							<Button type="primary" className="primary-btn" onClick={this.handleDeductionTransaction}>Add Deduction</Button>
						</div>
						{this.state.walletLoader && <Loader />}
						<table border="1" className="view mb-view cust-cryptofiat-view fiat-col-width">
							<thead>
								<tr>
									{Object.keys(this.state.customerWalletsDetails).map(currency => (
										<th key={currency}>{currency}</th>
									))}
								</tr>
							</thead>
							<tbody>
								<tr>
									{Object.values(this.state.customerWalletsDetails).map((rate, index) => (
										<td key={index}>{rate?.toLocaleString()}</td>
									))}
								</tr>
							</tbody>
						</table>
					</div>
				</Modal>

				<Modal

					title={
						(this.state.isBatchDeduction && (
							<div>
								Batch Deduction for{' '}
								<Link className="kpi-val" type="primary" onClick={this.getClients}>
									Clients
								</Link>?
							</div>
						)) ||

						(!this.state.isBatchDeduction && (`Deduction for ${this.state.selectedObj?.customerName || this.state.selectedObj?.businessName}?`))
					}
					destroyOnClose={true}
					className="custom-width text-break deposit-pop"
					visible={this.state.isDeductionModal}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.modalCancel} />
						</Tooltip>
					}
					footer={null}
				>
					{this.state.saveError && (
						<div style={{ width: '100%' }}>
							<Alert
								className="w-100 mb-16 align-center mt-0"
								type="error"
								description={this.state.saveError}
								showIcon
							/>
						</div>
					)}
					<div>
						{this.state.stateLoading && <Loader />}
						<Form
							ref={this.formRef}
							className="ant-advanced-search-form"
							autoComplete="off"
							onFinish={this.deductionSubmit}
						>
							<Row gutter={24} className="mb-24 pb-24 border-bottom">
								<Col xs={24} sm={24} md={12} lg={16} xxl={16}>
									<Form.Item
										name="reason"
										label="Reason"
										className="input-label"
										rules={[
											{
												required: true,
												message: "Is required",
												whitespace: true
											}
										]}
									>
										<Select
											className="cust-input w-80 "
											onChange={this.handleReasonChange}
											placeholder="Select Reason"
										>
											{countryOptions}
										</Select>
									</Form.Item>
								</Col>
								{this.state.selectedReason === "Others " && (
									<Col xs={24} sm={24} md={24} lg={24} xxl={24}>
										<Form.Item
											name="remarks"
											label="Remarks"
											className="input-label"
											rules={[
												{
													required: true,
													message: "Is required",
													whitespace: true
												},
												{
													validator: validateContentRule
												}
											]}
										>
											<TextArea
												placeholder="Remarks"
												rows={2}
												maxLength={100}
												showCount
											/>
										</Form.Item>
									</Col>
								)}
								<Col xs={24} sm={24} md={12} lg={16} xxl={16}>
									<Form.Item
										name="amount"
										label="Amount in USD"
										className="input-label"
										rules={[
											{
												required: true,
												message: "Is required",
												whitespace: true
											},
											{
												validator: validateContentRule
											}
										]}
									>
										<NumberFormat
											decimalScale={2}
											className="cust-input"
											customInput={Input}
											thousandSeparator={true}
											maxLength={13}
											prefix={""}
											placeholder="Enter Amount"
											allowNegative={false}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xxl={24}>
									<p>Amount will be deducted in the order of <b>USDT, USD, EUR, GBP, CHF, USDC, ETH, BTC, POL</b> and <b>SOL.</b></p>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xxl={24}>
									<div className="d-flex align-center  py-16">
										<label className="text-center custom-checkbox ">
											<input
												name="check"
												type="checkbox"
												checked={this.state.isRecurringCheck}
												onClick={this.handleRecurringCheck}
											/>
											<span></span>{" "}
										</label>
										<b className="ml-4">Recurring</b>
									</div>
								</Col>
								{this.state.isRecurringCheck && (
									<Col xs={24} sm={24} md={12} lg={16} xxl={16}>
										<Form.Item
											label="Frequency"
											name="frequency"
											className="input-label"
											rules={[
												{
													required: true,
													message: "Is required",
													whitespace: true
												},

											]}
										>
											<Select
												className="cust-input"
												maxLength={100}
												placeholder="Select Frequency"
												dropdownClassName="select-drpdwn"
											>
												{this.state.frequencyDetails?.map((item, indx) => (
													<Option key={item?.code} value={item?.name}>
														{item?.name}
													</Option>
												))}                                        </Select>
										</Form.Item>
									</Col>)}
								{this.state.isRecurringCheck && (
									<Col xs={24} sm={24} md={12} lg={16} xxl={16}>
										<Form.Item
											name="date"
											className="input-label cust-datepicker-cursor"
											label="Date to Start"
											rules={[
												{
													required: true,
													message: "Is required",
												},

											]}
										>
											<DatePicker
												format={config?.dateFormates?.dateFormate}
												className="cust-input"
												placeholder="Select Date to Start"
												disabledDate={(current) => {
													return current && current < moment().startOf('day');
												}}
												onKeyDown={(e) => e.preventDefault()}
											/>
										</Form.Item>
									</Col>
								)}

							</Row>
							<Form.Item className="mb-0">
								<div className="text-right">
									<Button
										type="primary"
										className="primary-btn cancel-btn mr-8"
										onClick={this.modalCancel}
									>
										Cancel
									</Button>
									<Button
										type="primary"
										key="submit"
										className="primary-btn"
										htmlType="submit"
										loading={this.state.btnLoading}
									>
										Confirm
									</Button>
								</div>
							</Form.Item>
						</Form>
					</div>
				</Modal>
				<Modal
					title={
						"List of Clients"
					}
					destroyOnClose={true}
					className="custom-width text-break deposit-pop"
					visible={this.state.isVisible}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.handleclientModalCancel} />
						</Tooltip>
					}
					footer={null}
				>
					<div className="client-names">
						{this.state.selectedObjs?.map((item, index) => (

							<p key={index}>
								{item?.customerName || item?.businessName}
							</p>
						))}
					</div>
				</Modal>

				<Modal
					title="Batch Deduction"
					className="custom-width text-break deposit-pop"
					visible={this.state.deductionSaveModal}
					closeIcon={
						<Tooltip title="Close">
							<span className="icon md x c-pointer" onClick={this.handleDeductionModalCancel} />
						</Tooltip>
					}
					footer={<>

						<Button
							type="primary"
							className="primary-btn"
							onClick={this.handleDeductionModal}
						>
							OK
						</Button>
					</>}>
					<p>Please wait and refresh after 5 minutes. Your batch deduction data is updating.</p>
				</Modal>
			</>
		);
	}
}

const connectStateToProps = ({ oidc, userConfig }) => {
	return { userConfig: userConfig.userProfileInfo, oidc };
};

export default withRouter(
	connect(connectStateToProps, (dispatch) => {
		return { dispatch };
	})(DeductionCustomersGrid)
);
