

import React, { Component } from 'react';
import { Row, Col, Typography, Alert, Input, Form, Button, message, Modal, Tooltip } from 'antd';
import Loader from '../loader.component';
import { handleWithdrawCrypto, handleWithdrawFait ,withdrawDetails,cryptoWithdrawDetails} from '../../reducers/withdrawReducer';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { getWithdrawFait, getWithdraw, saveComminssions, saveSendFiat } from './api'
import { setCurrentAction } from '../../reducers/actionsReducer';
import apiCalls from '../../api/apiCalls';
import { publishShowActions, mainSubject } from '../grid.component/subscribir';
import { setBreadcrumb } from "../../reducers/breadcrumbReducer";
import Info from "../info/Info";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { bytesToSize } from "../../utils/service";
import { updateCurrentScreen } from "../../reducers/permissionsReducer";
import DocumentPreview from '../shared/documentPreview';
import DocumentsUploade from '../../utils/documents';
import { PlusCircleOutlined } from '@ant-design/icons';
import Notes from '../../utils/notes';
import { getFileURL } from '../customers.component/api';
import config from "../../config";
import CreateCase from '../../utils/createCase';
import WithdrawStateChange from './stateChange'
import { DYNAMIC_STATECHANGE_FIELDS } from './constants'
import { error } from 'highcharts';

const { Title, Text } = Typography;
const EllipsisMiddle = ({ suffixCount, children }) => {
    const start = children?.slice(0, children.length - suffixCount).trim();
    const suffix = children?.slice(-suffixCount).trim();
    return (
        <Text
            className="mb-0 fs-14 docnames c-pointer d-block"
            style={{ maxWidth: "100% !important" }}
            ellipsis={{ suffix }}>
            {start}
        </Text>
    );
};
class WithdrawCryptoSummary extends Component {
    constructor (props) {
        super(props);
        this.state = {
            tabType: this.props.match.params.type,
            fiatObj: {},
            loading: false,
            error: null,
            previewPath: null,
            docId: null,
            previewModal: false,
            files: [],
            isModalVissible: false,
            docReplyObjs: [],
            docViewObj: [],
            docpreviewdata: false,
            withdrawCrypto: [],
            isCommissionCheck: false,
            btnLoading: false,
            isLoading: false,
            isReCalculateCheck: false,
            caseModal: false,
            showStateChange: false

        };
        this.formref = React.createRef();
    }
    componentDidMount() {
        publishShowActions(true);
         mainSubject.subscribe((data) =>
            this.onActionClick(data)

        );

        this.props.setAction(null)
        this.setState({ tabType: this.props.match.params.type })
        if (this.state.tabType === 'fiat') {
            this.setState({ ...this.state, loading: true })
            this.loadData();
        }
        else {
            this.getCryptoData()

        }

    }

    onActionClick = (key) => {
        const action = {
            "Create Case": "createCase",
            "State Change": "statusUpdate",

        };
        this[action[key]]();
    };
    statusUpdate = () => {
        if(this.props.match.params.type == "fiat" ){
            if(this.state?.fiatObj?.lockUnlock == "Locked"  || this.props.location.state?.details?.lockUnlock == "Locked"){
                this.setState({ ...this.state, showStateChange: false,error:"This transaction was locked, Please unlock before state change" })
            }
            else if(this.state?.fiatObj?.status == "Draft" || this.props?.withdrawStore?.withdrawDetails?.status == "Draft"){
                this.setState({ ...this.state, showStateChange: false,error:"The record is in draft state, so it cannot be changed." })

            }
            else{
                this.setState({ ...this.state, showStateChange: true,error:null})

            }
        }
        else{
            if(this.state?.withdrawCrypto?.lockUnlock == "Locked" || this.props?.withdrawStore?.cryptoWithdrawDetails?.lockUnlock == "Locked" || this.props.location.state?.details?.lockUnlock == "Locked" ){
                this.setState({ ...this.state, showStateChange: false,error:"This transaction was locked, Please unlock before state change" })
            }
            else if(this.state?.withdrawCrypto?.status == "Draft" || this.props?.withdrawStore?.cryptoWithdrawDetails?.status == "Draft"){
                this.setState({ ...this.state, showStateChange: false,error:"The record is in draft state, so it cannot be changed." })

            }
            else{
                this.setState({ ...this.state, showStateChange: true,error:null})

            }
        }

    }
    hideCaseModal = () => {
        this.setState({ ...this.state, caseModal: false });
    }

    createCase = () => {
        this.setState({ ...this.state, caseModal: true,error:null });

    }


    loadData = async () => {
        this.setState({ ...this.state, loading: true, error: null, showStateChange: false })
        let response = await getWithdrawFait(this.props.match.params.id);
        if (response.ok) {
            if (response.data) {
                publishShowActions(true)
                this.formref?.current?.setFieldsValue({ ...response.data })
                const val = response.data?.id;
                this.props.dispatch(
                    setBreadcrumb({
                        key: "/withdraw/" + val + `/fiat`,
                        val: ((response.data?.transactionId) + ((response.data?.transactionId || response.data?.transactionId === null) ? ("  " + "/") : " ") + " " + response.data?.status)
                    })
                )
            }
            this.setState({ fiatObj: response.data, docViewObj: response.data?.adminDocuments, loading: false, docReplyObjs: response.data?.adminDocuments })
            this?.props?.dispatch(withdrawDetails(response.data))
            let fileDetails = response.data?.docRepositoriesv;
            if (fileDetails) {
                let filesArray = [];
                if (fileDetails.length !== 0) {
                    fileDetails.forEach((item) => {
                        let obj = {};
                        obj.id = item.id;
                        obj.name = item.documentName;
                        obj.size = item.remarks;
                        obj.response = [item.path];
                        filesArray.push(obj);
                        this.setState({ ...this.state, files: filesArray })
                    });
                }
            }
        } else {
            this.setState({ ...this.state, loading: false, error: apiCalls.isErrorDispaly(response) })
        }

    }
    getCryptoData = async () => {
        this.setState({ ...this.state, loading: true, showStateChange: false })
        let response = await getWithdraw(this.props.match.params.id);
        if (response.ok) {
            publishShowActions(true)
            this.setState({ ...this.state, docViewObj: response.data?.adminDocuments, withdrawCrypto: response.data, loading: false })
            this?.props?.dispatch(cryptoWithdrawDetails(response.data))

            const val = response.data?.id;
            this.props.dispatch(
                setBreadcrumb({
                    key: "/withdraw/" + val + `/crypto`,
                    val: ((response.data?.transactionId) + ((response.data?.transactionId || response.data?.transactionId === null) ? ("  " + "/") : " ") + " " + response.data?.status)
                })
            )
        } else {
            this.setState({ ...this.state, loading: false, error: apiCalls.isErrorDispaly(response) })
        }
    }
    handleSuccess = () => {
        this.setState({ ...this.state, showStateChange: false })

        if (this.props.match.params.type == "fiat") {
            this.setState({ ...this.state, showStateChange: false })

            this.loadData()
        } else {
            this.setState({ ...this.state, showStateChange: false })

            this.getCryptoData()
        }

    }
    redirectCustomerView = (items) => {
        const val = items.customerId;
        this.props.dispatch(
            setBreadcrumb({
                key: "/customers/details/" + val,
                val: items.customerName
            })
        );
        localStorage.setItem("MyURL", "/customers/details/" + val)
        window.open("/customers/details/" + val, "_blank");
    }
    docPreview = async (file) => {
        let res = await getFileURL(file.id);
        if (res.ok) {
            this.setState({ ...this.state, previewModal: true, docpreviewdata: true, previewPath: file, error: null })
        } else {
            this.setState({ ...this.state, error: apiCalls.isErrorDispaly(res) })
        }
    };
    filePreviewPath = () => {
        return this.state?.previewPath
    };
    handleClose = () => {
        this.setState({ ...this.state, previewModal: false })
    }
    redirectCaseView = (caseData) => {
        const val = caseData?.caseId;
        this.props.dispatch(updateCurrentScreen("cases"));
        this.props.history.push("/cases/1/" + val + "/view");
        this.props.dispatch(
            setBreadcrumb({ key: "/cases/1/" + val + "/view", val: caseData.caseNumber })
        );
    };

    docOpen = () => {
        this.setState({ ...this.state, isModalVissible: true })
    }
    CloseModal = (popupdoc) => {
        this.setState({ ...this.state, isModalVissible: false })
    }
    updateAttachemnts = (attachemnts) => {
        this.setState({ ...this.state, docViewObj: attachemnts })
    }
    gotoProfile = (screen) => {
        const items = screen == "crypto" ? this.state.withdrawCrypto : this.state.fiatObj;
        const val = items.customerId;
        this.props.dispatch(
            setBreadcrumb({
                key: `${DYNAMIC_STATECHANGE_FIELDS.CUSTOMERS_DETAILS}` + val,
                val: items.customerName
            })
        );

        window.open(`${DYNAMIC_STATECHANGE_FIELDS.CUSTOMERS_DETAILS}${val}/${null}/${null}/10`);

    }

    renderViewData = (titleData, value, fiatClass) => {
        return <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
            <span className={`icon md ${fiatClass}`} />
            <div>
                <label className="kpi-label">{titleData}</label>
                <div className="kpi-val">{value || "-"}</div>
            </div>
        </Col>
    }

    renderCryptoViewData = (titleData, value, viewClass) => {
        return <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <span className={`icon md ${viewClass}`} />
            <div>
                <label className="kpi-label">{titleData}</label>
                <div className="kpi-val">{value || "-"}</div>
            </div>
        </Col>
    }


    renderCommissionViewData = (icon, commissionLable, commissionValue) => (
        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
            <span className={`icon md ${icon}`} />
            <div>
                <label className="kpi-label">{commissionLable}</label>
                <div className=" kpi-val">
                    {commissionValue ?
                        (<NumberFormat value={commissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : "0"}
                </div>
            </div>
        </Col>
    )

    renderCommissionCryptoView = (cryptoClassIcon, cryptoCommissionLable, CryptoCommissionValue) => (
        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
            <span className={`icon md ${cryptoClassIcon}`} />
            <div>
                <label className="kpi-label">{cryptoCommissionLable}</label>
                <div className=" kpi-val">
                    {CryptoCommissionValue ?
                        (<NumberFormat value={CryptoCommissionValue} decimalSeparator="." displayType={'text'} thousandSeparator={true} />) : "0"}
                </div>
            </div>
        </Col>
    )
    renderFiatCommonFirst = () => {
        const { fiatObj } = this.state;
        return <>
            {fiatObj?.approvedDate && <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                <span className="icon md date" />
                <div>
                    <label className="kpi-label">Approval Date</label>
                    <div className="kpi-val">

                        <Moment format={config?.dateFormates?.dateTimeFormate}>{fiatObj?.approvedDate && apiCalls.convertUTCToLocalTime(fiatObj?.approvedDate) || fiatObj?.approvedDate}</Moment>
                    </div>
                </div>
            </Col>}
            <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                <span className="icon md file" />
                <div>
                    <label className="kpi-label">Reference ID</label>
                    <div className="kpi-val" rows={2}
                        maxLength={1000}>
                        {fiatObj?.transactionrefId || "-"}
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                <span className="icon md file" />
                <div>
                    <label className="kpi-label">Reason For Transfer</label>
                    <div className="kpi-val" rows={2}
                        maxLength={1000}>
                        {((fiatObj?.customerRemarks === " ") && ("-")) || ((fiatObj?.customerRemarks === null) && ("-")) || ((fiatObj?.customerRemarks) || ("-"))}

                    </div>
                </div>
            </Col>
            {fiatObj?.status === "Cancelled" && this.renderViewData("Cancelled By", fiatObj?.modifiedBy, 'status')}
        </>
    }
    renderCaseData = (caseDetailsData) => {
        return <div>
            <label className="kpi-label d-block">Case Number</label>
            <div className="fw-600 fs-14">
                {caseDetailsData?.map(item => (
                    <a className='c-pointer' href="#/" onClick={() => this.redirectCaseView(item)}>
                        {item.caseNumber}<br />
                    </a>
                ))}
            </div>
        </div>
    }
    renderFiatCommonRemarksSecond = () => {
        const { fiatObj } = this.state;
        return (
            <>
                {fiatObj?.status === "Cancelled" && (
                    <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                        <span className="icon md status" />
                        <div>
                            <label className="kpi-label">Cancelled Date</label>
                            <div className="kpi-val">
                                <Moment format={config?.dateFormates?.dateTimeFormate}>
                                    {fiatObj?.modifiedDate && apiCalls.convertUTCToLocalTime(fiatObj?.modifiedDate) || fiatObj?.modifiedDate}
                                </Moment>
                            </div>
                        </div>
                    </Col>
                )}
                {fiatObj?.status === "Rejected" && (
                    <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                        <span className="icon md file" />
                        <div>
                            <label className="kpi-label">Reason For Rejection</label>
                            <div className="kpi-val" rows={2} maxLength={1000}>
                                {fiatObj?.rejectReason || "-"}
                            </div>
                        </div>
                    </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                    <span className="icon md file" />
                    <div>
                        <label className="kpi-label">Remarks</label>
                        <div className="kpi-val" rows={2} maxLength={1000}>
                            {((fiatObj?.statusRemarks === " ") && ("-")) || ((fiatObj?.statusRemarks === null) && ("-")) || ((fiatObj?.statusRemarks) || ("-"))}
                        </div>
                    </div>
                </Col>

                {fiatObj?.caseIds !== null && (
                    <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                        <span className='icon md case' />
                        {this.renderCaseData(fiatObj?.caseIds)}
                    </Col>
                )}
            </>
        );
    }

    renderRemarksField = () => {
        const { withdrawCrypto } = this.state;
        return (
            <Col xs={24} sm={24} md={12} lg={!withdrawCrypto.customerRemarks ? 8 : 12} xxl={!withdrawCrypto.customerRemarks ? 8 : 12}>
                <span className="icon md file" />
                <div>
                    <label className="kpi-label">Remarks</label>
                    <div className="kpi-val">
                        {((withdrawCrypto?.statusRemarks === " ") && ("-")) || ((withdrawCrypto?.statusRemarks === null) && ("-")) || ((withdrawCrypto?.statusRemarks) || ("-"))}
                    </div>
                </div>
            </Col>
        );
    }

    renderCryptoCommonData = () => {
        const { withdrawCrypto } = this.state;
        return (
            <>
                {withdrawCrypto?.approvedDate && (
                    <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                        <span className="icon md date" />
                        <div>
                            <label className="kpi-label">Approval Date</label>
                            <div className="kpi-val">
                                <Moment format={config?.dateFormates?.dateTimeFormate}>
                                    {withdrawCrypto?.approvedDate && apiCalls.convertUTCToLocalTime(withdrawCrypto?.approvedDate) || "-"}
                                </Moment>
                            </div>
                        </div>
                    </Col>
                )}

                {withdrawCrypto?.status === "Cancelled" && (
                    <>
                        {this.renderCryptoViewData("Cancelled By", withdrawCrypto?.modifiedBy, "status")}

                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                            <span className="icon md status" />
                            <div>
                                <label className="kpi-label">Cancelled Date</label>
                                <div className="kpi-val">
                                    <Moment format={config?.dateFormates?.dateTimeFormate}>
                                        {withdrawCrypto?.modifiedDate && apiCalls.convertUTCToLocalTime(withdrawCrypto?.modifiedDate) || withdrawCrypto?.modifiedDate}
                                    </Moment>
                                </div>
                            </div>
                        </Col>
                    </>
                )}

                {withdrawCrypto?.status === "Rejected" && (
                    this.renderCryptoViewData("Reason For Rejection", withdrawCrypto?.rejectReason, "file")
                )}

                {this.renderRemarksField()}

                {withdrawCrypto?.caseIds !== null && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                        <span className='icon md case' />
                        {this.renderCaseData(withdrawCrypto?.caseIds)}
                    </Col>
                )}
            </>
        );
    }

    renDocRepositorydata = () => {
        const { fiatObj } = this.state;
        return <Row>
            {fiatObj?.docRepositories?.map((file) => (
                <Col xs={24} sm={24} md={12} lg={8} xxl={8} className="">
                    <div
                        className="docfile mr-8 mt-16 d-flex align-center"
                        key={file.id}>
                        <span
                            className={`icon xl ${(file.fileName?.slice(-3) === "zip" && "file") ||
                                (file.fileName?.slice(-3) !== "zip" && "") ||
                                ((file.fileName?.slice(-3) === "pdf" ||
                                    file.fileName?.slice(-3) === "PDF") &&
                                    "file") ||
                                (file.fileName?.slice(-3) !== "pdf" &&
                                    file.fileName?.slice(-3) !== "PDF" &&
                                    "image")
                                } mr-16`}
                        />
                        <div
                            className="docdetails c-pointer"
                            onClick={() => this.docPreview(file)}>
                            {file.fileName !== null ? (
                                <EllipsisMiddle suffixCount={6}>
                                    {file.fileName}
                                </EllipsisMiddle>
                            ) : (
                                <EllipsisMiddle suffixCount={6}>Name</EllipsisMiddle>
                            )}
                            <span className="fs-12 text-secondary">
                                {file.fileSize ? bytesToSize(file.fileSize) : ""}
                            </span>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    }
    renderFiatViewData = () => {
        const { fiatObj } = this.state;
        return fiatObj && <>

            <div className="text-right mb-8"><Button className="primary-btn text-right" style={{ margin: "8px 8px" }}
                onClick={() => this.gotoProfile("fiat")}
            >{fiatObj?.isBusiness == true ? "KYB" : "KYC"}</Button></div>
            <Row gutter={10}>
                <Col xl={24} xxl={24} className="bank-view">
                    <Row className="kpi-List">
                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                            <span className="icon md date" />
                            <div>
                                <label className="kpi-label">Submission Date</label>
                                <div className="kpi-val">
                                    <Moment format={config?.dateFormates?.dateTimeFormate}>{fiatObj?.date && apiCalls.convertUTCToLocalTime(fiatObj?.date) || fiatObj?.date}</Moment>
                                </div>
                            </div>
                        </Col>
                        {this.renderViewData("Customer/Contract ID", fiatObj?.refrenceId, 'user')}

                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                            <span className="icon md user" />
                            <div>
                                <label className="kpi-label">{fiatObj?.isBusiness === true && <><b>Business Name</b>/<>Personal Name</> </>}
                                    {!fiatObj?.isBusiness &&
                                        <><>Business Name</>/<b>Personal Name</b></>}</label>

                                <br />
                                <span className="kpi-val c-pointer">
                                    <a href="#/" onClick={() => this.redirectCustomerView(this.state.fiatObj)}>
                                        {fiatObj?.customerName}
                                    </a>
                                </span>
                            </div>
                        </Col>
                        {this.renderViewData("User Name", fiatObj?.userName, 'user')}
                        {this.renderViewData("Email", fiatObj?.customerEmail, 'email')}
                        {this.renderViewData("Wallet Code", fiatObj?.walletCode, 'wallet')}
                        {this.renderViewData("Address Type", fiatObj?.addressType, 'addresstype')}
                        {this.renderViewData("Recipient Full Name", fiatObj?.recepientName, 'user')}
                        {this.renderViewData("Sender's Bank Name", fiatObj?.externalAccountName, 'bank')}
                        {this.renderViewData("Bank Name", fiatObj?.bankName, 'bank')}
                        {this.renderViewData("Bank Account Number/IBAN", fiatObj?.accountNumber, 'bank')}
                        {this.renderViewData("BIC/SWIFT/ABA Routing/Uk Sort Code", fiatObj?.routingNumber, 'bank')}
                        <Col xs={24} sm={24} md={12} lg={6} xxl={6}>
                            <span className="icon md cash" />
                            <div>
                                <label className="kpi-label">Requested Amount</label>
                                <div className=" kpi-val">
                                    {fiatObj?.requestedAmount &&
                                        (<NumberFormat value={fiatObj?.requestedAmount} decimalSeparator="." displayType={'text'} thousandSeparator={true} />)} {!fiatObj?.requestedAmount && ("-")}
                                </div>
                            </div>
                        </Col>
                        {this.renderViewData("State", fiatObj?.status, 'status')}
                        {fiatObj?.status === "Approved" &&
                            this.renderViewData("Approved By", fiatObj?.modifiedBy, 'status')
                        }
                        {this.renderFiatCommonFirst()}
                        {this.renderFiatCommonRemarksSecond()}
                    </Row>
                    {this.renDocRepositorydata()}

                </Col>
            </Row>
            <div><h4 className="fs-20 fw-400 text-textDark ml-12 mt-20">Commissions</h4></div>

            <Form
                time_related_controls="true"
                ref={this.formref}
                initialValues={fiatObj}
                name="time_related_controls"
                className="ant-advanced-search-form"
                onFinish={this.saveWithDrawFiat}
                autoComplete="off"
            >
                {(this.isCommissionEdit() && fiatObj?.isComissionAllowed && fiatObj?.status !== "Rejected" && fiatObj?.status !== "Cancelled" && (fiatObj?.addressType !== "Internal Transfer" && fiatObj?.bankAccountType !== "Personal")) && <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <div className="d-flex align-center ml-12 py-16">
                            <label className="mr-12">Edit Commissions</label>
                            <label className="text-center custom-checkbox ml-12">
                                <Input name="check" type="checkbox"
                                    onChange={(e) => this.handleEnableProviderCommission(e)}
                                    disabled={!this.isCommissionEdit() || !fiatObj?.isComissionAllowed}
                                    checked={this.props.match.params.isCommissionCheck || this.state.isCommissionCheck}
                                />

                                <span></span>{" "}
                            </label>
                            <Button
                                type="primary"
                                className="primary-btn ml-16"
                                htmlType="button"
                                loading={this.state.btnLoading}

                                disabled={!this.state.isCommissionCheck && !this.props.match.params.isCommissionCheck}

                                onClick={() => this.saveWithDrawFiat("re-claculation")}
                            >
                                Re-Calculate
                            </Button>
                        </div>

                    </Col>
                </Row>}
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="comissionvalue"
                            label="Commission"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Commission"
                                allowNegative={false}
                                maxLength={13}
                                value={fiatObj.commissionValue}
                                suffix={`${" "}${fiatObj.cTypeFlat ?? fiatObj?.cTypeFlat}`}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="comissionvalueInBase"
                            label="Commission in Base"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Commission in Base"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="effectiveFee"
                            label="Fees"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Fees"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="tierDiscount"
                            label="Tier Discount"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Tier Discount"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="sbCredit"
                            label="SuisseBase Credit Used"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="SuisseBase Credit Used"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="pcomissionvalue"
                            label="Provider Commission"
                            className="input-label"
                            rules={[
                                { required: true, message: "Is required" },
                            ]}
                        >
                            <NumberFormat
                                decimalScale={this.state?.isCommissionCheck ? 2 : 8}
                                className="cust-input"
                                disabled={!this.state.isCommissionCheck && !this.props.match.params.isCommissionCheck}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Provider Commission"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="pcomissionvalueInBase"
                            label="Provider Commission in Base"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Provider Commission in Base"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item style={{ flex: 1 }}
                            name="sweepSourceComissionValue"
                            label="Sweep Source Commission"
                            className="input-label p-relative"
                            required
                            rules={[
                                { required: true, message: "Is required" },
                            ]}

                        >
                            <NumberFormat
                                decimalScale={this.state?.isCommissionCheck ? 2 : 8}
                                className="cust-input"
                                disabled={!this.state.isCommissionCheck && !this.props.match.params.isCommissionCheck}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Sweep Source Commission"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="sweepSourceValueInBase"
                            label="Sweep Source Commission in Base"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Sweep Source Commission in Base"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            style={{ flex: 1 }}
                            name="sweepDestinationcomissionValue"
                            label="Sweep Destination Commission"
                            className="input-label p-relative"
                            required
                            rules={[
                                { required: true, message: "Is required" },
                            ]}
                        >
                            <NumberFormat
                                decimalScale={this.state?.isCommissionCheck ? 2 : 8}
                                className="cust-input"
                                disabled={!this.state.isCommissionCheck && !this.props.match.params.isCommissionCheck}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Sweep Destination Commission"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="sweepDestinationvalueInBase"
                            label="Sweep Destination Commission in Base"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Sweep Destination Commission in Base"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="totalAmount"
                            label="Total Cost"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Total Cost"
                                allowNegative={false}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="profit"
                            label="Profit"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Profit"
                                allowNegative={true}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="profitInBase"
                            label="Profit in Base"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Profit in Base"
                                allowNegative={true}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xxl={6}>
                        <Form.Item
                            name="netAmount"
                            label="Net Amount"
                            className="input-label"
                        >
                            <NumberFormat
                                decimalScale={8}
                                className="cust-input"
                                disabled={true}
                                customInput={Input}
                                thousandSeparator={true}
                                prefix={""}
                                placeholder="Net Amount"
                                allowNegative={true}
                                maxLength={13}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {this.isCommissionEdit() && fiatObj?.isComissionAllowed && fiatObj?.status !== "Rejected" && fiatObj?.addressType !== "Internal Transfer" && fiatObj?.bankAccountType !== "Personal" && <div className='text-right mt-24'>
                    <Button
                        type="primary"
                        className="primary-btn"
                        htmlType="submit"
                        disabled={!this.state.isReCalculateCheck}
                        loading={this.state.isLoading}
                    >
                        Save
                    </Button>
                </div>}
            </Form>
            <p className='mt-16 mb-0'>
                <b>Admin Upload</b> <PlusCircleOutlined onClick={() => this.docOpen()} /></p>
            <Row gutter={24} className="mb-24 pb-24 border-bottom">
                <>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                        {<DocumentsUploade
                            pop_up_cancel={() => this.CloseModal()}
                            docID={fiatObj?.id}
                            previewModal={this.state.isModalVissible}
                            attachmentUpdate={(attachemnts) =>
                                this.updateAttachemnts(attachemnts)
                            }
                            docViewObj={this.state.docViewObj}
                            screenTitle={"Send Fiat"}
                            ModifiedBy={this.props.userConfig.userName}
                        />}
                    </Col>
                </>
            </Row>
            <Notes screenId={this.props.match.params.id} screenName={"SendFiat"} createdNote={fiatObj.notes} />

            <Info Id={fiatObj.id} Status={fiatObj?.status} Createdby={fiatObj?.userName} CreatedDate={fiatObj?.createdDate} ModifiedDate={fiatObj?.modifiedDate} Modifiedby={fiatObj?.modifiedBy} isShowInfo={false} screenName={"Send"} loadData={this.loadData}/>
        </>
    }
    renderAlertMsg = () => (
        this.state.error !== undefined && this.state.error !== null && <Alert className="mb-16" type="error" showIcon description={this.state.error} />
    )
    renderViewLoading = () => (
        this.state.loading && <Loader />
    )
    isCommissionEdit = () => {
        let editCheck = this.props.permissions?.[(this.props?.permissions?.currentScreenTabId) || (this.props?.permissions?.currentScreenId)]?.filter((item) => (item.permissionName === "Edit Commissions")).map((item) => (item.values))
        return editCheck == undefined ? null : editCheck[0]
    }
    saveWithDrawFiat = async (saveType) => {
        const formValues = this.formref.current.getFieldsValue();
        let saveObj = Object.assign({}, formValues);
        saveObj.id = this.props.match.params.id
        saveObj.customerId = this.props.userConfig?.id;
        saveObj.Remarks = this.state?.fiatObj?.remarks
        saveObj.modifiedby = this.props.userConfig?.userName;
        if (saveType == "re-claculation") {
            this.setState({ ...this.state, btnLoading: true })
            let response = await saveComminssions(saveObj);
            if (response.ok) {
                this.formref.current.setFieldsValue({ ...response.data })
                message.success({
                    content: "Commissions successfully recalculated.",
                    className: "custom-msg",
                    duration: 3
                });
                this.setState({ ...this.state, btnLoading: false, isReCalculateCheck: true })
            }
            else {
                this.setState({ ...this.state, btnLoading: false, error: apiCalls.isErrorDispaly(response) })
            }
        }
        else {
            if (this.state.isCommissionCheck && !this.state.isReCalculateCheck) {
                this.setState({ ...this.state, error: `Please click on the 'Re-Calculate' button before saving.` })
                window.scrollTo(0, 0);
                return;
            }
            this.setState({ ...this.state, isLoading: true })
            let response = await saveSendFiat(saveObj)
            if (response.ok) {
                message.success({
                    content: "Commissions updated successfully",
                    className: "custom-msg",
                    duration: 3
                });
                this.setState({ ...this.state, isLoading: false })

                this.props.history.push("/withdraw");
            }
            else {
                this.setState({ ...this.state, isLoading: false, error: apiCalls.isErrorDispaly(response) })
            }

        }
    };

    handleEnableProviderCommission = (e) => {
        this.setState({ ...this.state, isCommissionCheck: e.target.checked });
    }
    render() {
        const { previewModal, withdrawCrypto } = this.state;
        return (
            <>
                {this?.state?.tabType === 'fiat' ?
                    <>
                        {this.state.error !== undefined && this.state.error !== null && <Alert className="mb-16" type="error" showIcon description={this.state.error} />}
                        {this.renderViewLoading()} <Title className="page-title">Withdraw Fiat Detail View</Title>
                        {this.renderFiatViewData()}
                    </> :
                    <> {this.renderViewLoading()}
                                                                        {this.renderAlertMsg()}

                        <div className="text-right mb-8"><Button className="primary-btn text-right" style={{ margin: "8px 8px" }}
                            onClick={() => this.gotoProfile("crypto")}
                        >{withdrawCrypto?.isBusiness == true ? "KYB" : "KYC"}</Button></div>

                        <Title className="page-title">Withdraw Crypto Detail View</Title>
                        {withdrawCrypto && <>
                            <Row gutter={8}>
                                <Col xl={24} xxl={24} className="bank-view">
                                    <Row className="kpi-List">
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md date" />
                                            <div>
                                                <label className="kpi-label">Submission Date</label>
                                                <div className="kpi-val">
                                                    <Moment format={config?.dateFormates?.dateTimeFormate} >{withdrawCrypto?.createdDate && apiCalls.convertUTCToLocalTime(withdrawCrypto?.createdDate) || withdrawCrypto?.createdDate}</Moment>
                                                </div>
                                            </div>
                                        </Col>

                                        {this.renderCryptoViewData("Customer/Contract ID", withdrawCrypto?.refrenceId, 'user')}


                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md user" />
                                            <div>
                                                <label className="kpi-label">{withdrawCrypto?.isBusiness === true && <><b>Business Name</b>/<>Personal Name</> </> || <><>Business Name</>/<b>Personal Name</b></>}</label>
                                                <br />
                                                <span className="kpi-val c-pointer">
                                                    <a href="#/" onClick={() => this.redirectCustomerView(withdrawCrypto)}>
                                                        {withdrawCrypto?.customerName || "-"}
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>

                                        {this.renderCryptoViewData("User Name", withdrawCrypto?.userName, 'user')}
                                        {this.renderCryptoViewData("Email", withdrawCrypto?.customerEmail, 'email')}

                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md wallet" />
                                            <div>
                                                <label className="kpi-label">Coin</label>
                                                <div className="kpi-val">{`${withdrawCrypto?.walletName}(${withdrawCrypto?.walletCode})` || "-"}</div>
                                            </div>
                                        </Col>
                                        {this.renderCryptoViewData("Network", withdrawCrypto?.network, 'wallet')}

                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md wallet" />
                                            <div>
                                                <label className="kpi-label">Wallet Address</label>
                                                <div className="kpi-val">
                                                    <CopyToClipboard>
                                                        <Text
                                                            copyable
                                                            className="fs-22 text-black-30 text-center custom-display"
                                                            style={{ width: "200px" }}
                                                        >
                                                            {withdrawCrypto?.walletAddress || "-"}
                                                        </Text>

                                                    </CopyToClipboard></div>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xxl={8}>
                                            <span className="icon md routing" />
                                            <div>
                                                <label className="kpi-label">Hash</label>
                                                <div className="kpi-val">
                                                    {(withdrawCrypto?.hash && <a onClick={() => window.open(`${withdrawCrypto?.explorer}${withdrawCrypto?.hash}`, '_blank')}>{withdrawCrypto?.hash}</a>) || "-"}
                                                </div>
                                            </div>
                                        </Col>
                                        {this.renderCommissionCryptoView("cash", "Requested Crypto", withdrawCrypto?.requestedAmmount)}
                                        {this.renderCommissionCryptoView("commission", "Commission", withdrawCrypto?.comissionvalue)}
                                        {this.renderCommissionCryptoView("commission", "Commission in Base", withdrawCrypto?.comissionvalueInBase)}
                                        {this.renderCommissionCryptoView("commission", "Fees", withdrawCrypto?.effectiveFee)}
                                        {this.renderCommissionCryptoView("commission", "Tier Discount", withdrawCrypto?.tierDiscount)}
                                        {this.renderCommissionCryptoView("commission", "Provider Commission", withdrawCrypto?.pcomissionvalue)}
                                        {this.renderCommissionCryptoView("commission", "Provider Commission in Base", withdrawCrypto?.pcomissionvalueInBase)}
                                        {this.renderCommissionCryptoView("commission", "Sweep Source Commission", withdrawCrypto?.sweepSourceComissionValue)}
                                        {this.renderCommissionCryptoView("commission", "Sweep Source in Base", withdrawCrypto?.sweepSourceValueInBase)}
                                        {this.renderCommissionCryptoView("commission", "Sweep Destination Commission", withdrawCrypto?.sweepDestinationcomissionValue)}
                                        {this.renderCommissionCryptoView("commission", "Sweep Destination in Base", withdrawCrypto?.sweepDestinationvalueInBase)}
                                        {this.renderCommissionCryptoView("cash", "Net Amount", withdrawCrypto?.receivedAmmount)}
                                        {this.renderCommissionCryptoView("cash", "Profit", withdrawCrypto?.netAmount)}
                                        {this.renderCommissionCryptoView("cash", "Profit in Base", withdrawCrypto?.profitInBase)}
                                        {this.renderCryptoViewData("State", withdrawCrypto?.status, 'status')}
                                        {(withdrawCrypto?.status === "Approved") &&
                                            this.renderCryptoViewData("Approved By", withdrawCrypto?.modifiedBy, 'status')
                                        }
                                        {this.renderCryptoCommonData()}
                                    </Row>
                                </Col>
                            </Row>
                            <p className='mt-16'>
                                <b> Admin Upload </b><PlusCircleOutlined onClick={() => this.docOpen()} /></p>
                            <Row gutter={24} className="mb-24 pb-24 border-bottom">
                                <>
                                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                                        {<DocumentsUploade
                                            pop_up_cancel={() => this.CloseModal()}
                                            docID={withdrawCrypto?.id}
                                            previewModal={this.state.isModalVissible}
                                            attachmentUpdate={(attachemnts) =>
                                                this.updateAttachemnts(attachemnts)
                                            }
                                            docViewObj={this.state.docViewObj}
                                            screenTitle={"Send Crypto"}
                                            ModifiedBy={this.props.userConfig.userName}
                                        />}
                                    </Col>
                                </>
                            </Row>
                            <Notes screenId={this.props.match.params.id} screenName={"SendCrypto"} createdNote={withdrawCrypto?.notes} />
                            <Info Id={withdrawCrypto.id} Status={withdrawCrypto.status} Createdby={withdrawCrypto?.userName} CreatedDate={withdrawCrypto.createdDate} ModifiedDate={withdrawCrypto?.modifiedDate} Modifiedby={withdrawCrypto?.modifiedBy} isShowInfo={false} screenName={"Send"}  getCryptoData={this.getCryptoData}/>
                        </>}

                    </>}

                {previewModal &&
                    <DocumentPreview
                        previewModal={this.state?.previewModal}
                        handleCancle={this.handleClose}
                        upLoadResponse={this.state?.previewPath ? this.filePreviewPath() : null}
                    />
                }
                <Modal
                    title="Create Case"
                    visible={this.state.caseModal}
                    width={1000}
                    style={{ top: 16 }}
                    closeIcon={
                        <Tooltip title="Close">
                            <span className="icon md x c-pointer" onClick={this.hideCaseModal} />
                        </Tooltip>
                    }
                    destroyOnClose
                    footer={null}
                >
                    <CreateCase
                        FromScreen={this.state.fiatObj?.type === "Fiat" ? 'SendFiat' : 'SendCrypto'}
                        ScreenId={this.props.match.params.id}
                        ScreenName={this.state.fiatObj?.type === "Fiat" ? 'SendFiat' : 'SendCrypto'}
                        closeModal={this.hideCaseModal} loadData={this.loadData} getCryptoData={this.getCryptoData}/>
                </Modal>
                <WithdrawStateChange visible={this.state.showStateChange}
                    withdraw={this.props.match.params.type == "fiat" ? true : false}
                    selectedTab={this.props.match.params.type == "fiat" ? 1 : 2}
                    selectedObj={this.props.match.params.type == "fiat" ? this.state.fiatObj : this.state.withdrawCrypto}
                    //selectedObj={this.props.location.state?.details}
                    status={this.props.match.params.type == "fiat" ? this.state.fiatObj?.status : this.state.withdrawCrypto?.status}
                    onClose={() => {
                        this.loadData()
                        this.setState({ ...this.state, showStateChange: false })
                    }}
                    selection={this.props.match.params.type == "fiat" ? this.state.fiatObj?.id : this.state.withdrawCrypto?.id}
                    onSuccess={() => {
                        this.handleSuccess()
                    }}
                />
            </>
        );
    }
}
const connectStateToProps = ({ withdrawStore, userConfig, permissions }) => {
    return { withdrawStore, userConfig: userConfig.userProfileInfo, permissions }
}
const connectDispatchToProps = dispatch => {
    return {
        fetchWithdrawCryptoData: (crypto_id) => {
            dispatch(handleWithdrawCrypto(crypto_id))
        },
        fetchWithdrawFaitData: (fiat_id) => {
            dispatch(handleWithdrawFait(fiat_id))
        },
        setAction: (val) => {
            dispatch(setCurrentAction(val))
        },
        dispatch
    }
}
export default connect(connectStateToProps, connectDispatchToProps)(WithdrawCryptoSummary);

